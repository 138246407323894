import axios from 'axios';
import { push } from 'react-router-redux';
import { API_URL, errorHandler, queryString } from './index';
import {
  API_ERROR,
  FETCH_ALL_PROMO_BANNERS,
} from '../constants/ActionTypes';
import swal from 'sweetalert2';

import SessionHelper from '../components/Helper/SessionHelper';

//= ===============================
// Client actions
//= ===============================

export function fetchAllPromoBanners() {
  return function(dispatch) {
    axios
      .post(
        `${API_URL}/fetch-all-promo-banners.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken()
        })
      )
      .then(response => {
        dispatch({
          type: FETCH_ALL_PROMO_BANNERS,
          payload: response.data.objects
        });
      })
      .catch(error => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function updatePromoBannerOrder(promo_banner_ids) {
    return function(dispatch) {
      axios
        .post(
          `${API_URL}/update-promo-banners-order.php`,
          queryString.stringify({
            requesting_user_id: SessionHelper.currentUser().admin_id,
            promo_banner_ids: promo_banner_ids,
            user_token: SessionHelper.getToken()
          })
        )
        .catch(error => {
          errorHandler(dispatch, error.response, API_ERROR);
        });
    };
  }

export function addEditPromoBanner(promo_banner_json) {
return function(dispatch) {
    axios
    .post(
        `${API_URL}/add-n-edit-promo-banner.php`,
        queryString.stringify({
        requesting_user_id: SessionHelper.currentUser().admin_id,
        promo_banner_json: promo_banner_json,
        user_token: SessionHelper.getToken()
        })
    )
    .then(response => {
        swal({
        type: "success",
        title: "Saved!",
        timer: 1000
        }).then(() => {
        dispatch(push("/banners"));
        });
    })
    .catch(error => {
        errorHandler(dispatch, error.response, API_ERROR);
    });
};
}

export function deletePromoBanner(promo_banner_id) {
    return function(dispatch) {
      axios
        .post(
          `${API_URL}/delete-promo-banner.php`,
          queryString.stringify({
            requesting_user_id: SessionHelper.currentUser().admin_id,
            promo_banner_id: promo_banner_id,
            user_token: SessionHelper.getToken()
          })
        )
        .then(response => {
          swal({
            type: "success",
            title: "Promo Banner Deleted!",
            timer: 1000
          }).then(() => {
            dispatch(push("/banners"));
          });
        })
        .catch(error => {
          errorHandler(dispatch, error.response, API_ERROR);
        });
    };
  }
  
