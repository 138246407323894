import React, { Component } from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { MdReorder } from "react-icons/md";

const DragHandle = SortableHandle(() => <div className="sort-handle" title='Drag to reorder'><MdReorder/></div>);

const SortableItem = SortableElement(
  ({ value, sessions, handleSeriesDay, state }) => {
    let label = "";
    let item = sessions.find(
      (obj) => obj.session_id === parseInt(Object.keys(value)[0])
    );
    if (item) {
      label = item.session_name;
    }

    if (item && item.session_id) {
      const data = value[item.session_id];
      console.log(data);
      const name = item.session_id;
      return (
        <div
          className="utility-flex-row"
          key={"sortable-session" + value.session_id}
          style={{ alignItems: "center", paddingBottom: "15px" }}
        >
          <DragHandle />
          <p style={{ marginLeft: "20px", fontSize: "16px" }}>{label}</p>
          <input
            type="number"
            className="utility-text-input"
            name={name}
            value={data.series_day}
            onChange={handleSeriesDay}
            style={{ width: "200px", marginLeft: "5px" }}
            required
          />
          <div style={{ marginLeft: "20px" }}>
            <label>DarkMode :</label>
            <input
              style={{ marginLeft: "10px", marginBottom: "10px" }}
              type="checkbox"
              name={name}
              value={!!data.session_is_dark_mode}
              onChange={(e) => handleSeriesDay(e, false)}
              checked={!!data.session_is_dark_mode}
            />
          </div>
        </div>
      );
    }
  }
);

const SortableList = SortableContainer(
  ({ items, sessions, handleSeriesDay, state }) => {
    return (
      <div>
        {items.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            value={value}
            sessions={sessions}
            handleSeriesDay={handleSeriesDay}
            state={state}
          />
        ))}
      </div>
    );
  }
);

class SortableSessions extends Component {
  render() {
    return (
      <SortableList
        items={this.props.items}
        onSortEnd={this.props.onSortEnd}
        useDragHandle={true}
        sessions={this.props.sessions}
        handleSeriesDay={this.props.handleSeriesDay}
        state={this.props.state}
        helperClass="utility-flex-row-dragging"
      />
    );
  }
}

export default SortableSessions;
