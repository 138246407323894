import React, { Component } from "react";
import { connect } from "react-redux";
import { SortableContainer } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { generalErrorHandle } from "../../actions";
import {
  addOrEditSuggestedSearch,
  deleteSuggesteedSearch,
  fetchAvailableOption,
  fetchSuggestedSearches,
} from "../../actions/suggestedSearchAction";
import MainContentContainer from "../MainContentContainer/MainContentContainer";
import SuggestedSearch from "./SuggestedSearch";

const SortableList = SortableContainer(
  ({ data, data_options, handleDelete, handleChange }) => {
    if (!data) {
      return <p>Loading ...</p>;
    }
    if (!data.length) {
      return <p>Please Add </p>;
    }
    return (
      <div>
        {data.map((value, index) => (
          <SuggestedSearch
            key={`item-${index}`}
            data={value}
            data_options={data_options}
            index={index}
            el_index={index}
            handleChange={handleChange}
            handleDelete={handleDelete}
          />
        ))}
      </div>
    );
  }
);

class SuggestedSearches extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suggested_search_data: [],
      edited: false,
    };
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      suggested_search_data: arrayMoveImmutable(
        this.state.suggested_search_data,
        oldIndex,
        newIndex
      ),
    });
  };

  componentDidMount() {
    this.props.fetchAvailableOption();
    this.props.fetchSuggestedSearches();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.suggested_search_data !== this.props.suggested_search_data) {
      this.setState({ suggested_search_data: this.props.suggested_search_data });
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      suggested_search_data: arrayMoveImmutable(
        this.state.suggested_search_data,
        oldIndex,
        newIndex
      ),
    });
  };

  validateData = () => {
    for (let i = 0; i < this.state.suggested_search_data.length; i++) {
      const data = this.state.suggested_search_data[i];
      if (!data.object_id || !data.object_type || !data.search_behavior) {
        throw generalErrorHandle(
          "Please Fill All Missing Data For Suggested Search"
        );
      }
    }
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.validateData();
    const data = JSON.stringify(this.state.suggested_search_data);
    this.props.addOrEditSuggestedSearch(data);
    this.setState({ edited: false });
  };

  handleDelete = (e, suggested_search_id, index) => {
    e.preventDefault();
    if (suggested_search_id) {
      this.props.deleteSuggesteedSearch(suggested_search_id);
    }
    this.state.suggested_search_data.splice(index, 1);
    this.setState({
      suggested_search_data: this.state.suggested_search_data,
    });
  };

  renderSuggestedSearch = () => {
    if (
      !this.props.suggested_search_options ||
      !this.props.suggested_search_data
    ) {
      return <p>Loading...</p>;
    }

    if (!this.state.suggested_search_data.length) {
      return <p>Add New</p>;
    } else {
      return (
        <SortableList
          data={this.state.suggested_search_data}
          onSortEnd={this.onSortEnd}
          data_options={this.props.suggested_search_options}
          handleChange={this.handleChange}
          handleDelete={this.handleDelete}
          useDragHandle={true}
          helperClass="utility-flex-row-dragging"
        />
      );
    }
  };

  handleChange = (e, index) => {
    const value = e.value;
    const prop = e.prop;
    let items = [...this.state.suggested_search_data];
    let item = { ...this.state.suggested_search_data[index] };
    if (prop === "object_type" && item[prop] !== value) {
      item["object_id"] = "";
    }

    item[prop] = value;
    items[index] = item;
    this.setState({ suggested_search_data: items });
  };

  addNewSuggestedSearch = () => {
    const data = {
      object_id: "",
      object_type: "",
      search_behavior: "",
    };
    this.setState({
      suggested_search_data: [...this.state.suggested_search_data, data],
    });
  };

  render() {
    return (
      <div>
        <MainContentContainer>
          <div className="HeaderBar">
            <h2>Suggested Search</h2>
          </div>

          <div className="utility-flex-end">
            <button
              className="utility-btn-transparent"
              onClick={() => this.addNewSuggestedSearch()}
            >
              Add New
            </button>
          </div>

          <div>{this.renderSuggestedSearch()}</div>
          <div className="utility-flex-end" style={{ marginTop: "30px" }}>
            <button
              className="utility-btn-primary"
              style={{ width: "35%" }}
              onClick={this.handleSubmit}
            >
              Save
            </button>
          </div>
        </MainContentContainer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    suggested_search_options: state.search.suggested_search_options,
    suggested_search_data: state.search.suggested_search_data,
  };
}

const mapDispatchToProps = {
  fetchAvailableOption,
  fetchSuggestedSearches,
  deleteSuggesteedSearch,
  addOrEditSuggestedSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(SuggestedSearches);
