import React, { Component } from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { Link } from "react-router-dom";
import { MdReorder } from 'react-icons/md';

const DragHandle = SortableHandle(() => <div style={{ width: "100px" }} className="utility-table-column sort-handle" title='Drag to reorder'><MdReorder/></div>);

const SortableItem = SortableElement(({ obj }) => {
  const colWidth1 = "536px";
  const colWidth2 = "400px";
  const colWidth3 = "220px";
  return (
    <div
      className="utility-table-row"
      key={obj.chllenge_id + obj.challenge_title}
    >
      <DragHandle />
      <p style={{ width: colWidth1 }}>{obj.challenge_title}</p>
      <p style={{ width: colWidth3 }}>{obj.total_joins}</p>
      <p style={{ width: colWidth3 }}>{obj.sessions.length}</p>

      <div style={{ width: colWidth3 }} className="utility-table-end-col">
        <p>
          {obj.challenge_is_active === 0 ? (
            "Not Active"
          ) : (
            <span className="utility-active">Active</span>
          )}
        </p>
        <div style={{ margin: "16px 0 16px auto" }}>
          <Link
            to={"/challenges/edit/" + obj.challenge_id}
            className="utility-edit-link"
          >
            Edit
          </Link>
        </div>
      </div>
    </div>
  );
});

const SortableList = SortableContainer(({ items, widths }) => {
  return (
    <div>
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} obj={value} />
      ))}
    </div>
  );
});

class SortableChallenges extends Component {
  render() {
    return (
      <SortableList
        items={this.props.items}
        onSortEnd={this.props.onSortEnd}
        useDragHandle={true}
        helperClass="utility-flex-row-dragging"
      />
    );
  }
}

export default SortableChallenges;
