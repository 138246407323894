import React, { Component } from "react";
import OnboardingData from "./../../OnboardingData/OnboardingData";

class OnboardingDataPage extends Component {
  render() {
    return (
      <div className="utility-page-layout">
        <OnboardingData />
      </div>
    );
  }
}

export default OnboardingDataPage;
