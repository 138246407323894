import axios from "axios";
import { API_URL, errorHandler, queryString } from "./index";
import { push } from "react-router-redux";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { API_ERROR, FETCH_ALL_CATEGORIES, CLEAR_HIGHLIGHT } from "../constants/ActionTypes";

import SessionHelper from "../components/Helper/SessionHelper";

//= ===============================
// Category actions
//= ===============================

export function fetchAllCategories(isQuickTap = null) {
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/fetch-all-categories.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken(),
          is_quick_tap: isQuickTap
        })
      )
      .then((response) => {
        dispatch({
          type: FETCH_ALL_CATEGORIES,
          payload: response.data.objects || [],
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function clearCategories() {
  return function (dispatch) {
    dispatch({
      type: FETCH_ALL_CATEGORIES,
      payload: null,
    });
  };
}

export function addEditCategory(options) {
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/add-n-edit-category.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          category_json: options.category_json,
          child_objects_json: options.child_objects_json,
          user_token: SessionHelper.getToken(),
        })
      )
      .then((response) => {
        swal({
          type: "success",
          title: "Saved!",
          timer: 1000,
        }).then(() => {
          const parsed_category = JSON.parse(options.category_json);
          if (parsed_category.category_id === 32) {
            dispatch(push("/audiobooks"));
          } else {
            dispatch(push("/category"));
          }
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function deleteCategory(category_id) {
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/delete-category.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          category_id: category_id,
          user_token: SessionHelper.getToken(),
        })
      )
      .then((response) => {
        swal({
          type: "success",
          title: "Category Deleted!",
          timer: 1000,
        }).then(() => {
          dispatch(push("/category"));
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function sortCategories(category_ids) {
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/update-category-order.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          category_ids: category_ids,
          user_token: SessionHelper.getToken(),
        })
      )
      .then(() => {
        dispatch({
          type: CLEAR_HIGHLIGHT,
          payload: null
        });
      })
      .catch((error) => {
        dispatch({
          type: CLEAR_HIGHLIGHT,
          payload: null
        });
        fetchAllCategories()(dispatch);
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function nonExistentCategory(){
  return function(dispatch) {
    swal({
      type: "error",
      text: "Category does not exist!",
      showConfirmButton: true,
      showCancelButton: false,
      confirmButtonText: "Return"
    }).then(() => {
      dispatch(push("/category"));
    });
  };
}
