import React, { Component } from 'react';
import AddEditQuotes from './../../AddEditQuotes/AddEditQuotes';

class QuotesAddEditPage extends Component {

  render() {
    const isEdit = this.props.location.pathname.indexOf('edit') > -1;

    return (
      <div className="utility-page-layout">
        <AddEditQuotes isEdit={isEdit} selectedId={this.props.match.params.id} />
      </div>
    );
  }

}

export default QuotesAddEditPage;
