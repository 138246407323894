import React from "react";
import { Link } from "react-router-dom";
import HeaderBar from "../../HeaderBar/HeaderBar";
import MainContentContainer from "../../MainContentContainer/MainContentContainer";
import QuickTapsFeaturedTable from "./QuickTapsFeaturedTable";

export default function QuickTapsFeaturedPage() {
  return (
    <div className="utility-page-layout">
      <HeaderBar header="Featured Quick Taps">
        <Link to="/quick-taps" className="utility-btn-primary">
          Back
        </Link>
      </HeaderBar>
      <MainContentContainer>
        <QuickTapsFeaturedTable />
      </MainContentContainer>
    </div>
  );
}
