import { Cancel, Check, Close } from '@mui/icons-material';
import { Dialog, DialogTitle, DialogContent, DialogContentText, List, ListItem, ListItemAvatar, Avatar, ListItemText, IconButton, DialogActions, Button, ListItemButton, Box, CircularProgress } from '@mui/material';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addCardToDeck, fetchUnparentedCards } from '../../../../actions/cardsActions';

export default function UnparentedCardList({ open, deckId, handleClose}) {
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.cards.loading);
    const unparentedCards = useSelector(state => state.cards.unparentedCards);

    const handleFetchUnparentedCards = () => {
        dispatch(fetchUnparentedCards());
    }

    const handleSelectCard = card => {
        dispatch(addCardToDeck(deckId, card));
    };

    return (
        <Dialog
            id="unparented-card-dialog"
            open={open}
            onClose={handleClose}
            onTransitionEnter={handleFetchUnparentedCards}
            fullWidth
            PaperProps={{
                component: 'form',
                onSubmit: (e) => {
                    e.preventDefault()
                    console.log('Submitted');
                    handleClose()
                }
            }}
        >
            <DialogTitle>Unparented Cards</DialogTitle>
            <IconButton
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500]
                }}
                onClick={handleClose}
            >
                <Close />
            </IconButton>
            <DialogContent>
                <DialogContentText>Select the card you want to add to the Deck.</DialogContentText>
                {
                    isLoading ? (
                        <Box sx={{ display: 'flex', marginTop: 2 }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <List>
                            <ListItem 
                                alignItems="center"
                                disablePadding
                            >
                                {
                                    unparentedCards.map((card) => (
                                        <ListItemButton 
                                            key={`unparented-card-${card.id}`}
                                            onClick={() => handleSelectCard(card)}
                                        >
                                            <ListItemAvatar>
                                                <Avatar alt={ card.title } src={ card.front_image_url } />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={ card.title }
                                                secondary={ card.subtitle }
                                            />
                                        </ListItemButton>
                                    ))
                                }
                            </ListItem>
                        </List>
                    )
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} startIcon={<Cancel />} color="error" variant="contained">Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}
