import React, { Component } from 'react';
import PromoBannersTable from './../../PromoBannersTable/PromoBannersTable';

class PromoBannersPage extends Component {

  render() {
    return (
      <div className="utility-page-layout">
        <PromoBannersTable />
      </div>
    );
  }

}

export default PromoBannersPage;
