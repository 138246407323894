import React from "react";
import videojs from "@mux/videojs-kit";
import "@mux/videojs-kit/dist/index.css";

export const VideoJS = ({ playbackID, onReady }) => {
  const videoRef = React.useRef(null);

  React.useEffect(() => {
    if (videoRef.current) {
      const player = videojs(videoRef.current);
      onReady && onReady(player);

      if (playbackID.length > 0) {
        player.src({
          type: "video/mux",
          src: playbackID,
        });
      }
    }
  }, [videoRef, playbackID]);

  return (
    <div style={{ width: 600, height: 300, marginBottom: "2rem" }}>
      <video
        ref={videoRef}
        className="video-js vjs-16-9"
        controls
        preload="auto"
        width="100%"
      />
    </div>
  );
};

export default VideoJS;
