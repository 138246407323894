import React, { Component } from 'react';
import './SubNav.css';

class SubNav extends Component {

  render() {
    return (
      <nav className="SubNav">
        { this.props.children }
      </nav>
    );
  }

}

export default SubNav;
