import React, { Component } from "react";
import FormatTimestamp from "./../FormatTimestamp/FormatTimestamp";
import Select from "react-select";
// import 'react-select/dist/react-select.css';

class BackgroundImageTimings extends Component {
  generateImageOptions = (i) => {
    const { tappingPoints } = this.props;

    if (!tappingPoints) {
      return null;
    }
    return tappingPoints.reduce(function (accum, obj) {
      if (obj && obj.tapping_point_name !== "Default Tapping Point") {
        accum = accum || [];
        accum.push({
          value: obj.tapping_point_id,
          label: obj.tapping_point_name,
          image: obj.tapping_point_image_url,
          index: i,
        });
        return accum;
      }
      return accum;
    }, []);
  };

  selectImage = (e) => {
    const { stateProp, handleTimestampChange } = this.props;

    handleTimestampChange(e.value, stateProp, e.index, "tapping_point_id");
    handleTimestampChange(e.image, stateProp, e.index, "image_url");
  };

  selectTimeStampToRemove = (e) => {
    const { removeTimestamp } = this.props;

    e.preventDefault();
    const index = Number(e.target.name);
    removeTimestamp(index);
  };

  renderItems = () => {
    const { items, stateProp, handleTimestampChange } = this.props;

    return items.map((obj, i) => {
      return (
        <div
          className="utility-flex-row"
          style={{ padding: "10px 0px", alignItems: "center" }}
          key={"timestampImages" + i}
        >
          <FormatTimestamp
            value={obj.timestamp}
            handleChange={handleTimestampChange}
            stateProp={stateProp}
            indexToChange={i}
          />
          <img src={obj.image_url} style={{ maxHeight: "100px" }} alt="" />
          <Select
            value={obj.tapping_point_id}
            onChange={this.selectImage}
            style={{ width: "270px" }}
            clearable={false}
            searchable={true}
            options={this.generateImageOptions(i)}
          />
          <button
            onClick={this.selectTimeStampToRemove}
            name={i + ""}
            className="utility-btn-transparent"
            style={{ color: "#d0021b", marginLeft: "10px" }}
          >
            remove
          </button>
        </div>
      );
    });
  };

  render() {
    return <div>{this.renderItems()}</div>;
  }
}

export default BackgroundImageTimings;
