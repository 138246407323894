import {
  FETCH_ALL_TOP_SEARCH,
  DELETE_TOP_SEARCH,
  FETCH_SUGGESTED_SEARCH_OPTIONS,
  FETCH_SUGGESTED_SEARCH,
  DELETE_SUGGESTED_SEARCH,
  FETCH_SEARCH_DEEPLINKS,
} from "../constants/ActionTypes";

const INITIAL_STATE = {
  top_search_data: null,
  suggested_search_data: null,
  suggested_search_options: null,
  deeplinks: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ALL_TOP_SEARCH:
      return { ...state, top_search_data: action.payload };
    case FETCH_SUGGESTED_SEARCH_OPTIONS:
      return { ...state, suggested_search_options: action.payload };
    case FETCH_SUGGESTED_SEARCH:
      return { ...state, suggested_search_data: action.payload };
    case FETCH_SEARCH_DEEPLINKS:
      return { ...state, deeplinks: action.payload };
    // case DELETE_TOP_SEARCH:
    //   const filtered = state.top_search_data.filter(
    //     (el) => el.top_search_id != action.payload
    //   );
    //   return { ...state, top_search_data: filtered };
    // case DELETE_SUGGESTED_SEARCH:
    //   const sg_filtered = state.suggested_search_data.filter(
    //     (el) => el.id != action.payload
    //   );
    //   return { ...state, suggested_search_data: sg_filtered };
    default:
      return state;
  }
}
