import axios from "axios";
import swal from "sweetalert2";
import { push } from "react-router-redux";
import { API_URL, errorHandler, queryString } from "./index";
import {
  API_ERROR,
  UPDATE_CANCELLATION_REASON,
  FETCH_CANCELLATION_REASONS,
  DELETE_CANCELLATION_REASON,
  FETCH_USER_CANCELLATION_REPORT,
} from "../constants/ActionTypes";
import SessionHelper from "../components/Helper/SessionHelper";

export function fetchAllcancellationReasons() {
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/fetch-cancellation-reasons.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken(),
        })
      )
      .then((response) => {
        dispatch({
          type: FETCH_CANCELLATION_REASONS,
          payload: response.data.objects,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function addEditCancellationReasons(json) {
  console.log("request came through ", json);
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/add-n-edit-cancellation-reasons.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          json: JSON.stringify(json),
          user_token: SessionHelper.getToken(),
        })
      )
      .then((response) => {
        swal({
          type: "success",
          title: "Saved!",
          timer: 1000,
        }).then(() => {
          dispatch(fetchAllcancellationReasons());
        });
      })
      .catch((error) => {
        swal({
          type: "error",
          title: error.response.data.message,
          heightAuto: true,
        });
      });
  };
}

export function deleteCancellationReasons(cancellation_id) {
  console.log("request came throuygh ", cancellation_id);
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/delete-cancellation-reasons.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken(),
          cancellation_id: cancellation_id,
        })
      )
      .then((response) => {
        console.log("response", response);
        swal({
          type: "success",
          title: "Deleted Successfully!",
          timer: 1000,
        }).then(() => {
          dispatch(fetchAllcancellationReasons());
        });
      })
      .catch((error) => {
        console.log("error");

        swal({
          type: "error",
          title: error.response.data.message,
          heightAuto: true,
        });
      });
  };
}

export function fetchUserCancellationReports() {
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/fetch-user-cancellation-report.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken(),
        })
      )
      .then((response) => {
        dispatch({
          type: FETCH_USER_CANCELLATION_REPORT,
          payload: response.data.objects,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}
