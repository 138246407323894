import React, { Component } from "react";
import AddEditSeries from "./../../AddEditSeries/AddEditSeries";

class SeriesAddEditPage extends Component {
  render() {
    const isEdit = this.props.location.pathname.indexOf("edit") > -1;
    return (
      <div className="utility-page-layout">
        <AddEditSeries
          isEdit={isEdit}
          selectedId={this.props.match.params.id}
        />
      </div>
    );
  }
}

export default SeriesAddEditPage;
