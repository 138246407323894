import React from "react";
import AddEditQuickTapSession from "./../../AddEditQuickTapSession/AddEditQuickTapSession";

export default function SessionsAddEditPage(props) {
  const isEdit = props.location.pathname.indexOf("edit") > -1;

  return (
    <div className="utility-page-layout">
      <AddEditQuickTapSession
        isEdit={isEdit}
        selectedId={props.match.params.id}
      />
    </div>
  );
}
