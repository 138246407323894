import React, { Component } from 'react';
import PromoData from '../../PromoData/PromoData';

class PromoDataPage extends Component {

  render() {
    return (
      <div className="utility-page-layout">
        <PromoData />
      </div>
    );
  }

}

export default PromoDataPage;
