import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchAllSubCategories,
  clearAllSubCategories,
} from "./../../actions/subcategoryActions";
import { Link } from "react-router-dom";
import HeaderBar from "./../HeaderBar/HeaderBar";
import MainContentContainer from "./../MainContentContainer/MainContentContainer";
import { MdModeEdit } from 'react-icons/md';

class SubCategoriesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
    };
  }

  componentDidMount() {
    this.props.clearAllSubCategories();
    this.props.fetchAllSubCategories();
  }

  handleSearchChange = (e) => {
    const { value } = e.target;
    this.setState({ searchText: value });
  };

  renderSubcategories = () => {
    if (!this.props.subcategories) return null;

    let data = Object.values(this.props.subcategories);
    console.log("subcategory_data", data);
    data = data.filter((el) => el.subcategory_is_book === 0);
    if (this.state.searchText !== "") {
      const search = this.state.searchText.toLowerCase();
      data = data.filter(
        (obj) => obj.subcategory_title.toLowerCase().indexOf(search) > -1
      );
    }

    return data.map((obj) => {
      return (
        <div
          className="utility-table-row"
          key={obj.subcategory_id + obj.subcategory_title}
        >
          <div className="utility-table-column utility-table-column-expanded">{obj.subcategory_title}</div>
          <div className="utility-table-column utility-table-column-expanded">{obj.category_title || "none"}</div>
          <div
            className={obj.subcategory_is_active === 1 ? 'utility-table-column utility-active' : 'utility-table-column'}>{obj.subcategory_is_active === 1 ? 'Active' : 'Not Active'}</div>
          <div className="utility-table-column">{obj.subcategory_is_quick_tap === 1 ? 'Yes' : 'No'}</div>
          <div className="utility-table-column">
            <Link
              to={"/subcategories/edit/" + obj.subcategory_id}
              className="utility-edit-link"
            ><MdModeEdit /></Link>
          </div>
        </div>
      );
    });
  };

  render() {
    let categoryName = "Category";

    return (
      <div>
        <HeaderBar header={categoryName}>
          <Link className="utility-btn-primary" to={"/subcategories/add"}>
            Create New Sub Category
          </Link>
        </HeaderBar>

        <MainContentContainer>
          <div className="SubCategoriesTable">
            <div
              className="utility-flex-row"
              style={{ alignItems: "center", justifyContent: "center" }}
            >
              <p>
                <b>Search Subcategories: </b>
              </p>
              <input
                type="text"
                value={this.state.searchText}
                className="utility-text-input"
                onChange={this.handleSearchChange}
                style={{ marginLeft: "20px" }}
              />
            </div>

            {this.state.searchText !== "" && (
              <h3>
                Showing subcategories matching search: {this.state.searchText}
              </h3>
            )}
            <div style={{ flex: 1 }}>
              {/* Header Row */}
              <div className="utility-table-row utility-table-row-headers">
                <div className="utility-table-column utility-table-column-expanded">Title</div>
                <div className="utility-table-column utility-table-column-expanded">In Category</div>
                <div className="utility-table-column">Active</div>
                <div className="utility-table-column">Quick Tap?</div>
                <div className="utility-table-column"></div>
              </div>
              {this.renderSubcategories()}
            </div>
          </div>
        </MainContentContainer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    subcategories: state.subcategories.subcategories,
    all_categories: state.categories.all_categories,
  };
}

const mapDispatchToProps = {
  fetchAllSubCategories,
  clearAllSubCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubCategoriesTable);
