import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import {
  fetchAllQuickTapSessions,
  clearQuickTapSessions,
  fetchQuickTapsIntro,
  updateQuickTapsIntro
} from "./../../actions/quickTapsActions";
import { Link } from "react-router-dom";

class QuickTapsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: props.searchText,
      quick_taps_intro: props.quick_taps_intro
    };
    this.colWidth1 = "136px";
    this.colWidth2 = "480px";
    this.colWidth3 = "200px";
    this.colWidth4 = "240px";
  }

  componentDidMount() {
    this.props.clearQuickTapSessions();
    this.props.fetchAllQuickTapSessions();
    this.props.fetchQuickTapsIntro();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.quick_taps_intro !== this.props.quick_taps_intro) {
        this.setState({ quick_taps_intro: this.props.quick_taps_intro });
    }
  }

  handleSearchChange = (e) => {
    const { value } = e.target;
    this.setState({ searchText: value });
  };

  handleIntroChange = (e) => {
    const { value } = e.target;
    this.setState({ quick_taps_intro: value });
  };

  handleSave = (e) => {
    this.props.updateQuickTapsIntro(
      this.state.quick_taps_intro
    );
  };

  renderSessions = () => {
    if (!this.props.all_quick_tap_sessions) {
      return <h1 style={{ textAlign: "center" }}>Loading...</h1>;
    }
    let data = [...this.props.all_quick_tap_sessions];
    if (this.state.searchText !== "") {
      const search = this.state.searchText.toLowerCase();
      data = data.filter((obj) => obj.title.toLowerCase().indexOf(search) > -1);
    }
    return data.map((obj) => {
      return (
        <div className="utility-table-row" key={obj.id + obj.title}>
          <p style={{ width: this.colWidth2 }}>{obj.title}</p>
          <p style={{ width: this.colWidth3 }}>{obj.duration}</p>
          <p style={{ width: this.colWidth3 }}>
            {obj.is_active === 0 ? (
              "Not Active"
            ) : (
              <span className="utility-active">Active</span>
            )}
          </p>
          <p style={{ width: this.colWidth3 }}>
            {obj.is_featured === 0 ? "No" : "Yes"}
          </p>
          <div
            style={{ width: this.colWidth4 }}
            className="utility-table-end-col"
          >
            <p>{obj.is_premium === 1 ? "Premium" : "Free"}</p>
            <div style={{ margin: "16px 5px 16px auto" }}>
              <Link
                to={"/quick-taps/edit/" + obj.id}
                className="utility-edit-link"
              >
                Edit
              </Link>
            </div>
          </div>
        </div>
      );
    });
  };

  render() {
    return (
      <div className="SessionsTable">
        <div
          className="utility-flex-row"
          style={{ alignItems: "left", justifyContent: "left", margin: "20px 20px" }}
        >
          <input
            type="text"
            value={this.state.quick_taps_intro}
            className="utility-text-input"
            name="quick_taps_intro"
            onChange={this.handleIntroChange}
            style={{ marginRight: "20px", flex: "1" }}
          />
          <Button className="utility-btn-primary" onClick={this.handleSave}>
            Save Intro
          </Button>
        </div>
        <div
          className="utility-flex-row"
          style={{ alignItems: "center", justifyContent: "center", margin: "20px 0px"}}
        >
          <p>
            <b>Search Quick Taps: </b>
          </p>
          <input
            type="text"
            value={this.state.searchText}
            className="utility-text-input"
            onChange={this.handleSearchChange}
            style={{ marginLeft: "20px" }}
          />
        </div>

        {this.state.searchText !== "" && (
        <div className="utility-flex-row"  style={{ margin: "20px 0px" }}>
          <h3>Showing sessions matching search: {this.state.searchText}</h3>
        </div>
        )}

        <div className="utility-table-row utility-table-row-headers">
          <h2 style={{ width: this.colWidth2 }}>Title</h2>
          <h2 style={{ width: this.colWidth3 }}>Duration</h2>
          <h2 style={{ width: this.colWidth3 }}>Is Active</h2>
          <h2 style={{ width: this.colWidth3 }}>Featured </h2>
          <h2 style={{ width: this.colWidth4 }}>Free/Premium</h2>
        </div>
        {this.renderSessions()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    all_quick_tap_sessions: state.quick_taps.all_quick_tap_sessions,
    quick_taps_intro: state.general_data.general_data
      ? state.general_data.general_data.quick_tap_intro
      : "",
    searchText: state.searchText ? state.searchText : ""
  };
}

const mapDispatchToProps = {
  clearQuickTapSessions,
  fetchAllQuickTapSessions,
  fetchQuickTapsIntro,
  updateQuickTapsIntro
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickTapsTable);
