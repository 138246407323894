import axios from "axios";
import { API_URL, errorHandler, queryString } from "./index";
import { API_ERROR, FETCH_USER_STREAK, RESTORE_USER_STREAK, FETCH_USER_STREAK_ERROR } from "../constants/ActionTypes";

import SessionHelper from "../components/Helper/SessionHelper";


//= ===============================
// Client actions
//= ===============================

export function fetchUserStreak(options) {
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/v2/admin/fetch-user-streak.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken(),
          days: options.days,
          user_email: options.user_email
        })
      )
      .then((response) => {
        dispatch({
          type: FETCH_USER_STREAK,
          payload: response.data
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_USER_STREAK_ERROR,
          payload: []
        });
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function restoreUserStreak(options) {
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/restore-user-streak.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken(),
          days: options.days,
          email: options.user_email
        })
      )
      .then((response) => {
        dispatch({
          type: RESTORE_USER_STREAK,
          payload: response.data
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

