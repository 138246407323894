import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
// import SubNav from './../../Nav/SubNav/SubNav';
import HeaderBar from "./../../HeaderBar/HeaderBar";
import MainContentContainer from "./../../MainContentContainer/MainContentContainer";
import CategoriesTable from "./../../CategoriesTable/CategoriesTable";

class CategoryPage extends Component {
  render() {
    return (
      <div className="utility-page-layout">
        {/* <SubNav>
          <Link to="/category">Category</Link>
        </SubNav> */}
        <HeaderBar header="Categories">
          <Link to="/category/add" className="Utility-btn-primary">
            Add Category
          </Link>
        </HeaderBar>
        <MainContentContainer>
          <CategoriesTable />
        </MainContentContainer>
      </div>
    );
  }
}

export default withRouter(CategoryPage);
