import axios from "axios";
import { API_URL, errorHandler, queryString } from "./index";
import { push } from "react-router-redux";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import {
  API_ERROR,
  FETCH_ALL_SESSIONS,
  FETCH_ALL_SESSIONS_START,
} from "../constants/ActionTypes";

import SessionHelper from "../components/Helper/SessionHelper";

//= ===============================
// Session actions
//= ===============================

export function fetchAllSessions() {
  return function (dispatch) {
    dispatch(({
      type: FETCH_ALL_SESSIONS_START,
    }))
    axios
      .post(
        `${API_URL}/fetch-all-sessions.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken(),
        })
      )
      .then((response) => {
        // console.log("response: ", response);
        dispatch({
          type: FETCH_ALL_SESSIONS,
          payload: response.data.objects,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function fetchOneSession(session_id) {
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/fetch-cms-session.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken(),
          session_id: session_id,
        })
      )
      .then((response) => {
        // console.log("response: ", response);
        dispatch({
          type: FETCH_ALL_SESSIONS,
          payload: response.data.objects,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function addEditSession(options) {
  console.log("image: ", options.session_json);
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/add-n-edit-session.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken(),
          timestamps_json: options.timestamps_json,
          subcategory_id: options.subcategory_id,
          category_id: options.category_id,
          series_id: options.series_id,
          session_json: options.session_json,
          challenge_id: options.challenge_id,
        })
      )
      .then((response) => {
        swal({
          type: "success",
          title: "Saved!",
          timer: 1000,
        }).then(() => {
          dispatch(push("/sessions"));
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function deleteSession(session_id) {
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/delete-session.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          session_id: session_id,
          user_token: SessionHelper.getToken(),
        })
      )
      .then((response) => {
        swal({
          type: "success",
          title: "Session Deleted!",
          timer: 1000,
        }).then(() => {
          dispatch(push("/sessions"));
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function clearSessions() {
  return function (dispatch) {
    dispatch({
      type: FETCH_ALL_SESSIONS,
      payload: null,
    });
  };
}
