import React, { Component } from "react";
import QuotesTable from "./../../QuotesTable/QuotesTable";

class ProgramsPage extends Component {
  render() {
    return (
      <div className="utility-page-layout">
        <QuotesTable />
      </div>
    );
  }
}

export default ProgramsPage;
