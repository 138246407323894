import React, { Component } from 'react';
import AddEditTappingPoints from './../../AddEditTappingPoints/AddEditTappingPoints';

class TappingPointsAddEditPage extends Component {

  render() {
    const isEdit = this.props.location.pathname.indexOf('edit') > -1;

    return (
      <div className="utility-page-layout">
        <AddEditTappingPoints isEdit={isEdit} selectedId={this.props.match.params.id} />
      </div>
    );
  }

}

export default TappingPointsAddEditPage;
