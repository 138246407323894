import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Logout from './../../Logout/Logout';

class LogoutPage extends Component {
  render() {
    return (
      <div className="LogoutPage">
        <Logout></Logout>
      </div>
    );
  }
}

export default withRouter(LogoutPage);