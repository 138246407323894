import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { SingleDatePicker } from "react-dates";
import { connect } from "react-redux";
import Select from "react-select";
import swal from "sweetalert2";
import {
  addEditDailyReflections,
  fetchAllDailyReflections,
  deleteDailyReflection,
} from "../../../actions/dailyReflectionActions";
import {
  clearMedia,
  uploadAudio,
  uploadImage,
} from "../../../actions/mediaActions";
import { addObjectTag, removeObjectTag } from "../../../actions/tagActions";

function DailyReflectionModal({
  data,
  authors,
  audio,
  image,
  addEditDailyReflections,
  uploadAudio,
  uploadImage,
  clearMedia,
  type,
  deleteDailyReflection,
  addObjectTag,
  removeObjectTag,
}) {
  const [show, setShow] = useState(false);
  const [dailyReflection, setDailyReflection] = useState({});
  const [tags, setTags] = useState([]);
  const [currentTag, setCurrentTag] = useState("");
  const audioRef = useRef();
  const [focused, setFocused] = useState(false);
  const [audioUploaded, setAudioUploaded] = useState(false);
  const [date, setDate] = useState(null);

  useEffect(() => {
    if (data) {
      setDailyReflection(data);
    }
    if (data.date_epoch) {
      setDate(moment(data.date_epoch * 1000));
    }
  }, [data]);
  useEffect(() => {
    if (audio && audioUploaded) {
      setDailyReflection({ ...dailyReflection, audio_url: audio });
      setAudioUploaded(false);
    }
  }, [audio]);
  useEffect(() => {
    if (image) {
      setDailyReflection({ ...dailyReflection, deeplink_image_url: image });
    }
  }, [image]);

  const handleClose = () => {
    setDailyReflection({});
    clearTags();
    setShow(false);
    setCurrentTag("");
  };

  const handleDelete = () => {
    console.log("dailyReflection.daily_reflection_id", dailyReflection);
    if (dailyReflection.daily_reflection_id) {
      swal({
        type: "warning",
        text: "Are you sure you want to delete this daily inspiration session ?",
        showConfirmButton: true,
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Delete",
        confirmButtonColor: "#d0021b",
        cancelButtonColor: "secondary",
      }).then((result) => {
        console.log(result);
        if (result.value === true) {
          deleteDailyReflection(dailyReflection.daily_reflection_id);
          handleClose();
        }
      });
    } else {
      handleClose();
    }
  };

  const handleShow = () => {
    setDailyReflection(data);
    if (data.tags) {
      setTags(
        data.tags.map((el) => {
          return { tag: el.tag, state: "Exist" };
        })
      );
    }
    console.log(tags);
    setShow(true);
  };

  const renderAuthors = () => {
    if (authors) {
      return authors.map((obj) => {
        return {
          label: obj.author_name,
          value: obj.author_id,
          prop: "author_id",
        };
      });
    }
    return [];
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (dailyReflection.deeplink_image_url) {
      window.URL.revokeObjectURL(dailyReflection.deeplink_image_url);
    }

    setDailyReflection({
      ...dailyReflection,
      deeplink_image_url: window.URL.createObjectURL(file),
    });

    uploadImage(file);
  };

  const handleAudioUpload = async (e) => {
    const file = e.target.files[0];
    if (dailyReflection.audio_url) {
      window.URL.revokeObjectURL(dailyReflection.audio_url);
    } else {
      setDailyReflection({
        ...dailyReflection,
        audio_url: window.URL.createObjectURL(file),
      });
    }

    await uploadAudio(file);
    setAudioUploaded(true);
  };

  const updateDuration = () => {
    const duration = Math.floor(audioRef.current.duration) || 0;
    setDailyReflection({ ...dailyReflection, audio_length: duration });
  };

  const handleFieldChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    console.log(name, value);
    setDailyReflection({ ...dailyReflection, [name]: value });
  };

  const handleDateChange = (date) => {
    setDailyReflection({ ...dailyReflection, date_epoch: date.unix() });
    setDate(date);
  };

  const handleSelectChange = (e) => {
    setDailyReflection({ ...dailyReflection, [e.prop]: e.value });
  };
  const handleTagChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setCurrentTag(value);
  };

  const clearState = () => {
    setDate(null);
    setTags([]);
    setDailyReflection({});
    setAudioUploaded(false);
    clearMedia();
  };
  const handleSubmit = async () => {
    for (const tag of tags) {
      if (tag.state === "Add") {
        await addTag(tag.tag);
      }
      if (tag.state === "Delete") {
        await removeTag(tag.tag);
      }
    }
    await addEditDailyReflections(dailyReflection);
    clearState();
    setShow(false);
  };

  const addTagToState = (tag) => {
    const state = {
      tag: tag,
      state: "Add",
    };
    console.log("tags", tags);
    setTags([...tags, state]);
    setCurrentTag("");
    console.log(tags);
  };

  const removeTagToState = (tag) => {
    const idx = tags.findIndex((el) => el.tag === tag);
    const target = tags[idx];
    if (target.state === "ADD") {
      setTags(tags.filter((el, i) => i !== idx));
    } else {
      tags[idx].state = "Delete";
      setTags([...tags]);
    }
  };
  const addTag = async (tag) => {
    const options = {
      object_id: dailyReflection.daily_reflection_id,
      tag: tag,
      object_type: "DailyInspiration",
    };
    await addObjectTag(options);
  };

  const removeTag = async (tag) => {
    const options = {
      object_id: dailyReflection.daily_reflection_id,
      tag: tag,
      object_type: "DailyInspiration",
    };
    await removeObjectTag(options);
  };

  const clearTags = () => {
    setTags([]);
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        {type}
      </Button>

      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{dailyReflection.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="utility-flex-row">
            <label className="utility-inline-label">
              <p>Date </p>
              <SingleDatePicker
                date={date} // momentPropTypes.momentObj or null
                onDateChange={(date) => handleDateChange(date)} // PropTypes.func.isRequired
                focused={focused} // PropTypes.bool
                onFocusChange={({ focused }) => setFocused(focused)} // PropTypes.func.isRequired
                id="your_unique_id" // PropTypes.string.isRequired,
              />
            </label>
            <label className="utility-inline-label">
              <p>Title </p>
              <input
                type="text"
                className="utility-text-input"
                name="title"
                value={dailyReflection.title}
                onChange={(e) => handleFieldChange(e)}
                style={{ width: "500px" }}
                required
              />
            </label>
          </div>

          <div className="utility-flex-row">
            <label className="utility-inline-label">
              <p>Deeplink Url </p>

              <input
                type="text"
                className="utility-text-input"
                name="deeplink_url"
                value={dailyReflection.deeplink_url}
                onChange={(e) => handleFieldChange(e)}
                style={{ width: "500px" }}
              />
            </label>
            <label className="utility-inline-label">
              <p>Deeplink Image </p>
              <div className="utility-flex-row">
                {dailyReflection.deeplink_image_url && (
                  <img
                    src={dailyReflection.deeplink_image_url}
                    alt="Deeplink Image Url"
                    style={{
                      maxHeight: "124px",
                      maxWidth: "150px",
                      marginRight: "20px",
                      marginBottom: "10px",
                    }}
                  />
                )}
              </div>
              <input
                type="file"
                name="deeplink_image_url"
                onChange={handleImageUpload}
                accept="image/*"
                required
                style={{ display: "none" }}
              />
              <p className="utility-btn-primary" style={{ color: "#fff" }}>
                Upload Deeplink Image
              </p>
            </label>
          </div>

          <div className="utility-flex-row">
            <label className="utility-inline-label">
              <p>Author</p>
              <Select
                name="author_id"
                value={dailyReflection.author_id}
                onChange={handleSelectChange}
                style={{ width: "259px" }}
                clearable={false}
                searchable={true}
                options={renderAuthors()}
              />
            </label>
            <label
              className="utility-inline-label"
              style={{ marginRight: "20px", width: "300px" }}
            >
              <p>Daily Inspiration Audio</p>
              <input
                type="file"
                onChange={handleAudioUpload}
                style={{ display: "none" }}
                accept={".mp3"}
              />
              <p
                className="utility-btn-primary"
                style={{ color: "#fff" }}
                tabIndex="0"
              >
                Upload Audio
              </p>

              <audio
                id="audio_url"
                src={dailyReflection.audio_url}
                onLoadedMetadata={() => updateDuration()}
                ref={audioRef}
                controls
                style={{ width: "300px" }}
              />
            </label>
          </div>
          {dailyReflection.daily_reflection_id && (
            <>
              <div className="utility-flex-row">
                <label className="utility-inline-label">
                  <p>Tags</p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "5px",
                      flexWrap: "wrap",
                    }}
                  >
                    {tags.length !== 0 &&
                      tags
                        .filter((el) => el.state !== "Delete")
                        .map((tag, idx) => {
                          return (
                            <div
                              key={idx}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                margin: "2px 2px",
                              }}
                            >
                              <p>•</p>
                              <p
                                style={{
                                  display: "inline-block",
                                  borderRadius: "3px",
                                  fontSize: "18px",
                                }}
                              >
                                {tag.tag}
                              </p>
                              <p
                                className="utility-btn-danger"
                                onClick={() => removeTagToState(tag.tag)}
                              >
                                X
                              </p>
                            </div>
                          );
                        })}
                  </div>
                </label>
              </div>
              <div className="utility-flex-row">
                <label className="utility-inline-label">
                  <input
                    type="text"
                    className="utility-text-input"
                    name="session_tag"
                    value={currentTag}
                    onChange={(e) => handleTagChange(e)}
                  />
                  <p
                    className="utility-btn-primary"
                    style={{ color: "#fff" }}
                    onClick={() => addTagToState(currentTag)}
                  >
                    Upload tag
                  </p>
                </label>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {dailyReflection.daily_reflection_id && (
            <Button variant="danger" onClick={handleDelete}>
              Delete
            </Button>
          )}
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function mapStateToProps(state) {
  return {
    daily_reflections: state.daily_reflections.daily_reflections || [],
    audio: state.media.audio,
    image: state.media.image,
  };
}

const mapDispatchToProps = {
  fetchAllDailyReflections,
  addEditDailyReflections,
  deleteDailyReflection,
  uploadImage,
  uploadAudio,
  addObjectTag,
  removeObjectTag,
  clearMedia,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DailyReflectionModal);
