import "bootstrap/dist/css/bootstrap.min.css";
import React, { Component, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import {
  fetchAllDailyReflections,
  fetchDailyInspirationDescription,
  updateDailyInspirationDescription,
} from "../../../actions/dailyReflectionActions";
import DailyReflectionModal from "./DailyReflectionModal";
import { SingleDatePicker } from "react-dates";
import HeaderBar from "./../../HeaderBar/HeaderBar";
import MainContentContainer from "./../../MainContentContainer/MainContentContainer";
import moment from "moment";
import { fetchAuthors } from "../../../actions/authorsActions";

function DateSelector() {
  const [date, setDate] = useState(null);
  const [focused, setFocused] = useState(false);
  return (
    <SingleDatePicker
      date={date} // momentPropTypes.momentObj or null
      onDateChange={(date) => setDate(date)} // PropTypes.func.isRequired
      focused={focused} // PropTypes.bool
      onFocusChange={({ focused }) => setFocused(focused)} // PropTypes.func.isRequired
      id="your_unique_id" // PropTypes.string.isRequired,
    />
  );
}
class DailyReflectionPage extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false, daily_inspiration_description: "" };
    this.colWidth1 = "300px";
    this.colWidth2 = "250px";
    this.colWidth3 = "150px";
  }

  componentDidMount() {
    this.props.fetchAllDailyReflections();
    this.props.fetchAuthors();
    this.props.fetchDailyInspirationDescription();
  }

  componentDidUpdate(prevProp) {
    if (
      prevProp.daily_inspiration_description !==
      this.props.daily_inspiration_description
    ) {
      this.setState({
        ...this.state,
        daily_inspiration_description: this.props.daily_inspiration_description,
      });
    } else if (
      !this.state.daily_inspiration_description &&
      this.props.daily_inspiration_description
    ) {
      this.setState({
        ...this.state,
        daily_inspiration_description: this.props.daily_inspiration_description,
      });
    }
  }
  createNew() {
    let initialState = {
      daily_reflection_id: null,
      title: "",
      image_url: "",
      audio_url: "",
      deeplink_url: null,
      deeplink_image_url: null,
      author_id: 2,
      updated_at: "N/A",
      is_premium: "",
      uploaded: false,
      day_string: "",
      date_epoch: null,
      audio_length: 0,
    };

    return (
      <DailyReflectionModal
        key={"new"}
        data={initialState}
        type={"Create"}
        authors={this.props.authors}
      />
    );
  }
  renderDailyReflections = () => {
    if (!this.props.daily_reflections.length) return null;
    return this.props.daily_reflections.map((initialState, index) => {
      // initialize

      let current_premium = "N/A";
      if (initialState.is_premium === 0) {
        current_premium = "Free";
      }
      if (initialState.is_premium === 1) {
        current_premium = <span className="utility-active">Premium</span>;
      }

      const date = initialState.date_epoch
        ? moment(initialState.date_epoch * 1000).format("YYYY-MM-DD")
        : "N/A";

      const current_date = moment().format("YYYY-MM-DD");

      let is_current_date = "NO";
      if (current_date === date) {
        is_current_date = <span className="utility-active">YES</span>;
      }
      return (
        <div className="utility-table-row" key={index}>
          <p style={{ width: this.colWidth3 }}>
            {initialState.daily_reflection_id}
          </p>
          <p style={{ width: this.colWidth2 }}>{date}</p>
          <p style={{ width: this.colWidth1 }}>{initialState.title}</p>
          <p style={{ width: this.colWidth3 }}>{current_premium}</p>
          <p style={{ width: this.colWidth2 }}>{is_current_date}</p>
          <div
            style={{ width: this.colWidth3 }}
            className="utility-table-end-col"
          >
            <div style={{ margin: "16px 0 16px auto" }}>
              <DailyReflectionModal
                key={index}
                data={initialState}
                type={"Edit"}
                authors={this.props.authors}
              />
            </div>
          </div>
        </div>
      );
    });
  };

  handleUpdate = (e) => {
    const { value } = e.target;
    this.setState({ ...this.state, daily_inspiration_description: value });
  };

  handleSave = (e) => {
    this.props.updateDailyInspirationDescription(
      this.state.daily_inspiration_description
    );
  };

  render() {
    return (
      <div className="utility-page-layout">
        <HeaderBar header="Daily Inspiration">
          <div>{this.createNew()}</div>
        </HeaderBar>
        <MainContentContainer>
          <div
            className="utility-flex-row"
            style={{ alignItems: "left", justifyContent: "left" }}
          >
            <input
              type="text"
              value={this.state.daily_inspiration_description}
              className="utility-text-input"
              onChange={this.handleUpdate}
              style={{ marginLeft: "20px", width: "80%" }}
            />

            <Button className="utility-btn-primary" onClick={this.handleSave}>
              Save Description
            </Button>
          </div>
          <div
            className="utility-flex-row"
            style={{
              alignItems: "center",
              justifyContent: "center",
              marginTop: "100px",
            }}
          >
            <div className="utility-page-layout">
              <div className="utility-table-row utility-table-row-headers">
                <h2 style={{ width: this.colWidth3 }}>Id </h2>
                <h2 style={{ width: this.colWidth2 }}>Date </h2>
                <h2 style={{ width: this.colWidth1 }}>Title</h2>
                <h2 style={{ width: this.colWidth3 }}> Premium </h2>
                <h2 style={{ width: this.colWidth2 }}>Is Current Date </h2>
                <h2 style={{ width: this.colWidth3 }}> </h2>
              </div>
              {this.renderDailyReflections()}
            </div>
          </div>
        </MainContentContainer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    daily_reflections: state.daily_reflections.daily_reflections || [],
    authors: state.authors.authors,
    daily_inspiration_description: state.general_data.general_data
      ? state.general_data.general_data.daily_inspiration_description
      : "",
  };
}

const mapDispatchToProps = {
  fetchAllDailyReflections,
  fetchAuthors,
  fetchDailyInspirationDescription,
  updateDailyInspirationDescription,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DailyReflectionPage);
