import React from 'react'
import {
  Box,
  Typography,
  Card,
  CardContent,
} from "@mui/material"

const LinkedContentButton = ({ title, card, ...props }) => {
  return (
    <Card
      {...props}
    >
      <CardContent>
        <Box sx={{ marginBottom: 2 }}>
          <Typography variant="body2" color="text.secondary">{title}</Typography>
        </Box>
        <Box
          sx={{
            borderRadius: '10px',
            flex: '1 1 auto',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '14px 24px',
            gap: '10px',
            border: '1px solid #048cb9',
          }}>
          <Box
            sx={{
              height: '24px',
              width: '24px',
            }}>
            {card.linked_content_button_icon_url ? (
              <Box component="img"
                src={card.linked_content_button_icon_url}
                alt="Linked Content Button Icon"
                sx={{
                  height: '24px',
                  width: '24px',
                }} />
            ) : null}
          </Box>
          <Typography
            sx={{
              textAlign: 'center',
              verticalAlign: 'middle',
              fontSize: '16px',
              fontFamily: 'Asap',
              lineHeight: '24px',
              color: '#65c8da',
            }}>
            {card.linked_content_button_text}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}

export default LinkedContentButton