import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  addEditCancellationReasons,
  fetchAllcancellationReasons,
  deleteCancellationReasons,
} from "../../../actions/userCancellationActions";
import { arrayMoveImmutable } from "array-move";
import MainContentContainer from "../../MainContentContainer/MainContentContainer";
import SortableData from "./Sortable";

const CancellationReasonPage = ({
  addEditCancellationReasons,
  fetchAllcancellationReasons,
  deleteCancellationReasons,
  cancellation_reasons,
}) => {
  const [cancellationReasons, setCancellationReasons] = useState([]);

  useEffect(() => {
    fetchAllcancellationReasons();
  }, []);

  useEffect(() => {
    if (cancellation_reasons.length) {
      if (cancellation_reasons.length !== cancellationReasons.length) {
        setCancellationReasons(cancellation_reasons);
      }
    }
  }, [cancellation_reasons]);

  const addNew = () => {
    setCancellationReasons([
      ...cancellationReasons,
      { reason: "", sort_order: cancellationReasons.length + 1 },
    ]);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setCancellationReasons(arrayMoveImmutable(cancellationReasons, oldIndex, newIndex));
  };

  const handleChange = (e, index) => {
    const value = e.target.value;
    console.log(value);
    let items = [...cancellationReasons];
    let item = { ...cancellationReasons[index] };
    item.reason = value;
    items[index] = item;
    setCancellationReasons(items);
  };

  const handleDelete = (e, ref_id, index) => {
    e.preventDefault();
    if (ref_id) {
      deleteCancellationReasons(ref_id);
    }
    setCancellationReasons(cancellationReasons.filter((el, i) => i !== index));
  };

  const handleSubmit = () => {
    addEditCancellationReasons(cancellationReasons);
    setCancellationReasons([]);
  };

  return (
    <div className="utility-page-layout" style={{ width: "50%" }}>
      <MainContentContainer>
        <div>
          <div className="HeaderBar">
            <h2>Cancellation Reasons</h2>
          </div>

          <div className="utility-flex-end">
            <button
              className="utility-btn-transparent"
              onClick={() => addNew()}
            >
              Add New
            </button>
          </div>
          <div>
            <SortableData
              items={cancellationReasons}
              onSortEnd={onSortEnd}
              onDelete={handleDelete}
              handleChange={handleChange}
            />
          </div>
          <div className="utility-flex-end" style={{ marginTop: "30px" }}>
            <button
              className="utility-btn-primary"
              style={{ width: "35%" }}
              onClick={() => handleSubmit()}
            >
              Save
            </button>
          </div>
        </div>
      </MainContentContainer>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    cancellation_reasons: state.cancellation_reasons.cancellation_reasons || [],
  };
}

const mapDispatchToProps = {
  addEditCancellationReasons,
  fetchAllcancellationReasons,
  deleteCancellationReasons,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CancellationReasonPage);
