import React from "react";
import { Link } from "react-router-dom";
import HeaderBar from "./../../HeaderBar/HeaderBar";
import MainContentContainer from "./../../MainContentContainer/MainContentContainer";
import QuickTapsTable from "../../QuickTapsTable/QuickTapsTable";

export default function QuickTapsPage() {
  return (
    <div className="utility-page-layout">
      <HeaderBar header="Quick Taps">
        <Link to="/quick-taps/featured" className="utility-btn-primary">
          Featured
        </Link>
        <Link to="/quick-taps/add" className="utility-btn-primary utility-lr-margin-md">
          Add Quick Tap
        </Link>
      </HeaderBar>
      <MainContentContainer>
        <QuickTapsTable />
      </MainContentContainer>
    </div>
  );
}
