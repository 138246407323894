import axios from 'axios';
import { push } from 'react-router-redux';
import { API_URL, errorHandler, queryString } from './index';
import {
  API_ERROR,
  FETCH_ALL_QUOTES,

 } from '../constants/ActionTypes';
import swal from 'sweetalert2';

import SessionHelper from '../components/Helper/SessionHelper';

//= ===============================
// Client actions
//= ===============================

export function fetchAllQuotes() {
  return function(dispatch) {
    axios.post(`${API_URL}/fetch-all-quotes.php`,
                        queryString.stringify({
                            requesting_user_id: SessionHelper.currentUser().admin_id,
                            user_token: SessionHelper.getToken()
                        }))
    .then((response) => {
      dispatch({
        type: FETCH_ALL_QUOTES,
        payload: response.data.objects,
      });
    })
    .catch((error) => {
      errorHandler(dispatch, error.response, API_ERROR)
    });
  }
}

export function clearAllQuotes() {
  return function(dispatch) {
    dispatch({
      type: FETCH_ALL_QUOTES,
      payload: null,
    });
  }
}

export function addEditQuote(quote_json) {
  return function(dispatch) {
    axios.post(`${API_URL}/add-n-edit-dashboard-quote.php`,
                        queryString.stringify({
                            requesting_user_id: SessionHelper.currentUser().admin_id,
                            user_token: SessionHelper.getToken(),
                            quote_json: quote_json
                        }))
    .then((response) => {
      swal({
        type: 'success',
        title: 'Quote Saved!',
        timer: 1000
      }).then(()=>{
          dispatch(push('/quotes'));
      });
    })
    .catch((error) => {
      errorHandler(dispatch, error.response, API_ERROR)
    });
  }
}

export function deleteQuote(quote_id) {
  return function(dispatch) {
    axios.post(`${API_URL}/delete-quote.php`,
                        queryString.stringify({
                            requesting_user_id: SessionHelper.currentUser().admin_id,
                            user_token: SessionHelper.getToken(),
                            quote_id: quote_id
                        }))
    .then((response) => {
      swal({
        type: 'success',
        title: 'Quote Deleted!',
        timer: 1000
      }).then(()=>{
          dispatch(push('/quotes'));
      });
    })
    .catch((error) => {
      errorHandler(dispatch, error.response, API_ERROR)
    });
  }
}
