import React, { Component } from "react";
import Select from "react-select";
import "react-select/dist/react-select.css";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { MdReorder, MdDelete } from "react-icons/md";

const DragHandle = SortableHandle(() => <div className="sort-handle" title='Drag to reorder'><MdReorder/></div>);

const SortableList = SortableContainer(
  ({
    items,
    options,
    editDropDown,
    removeItem,
    handleSessionData,
    sessions,
    quickTapSessions,
    state,
  }) => {
    return (
      <div className="DynamicOptionsSelect">
        {items.map((value, index) => {
          return (
            <SortableItem
              index={index}
              key={`dynamic-item-${index}`}
              place={index}
              value={value}
              options={options}
              editDropDown={editDropDown}
              removeItem={removeItem}
              handleSessionData={handleSessionData}
              sessions={sessions}
              quickTapSessions={quickTapSessions}
              state={state}
            />
          );
        })}
      </div>
    );
  }
);

const SortableItem = SortableElement(
  ({
    place,
    value,
    options,
    editDropDown,
    removeItem,
    handleSessionData,
    sessions,
    quickTapSessions,
    state,
  }) => {
    let isQuickTap = value.indexOf("QTSESS") > 0;
    let itemId = parseInt(value.split(/ /)[0].replace(/[^\d]/g, ""));
    var item = {};

    if (isQuickTap){
      item = quickTapSessions.map(
        (quickTap) => ({
          id: quickTap.id,
          title: quickTap.title
        })
      ).find(
        (it) => it.id === itemId
      )
    } else {
      item = sessions.map(
        (it) => ({
          id: it.session_id,
          title: it.session_title
        })
      ).find(
        (obj) =>
          obj.id === itemId
      );
    }

    const name = item ? item.id : "session_data";
    const session_data = state.session_data.find((obj) => {
      return (
        parseInt(Object.keys(obj)[0]) ===
        parseInt(value.split(/ /)[0].replace(/[^\d]/g, ""))
      );
    });

    return (
      <div
        key={place + "-select-item"}
        className="utility-flex-row"
        style={{ padding: "5px 70px 5px 0", alignItems: 'center' }}
      >
        {item && session_data && (
          <div style={{ marginRight: "20px", fontSize: "12px" }}>
            <div>
              <label style={{ alignSelf: "center" }}>
                Featured:
                <input
                  style={{
                    marginLeft: "10px",
                    marginBottom: "10px",
                    marginRight: "10px",
                  }}
                  type="checkbox"
                  name={name}
                  value={session_data[item.id].session_is_featured}
                  onChange={handleSessionData}
                  checked={session_data[item.id].session_is_featured}
                />
              </label>
            </div>
            <div style={{}}>
              <label>DarkMode :</label>
              <input
                style={{ marginLeft: "10px", marginBottom: "10px" }}
                type="checkbox"
                name={name}
                value={session_data[item.id].session_is_dark_mode}
                onChange={(e) => handleSessionData(e, false)}
                checked={session_data[item.id].session_is_dark_mode}
              />
            </div>
          </div>
        )}

        <Select
          value={value}
          onChange={(e) => {
            editDropDown(e, place);
          }}
          style={{ width: "700px" }}
          clearable={false}
          searchable={true}
          options={options}
        />

        <button
          className="utility-btn-transparent"
          style={{ color: "#d0021b", fontSize: "20px" }}
          title="Remove"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            removeItem(place);
          }}
        >
          <MdDelete/>
        </button>
        <DragHandle />
      </div>
    );
  }
);

class DynamicOptionsSelect extends Component {
  render() {
    return (
      <SortableList
        items={this.props.items || []}
        onSortEnd={this.props.editSort}
        options={this.props.options}
        editDropDown={this.props.editDropDown}
        removeItem={this.props.removeItem}
        useDragHandle={true}
        handleSessionData={this.props.handleSessionData}
        sessions={this.props.sessions}
        quickTapSessions={this.props.quickTapSessions}
        state={this.props.state}
        helperClass="utility-flex-row-dragging"
      />
    );
  }
}

export default DynamicOptionsSelect;
