import React, { Component } from 'react';
import AddEditSession from '../../AddEditSession/AddEditSession';

class SessionDuplicatePage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
  const idDuplicate = this.props.match.params.id > 0;
  const duplicateSessionId = this.props.match.params.id
  console.log(duplicateSessionId);

    console.log("duplicate props : " ,this.props);
    return (
      <div className="utility-page-layout">
        <AddEditSession
          isEdit={false}
          isDuplicate={idDuplicate}
          duplicateSessionId={duplicateSessionId}
        />
      </div>
    );
  }

}

export default SessionDuplicatePage;
