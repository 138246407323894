import React, { Component } from "react";
import AddEditChallenges from "../../AddEditChallenges/AddEditChallenges";

class ChallengeAddEditPage extends Component {
  render() {
    const isEdit = this.props.location.pathname.indexOf("edit") > -1;
    return (
      <div className="utility-page-layout">
        <AddEditChallenges
          isEdit={isEdit}
          selectedId={this.props.match.params.id}
        />
      </div>
    );
  }
}

export default ChallengeAddEditPage;
