import axios from "axios";
import { API_URL, errorHandler, queryString } from "./index";
import { API_ERROR } from "../constants/ActionTypes";

import SessionHelper from "../components/Helper/SessionHelper";

//= ===============================
// Client actions
//= ===============================

export function addObjectTag(options) {
  console.log("add tag option", options);
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/add-object-tag.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken(),
          object_id: options.object_id,
          object_type: options.object_type,
          tag: options.tag,
        })
      )
      .then((response) => {
        swal({
          type: "success",
          title: "Tag added!",
          timer: 1000,
        }).then(() => {
          dispatch(push("/sessions"));
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function removeObjectTag(options) {
  console.log("remove tag options", options);
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/remove-object-tag.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken(),
          object_id: options.object_id,
          object_type: options.object_type,
          tag: options.tag,
        })
      )
      .then((response) => {
        swal({
          type: "success",
          title: "Tag removed!",
          timer: 1000,
        }).then(() => {
          dispatch(push("/sessions"));
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}
