import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchAllCategories,
  clearCategories,
  sortCategories,
} from "./../../actions/categoryActions";
import { Link, withRouter } from "react-router-dom";
import { arrayMoveImmutable } from "array-move";
import SortableCategories from "./SortableCategories/SortableCategories";
import { MdModeEdit } from 'react-icons/md';

class CategoriesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      all_categories: null,
      filtered_categories: null,
      searchText: "",
      highlight_index: null,
    };
  }

  componentDidMount() {
    this.props.clearCategories();
    this.props.fetchAllCategories();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.all_categories && this.state.all_categories === null) {
      const all_categories = nextProps.all_categories
        .filter((el) => el.category_is_audiobook === 0)
        .sort(
          (a, b) =>
            Number(a.category_sort_order) - Number(b.category_sort_order)
        );

      this.setState({ all_categories: all_categories });
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.highlight_index !== this.props.highlight_index){
      this.setState({ highlight_index: this.props.highlight_index})
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(
      {
        all_categories: arrayMoveImmutable(
          this.state.all_categories,
          oldIndex,
          newIndex
        ),
        highlight_index: newIndex
      },
      () => {
        const sort_json = this.state.all_categories.map(
          (obj) => obj.category_id
        );
        this.props.sortCategories(JSON.stringify(sort_json));
      }
    );
  };

  handleSearchChange = (e) => {
    const { value } = e.target;
    let search = value.toLowerCase();
    const filtered_categories = value.length > 0 ? this.state.all_categories.filter(
      (obj) => obj.category_title.toLowerCase().indexOf(search) > -1
    ) : null;
    this.setState({ searchText: value, filtered_categories: filtered_categories });
  };

  render() {
    if (!this.state.all_categories) {
      return <h1 style={{ textAlign: "center" }}>Loading</h1>;
    }

    const dragKey = this.state.all_categories.reduce(
      (accum, val) => (accum += val.category_id),
      "sort-key"
    );

    return (
      <div className="CategoriesTable">
        <div
          className="utility-flex-row"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <p>
            <b>Search Categories: </b>
          </p>
          <input
            type="text"
            value={this.state.searchText}
            className="utility-text-input"
            onChange={this.handleSearchChange}
            style={{ marginLeft: "20px" }}
          />
        </div>

        {this.state.searchText !== "" && (
          <h3>Showing categories matching search: {this.state.searchText}</h3>
        )}
        {/* Header Row */}
        <div className="utility-table-row utility-table-row-headers">
            <div className="utility-table-column">Sort Order</div>
            <div className="utility-table-column utility-table-column-expanded">Title</div>
            <div className="utility-table-column">Active</div>
            <div className="utility-table-column">Subcategories</div>
            <div className="utility-table-column">Sessions</div>
            <div className="utility-table-column">Quick Tap?</div>
            <div className="utility-table-column"></div>
        </div>
        <SortableCategories
          items={this.state.filtered_categories == null ? this.state.all_categories : this.state.filtered_categories}
          onSortEnd={this.onSortEnd}
          key={this.state.filtered_categories == null ? dragKey : "sort-key0"}
          isSearching={this.state.searchText !== ""}
          highlightIndex={this.state.highlight_index}
          />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    all_categories: state.categories.all_categories,
    highlight_index: state.highlight_index
  };
}

const mapDispatchToProps = {
  fetchAllCategories,
  clearCategories,
  sortCategories,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CategoriesTable)
);
