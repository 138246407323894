import React, { Component } from 'react';
import AddEditSubCategory from './../../AddEditSubCategory/AddEditSubCategory';

class SubCategoryAddEditPage extends Component {

  render() {
    const isEdit = this.props.location.pathname.indexOf('edit') > -1;
    return (
      <div className="utility-page-layout">
        <AddEditSubCategory
          isEdit={isEdit}
          selectedId={this.props.match.params.id}
        />
      </div>
    );
  }

}

export default SubCategoryAddEditPage;
