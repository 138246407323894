import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import MainContentContainer from "../../MainContentContainer/MainContentContainer";
import {
  addEditBGM,
  deleteBGM,
  fetchAllBGM,
} from "./../../../actions/backgroundMusicActions";

class BackgroundMusicTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      background_musics: [],
    };
    this.colWidth1 = "536px";
    this.colWidth2 = "400px";
    this.colWidth3 = "220px";
  }
  componentDidMount() {
    this.props.fetchAllBGM();
  }

  renderBGM() {
    if (!this.props.background_musics) return null;

    return this.props.background_musics.map((music) => {
      return (
        <div className="utility-table-row" key={music.bgm_id + music.bgm_title}>
          <p style={{ width: this.colWidth1 }}>{music.bgm_title}</p>
          <a href={music.bgm_url}>Link </a>
          <Link
            to={"/background-musics/edit/" + music.bgm_id}
            className="utility-edit-link"
          >
            Edit
          </Link>
        </div>
      );
    });
  }
  render() {
    return (
      <div>
        {/* <HeaderBar header={categoryName}>
          <Link className="utility-btn-primary" to={"/series/add"}>
            Create New Series
          </Link>
        </HeaderBar> */}

        <MainContentContainer>
          <div className="BGMTable">
            <div className="utility-table-row utility-table-row-headers">
              <h2 style={{ width: this.colWidth1 }}>Title</h2>
              <h2 style={{ width: this.colWidth2 }}> BGM Link </h2>
            </div>
            {this.renderBGM()}
          </div>
        </MainContentContainer>
      </div>
    );
    return <div></div>;
  }
}

function mapStateToProps(state) {
  return {
    background_musics: state.background_musics.background_musics,
  };
}

const mapDispatchToProps = {
  fetchAllBGM,
  deleteBGM,
  addEditBGM,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BackgroundMusicTable);
