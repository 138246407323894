import axios from "axios";
import { API_URL, errorHandler, queryString } from "./index";
import { push } from "react-router-redux";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import {
  API_ERROR,
  FETCH_ALL_GENERAL_DATA,
  FETCH_ALL_CHALLENGES,
  FETCH_ONE_CHALLENGE,
} from "../constants/ActionTypes";

import SessionHelper from "../components/Helper/SessionHelper";

//= ===============================
// Session actions
//= ===============================

export function fetchAllChallenges(type = 0) {
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/fetch-cms-challenges.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken(),
          is_audiobook: type,
        })
      )
      .then((response) => {
        const challenges = response.data.objects.reduce((acc, el) => {
          acc[el.challenge_id] = el;
          return acc;
        }, {});
        dispatch({
          type: FETCH_ALL_CHALLENGES,
          payload: challenges,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export async function fetchChallengeIntro() {
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/fetch-challenge-intro.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken(),
        })
      )
      .then((response) => {
        console.log("response: ", response);
        dispatch({
          type: FETCH_ALL_GENERAL_DATA,
          payload: response.data,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function updateChallengeIntro(challenge_intro_text) {
  return async function (dispatch) {
    try {
      axios.post(
        `${API_URL}/update-challenge-intro.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken(),
          challenge_intro_text: challenge_intro_text,
        })
      );
    } catch (error) {
      return errorHandler(dispatch, error.response, API_ERROR);
    }
    swal({
      type: "success",
      title: "Saved!",
      timer: 1000,
    }).then(() => {
      dispatch(push("/challenges"));
    });
  };
}

export function fetchOneChallenge(challenge_id) {
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/fetch-cms-challenge.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken(),
          challenge_id: challenge_id,
        })
      )
      .then((response) => {
        dispatch({
          type: FETCH_ONE_CHALLENGE,
          payload: { [response.data.challenge_id]: response.data },
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function clearAllChallenges() {
  return function (dispatch) {
    dispatch({
      type: FETCH_ALL_CHALLENGES,
      payload: null,
    });
  };
}

export function addEditChallenges(options) {
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/add-n-edit-challenge.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken(),
          challenge_json: options.challenge_json,
          session_ids_json: options.session_ids_json,
          intro_session_json: options.intro_session_json,
        })
      )
      .then((response) => {
        swal({
          type: "success",
          title: "Saved!",
          timer: 1000,
        }).then(() => {
          dispatch(push("/challenges"));
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function deleteChallenges(challenge_id) {
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/delete-challenge.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          challenge_id: challenge_id,
          user_token: SessionHelper.getToken(),
        })
      )
      .then((response) => {
        swal({
          type: "success",
          title: "challenge Deleted!",
          timer: 1000,
        }).then(() => {
          dispatch(push("/challenges"));
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function sortChallenges(challenge_ids) {
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/update-challenge-order.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          challenge_ids: challenge_ids,
          user_token: SessionHelper.getToken(),
        })
      )
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}
