import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import HeaderBar from "./../HeaderBar/HeaderBar";
import MainContentContainer from "./../MainContentContainer/MainContentContainer";

import {
  fetchAllQuotes,
  clearAllQuotes,
  addEditQuote,
  deleteQuote,
} from "./../../actions/quoteActions";
import swal from "sweetalert2";

class AddEditQuotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quote_text: "",
      quote_speaker_name: "",
      quote_speaker_title: "",
      isSetToEdit: false,
    };
  }

  componentDidMount() {
    this.props.clearAllQuotes();
    if (this.props.isEdit) {
      this.props.fetchAllQuotes();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.isEdit && nextProps.all_quotes && !this.state.isSetToEdit) {
      let currentQuote = null;
      for (let x = 0; x < nextProps.all_quotes.length; x++) {
        if (
          nextProps.all_quotes[x].quote_id === Number(this.props.selectedId)
        ) {
          currentQuote = nextProps.all_quotes[x];
          break;
        }
      }
      if (currentQuote === null) {
        swal({
          type: "warning",
          text: "Error finding selected quote",
        });
      }
      this.setState({ ...currentQuote, isSetToEdit: true });
    }
  }

  handleFieldChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState((prevState) => {
      const state = { ...prevState };
      state[name] = value;
      return state;
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let data = { ...this.state };
    data = JSON.stringify(data);
    this.props.addEditQuote(data);
  };

  handleDelete = (e) => {
    e.preventDefault();
    swal({
      type: "warning",
      text: "Are you sure you want to delete this quote?",
      showConfirmButton: true,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: "Delete Quote",
    }).then((result) => {
      if (result.value) {
        this.props.deleteQuote(this.props.selectedId);
      }
    });
  };

  render() {
    const { isEdit, all_quotes } = this.props;

    if (isEdit && !all_quotes) {
      return <h1 style={{ textAlign: "center" }}>Loading...</h1>;
    }

    return (
      <div>
        <form
          className="AddEditQuote"
          onSubmit={this.handleSubmit}
          autoComplete="off"
        >
          <HeaderBar header={isEdit ? "Edit Quote" : "Add Quote"}>
            {isEdit && (
              <Link
                to="/quotes"
                className="utility-btn-transparent utility-lr-margin-small"
              >
                Cancel
              </Link>
            )}
            <button className="utility-btn-primary">
              {isEdit ? "Update" : "Create"}
            </button>
          </HeaderBar>
          <MainContentContainer subContent={true}>
            <div className="utility-flex-row">
              <label className="utility-inline-label">
                <p>Quote Text</p>
                <textarea
                  className="utility-textarea"
                  name="quote_text"
                  value={this.state.quote_text}
                  onChange={this.handleFieldChange}
                  required
                />
              </label>
            </div>
            <div className="utility-flex-row">
              <label className="utility-inline-label">
                <p>Source</p>
                <input
                  className="utility-text-input"
                  name="quote_speaker_name"
                  value={this.state.quote_speaker_name}
                  onChange={this.handleFieldChange}
                  style={{ width: "580px" }}
                  required
                />
              </label>
            </div>

            {isEdit && (
              <div style={{ marginTop: "20px" }}>
                <button
                  className="utility-btn-danger"
                  onClick={this.handleDelete}
                >
                  Delete Quote
                </button>
              </div>
            )}
          </MainContentContainer>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    all_quotes: state.quotes.all_quotes,
  };
}

const mapDispatchToProps = {
  fetchAllQuotes,
  clearAllQuotes,
  addEditQuote,
  deleteQuote,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditQuotes);
