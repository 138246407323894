import React, { Component } from 'react';
import './HeaderBar.css'

class HeaderBar extends Component {

  render() {
    return (
      <div className="HeaderBar">
        <h1>{this.props.header}</h1>
        <div className="HeaderBar-right">
          { this.props.children }
        </div>
      </div>
    );
  }

}

export default HeaderBar;
