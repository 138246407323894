import axios from "axios";
import swal from "sweetalert2";
import { push } from "react-router-redux";
import { API_URL, errorHandler, queryString } from "./index";
import {
  FETCH_ALL_DAILY_REFLECTIONS,
  API_ERROR,
  FETCH_ALL_GENERAL_DATA,
} from "../constants/ActionTypes";
import SessionHelper from "../components/Helper/SessionHelper";

export function fetchAllDailyReflections() {
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/fetch-cms-daily-reflections.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken(),
        })
      )
      .then((response) => {
        dispatch({
          type: FETCH_ALL_DAILY_REFLECTIONS,
          payload: response.data.objects,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function addEditDailyReflections(daily_reflection_json) {
  console.log("request came throuygh ", daily_reflection_json);
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/add-n-edit-daily-reflection.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken(),

          daily_reflection_json: JSON.stringify(daily_reflection_json),
        })
      )
      .then((response) => {
        swal({
          type: "success",
          title: "Saved!",
          timer: 1000,
        }).then(() => {
          dispatch(fetchAllDailyReflections());
        });
      })
      .catch((error) => {
        swal({
          type: "error",
          title: error.response.data.message,
          heightAuto: true,
        });
      });
  };
}

export function deleteDailyReflection(daily_reflection_id) {
  console.log("request came throuygh ", daily_reflection_id);
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/delete-daily-reflection.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken(),
          daily_reflection_id: daily_reflection_id,
        })
      )
      .then((response) => {
        console.log("response", response);
        swal({
          type: "success",
          title: "Deleted Successfully!",
          timer: 1000,
        }).then(() => {
          dispatch(fetchAllDailyReflections());
        });
      })
      .catch((error) => {
        console.log("error");

        swal({
          type: "error",
          title: error.response.data.message,
          heightAuto: true,
        });
      });
  };
}

export async function fetchDailyInspirationDescription() {
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/fetch-di-description.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken(),
        })
      )
      .then((response) => {
        console.log("response: ", response);
        dispatch({
          type: FETCH_ALL_GENERAL_DATA,
          payload: response.data,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function updateDailyInspirationDescription(
  daily_inspiration_description
) {
  return async function (dispatch) {
    try {
      axios.post(
        `${API_URL}/update-di-description.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken(),
          daily_inspiration_description: daily_inspiration_description,
        })
      );
    } catch (error) {
      return errorHandler(dispatch, error.response, API_ERROR);
    }
    swal({
      type: "success",
      title: "Saved!",
      timer: 1000,
    });
  };
}
