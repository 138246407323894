import React, { Component } from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { MdReorder } from "react-icons/md";

const DragHandle = SortableHandle(() => <div className="sort-handle" title='Drag to reorder'><MdReorder/></div>);

const SortableItem = SortableElement(
  ({ value, sessions, state, handleSessionData }) => {
    let label = "";

    let item = value;
    if (item) {
      label = item.session_name;
    }

    const name = `series_${value.session_id}`;
    return (
      <div
        className="utility-flex-row"
        key={"sortable-session" + value.session_id}
        style={{ alignItems: "center", paddingBottom: "15px" }}
      >
        <DragHandle />
        <p style={{ marginLeft: "20px", fontSize: "16px", width: "500px" }}>
          {label}
        </p>
        <div style={{ marginLeft: "20px" }}>
          <label>Featured :</label>
          <input
            style={{ marginLeft: "10px", marginBottom: "10px" }}
            type="checkbox"
            name={value.session_id}
            value={!!value.session_is_featured}
            onChange={handleSessionData}
            checked={!!value.session_is_featured}
          />
        </div>
        <div style={{ marginLeft: "20px" }}>
          <label>DarkMode :</label>
          <input
            style={{ marginLeft: "10px", marginBottom: "10px" }}
            type="checkbox"
            name={value.session_id}
            value={!!value.session_is_dark_mode}
            onChange={(e) => handleSessionData(e, false)}
            checked={!!value.session_is_dark_mode}
          />
        </div>
      </div>
    );
  }
);

const SortableList = SortableContainer(
  ({ items, sessions, handleSessionData, state }) => {
    return (
      <div>
        {items.map((value, index) => (
          <SortableItem
            key={`item-${value.session_id}`}
            index={index}
            value={value}
            sessions={sessions}
            handleSessionData={handleSessionData}
            state={state}
          />
        ))}
      </div>
    );
  }
);

class SortableSessions extends Component {
  render() {
    return (
      <SortableList
        items={this.props.items}
        onSortEnd={this.props.onSortEnd}
        useDragHandle={true}
        sessions={this.props.sessions}
        handleSessionData={this.props.handleSessionData}
        state={this.props.state}
        helperClass="utility-flex-row-dragging"
      />
    );
  }
}

export default SortableSessions;
