import { Grid, TextField, FormGroup, Switch, FormControlLabel } from '@mui/material'
import React, { useEffect } from 'react'
import CardDeckImage from '../Common/CardDeckImage'
import { useDispatch, useSelector } from 'react-redux';
import { uploadImage, clearMedia } from '../../../../actions/mediaActions';
import LinkedContentButton from './LinkedContentButton';
import FieldSetGroup from '../../../Elements/FieldSetGroup'

export default function CardFormFields({ card, handleChange }) {
    const dispatch = useDispatch();

    const mediaStore = useSelector((state) => {
        return state.media
    });

    useEffect(() => {
        if (mediaStore.image) {
            card[mediaStore.target] = mediaStore.image;
            dispatch(clearMedia());
            handleChange({ target: { name: mediaStore.target, value: mediaStore.image } });
        }
    }, [mediaStore]);

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        dispatch(uploadImage(file, e.target.name));
    };

    const removeImage = (e) => {
        handleChange({ target: { name: e.target.name, value: "" } });
    }

    const handleSwitchChange = (e) => {
        handleChange({ target: { name: e.target.name, value: e.target.checked ? 1 : 0 } });
    }



    return (
        <Grid container spacing={2} sx={{ marginTop: 1 }}>
            <Grid item xs={8}>
                <TextField
                    fullWidth
                    label="Title"
                    variant="outlined"
                    name="title"
                    value={card.title || ""}
                    onChange={handleChange}
                    autoFocus
                />
            </Grid>
            <Grid container item xs={4} justifyContent="flex-end" alignItems="center">
                <FormGroup>
                    <FormControlLabel
                        control={<Switch aria-label="is active" checked={Boolean(card.is_active)} onChange={handleSwitchChange} name="is_active" />}
                        label="Is Active?"
                    />
                </FormGroup>
            </Grid>
            <Grid item xs={12}>
                <FieldSetGroup title="Card Images">
                    <Grid container spacing={2} item xs={12}>
                        <Grid item xs={3}>
                            <CardDeckImage
                                title="Front Image"
                                image={card.front_image_url || ""}
                                name="front_image_url"
                                handleRemove={removeImage}
                                handleImageUpload={handleImageUpload}
                                sx={{ padding: 1, background: '#FFFCF5', height: 250, objectFit: 'contain' }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <CardDeckImage
                                title="Back Image"
                                image={card.back_image_url || ""}
                                name="back_image_url"
                                handleRemove={removeImage}
                                handleImageUpload={handleImageUpload}
                                sx={{ padding: 1, background: '#FFFCF5', height: 250, objectFit: 'contain' }}
                            />
                        </Grid>
                    </Grid>
                </FieldSetGroup>
            </Grid>
            <Grid item xs={12}>
                <FieldSetGroup title="Sharing">
                    <Grid container spacing={2} item xs={12}>
                        <Grid item xs={3}>
                            <CardDeckImage
                                title="Share Image"
                                image={card.share_image_url || ""}
                                handleRemove={removeImage}
                                handleImageUpload={handleImageUpload}
                                name="share_image_url"
                                showCopyUrlButton={true}
                                sx={{ padding: 5, background: '#FFFCF5', height: 250, objectFit: 'contain' }}
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <Grid item xs={12} sx={{ marginBottom: 2 }}>
                                <TextField
                                    fullWidth
                                    label="Share Deeplink URL"
                                    variant="outlined"
                                    name="share_deeplink_url"
                                    value={card.share_deeplink_url || ""}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </FieldSetGroup>
            </Grid>
            <Grid item xs={12} >
                <FieldSetGroup title="Linked Content">
                    <Grid container spacing={2} item xs={12} >
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                label="Link (Web)"
                                variant="outlined"
                                name="target_web_deeplink_url"
                                value={card.target_web_deeplink_url || ""}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                label="Link (Android)"
                                variant="outlined"
                                name="target_android_deeplink_url"
                                value={card.target_android_deeplink_url || ""}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                label="Link (iOS)"
                                variant="outlined"
                                name="target_ios_deeplink_url"
                                value={card.target_ios_deeplink_url || ""}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid container spacing={2} item xs={12}>
                            {/* Left Column */}
                            <Grid item xs={12} container spacing={2} alignItems="stretch">
                                <Grid item xs={2}>
                                    <CardDeckImage
                                        title="Icon"
                                        image={card.linked_content_button_icon_url || ""}
                                        name="linked_content_button_icon_url"
                                        handleRemove={removeImage}
                                        handleImageUpload={handleImageUpload}
                                        sx={{ padding: 2, background: '#FFFCF5', height: 110, objectFit: 'contain' }}
                                    />
                                </Grid>
                                <Grid item xs={10} container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Button Text"
                                            variant="outlined"
                                            name="linked_content_button_text"
                                            value={card.linked_content_button_text || ''}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <LinkedContentButton sx={{ background: '#FFFCF5' }} card={card} title="Linked Content Button Preview" />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </FieldSetGroup>
            </Grid>
        </Grid>
    )
}
