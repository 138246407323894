import axios from "axios";
import { API_URL, errorHandler, queryString } from "./index";
import {
  API_ERROR,
  FETCH_SEARCH_DEEPLINKS,
} from "../constants/ActionTypes";
import swal from "sweetalert2";

import SessionHelper from "../components/Helper/SessionHelper";


export function fetchSearchDeeplinksInfo() {
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/fetch-general-info.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken()
        })
      )
      .then(response => {
        var data = {
          deeplink_ios: response.data.search_deeplink_ios_url ?? "",
          deeplink_android: response.data.search_deeplink_android_url ?? "",
          deeplink_image_url: response.data.search_deeplink_image_url ?? "",
        };
        dispatch({
          type: FETCH_SEARCH_DEEPLINKS,
          payload: data
        });
      })
      .catch(error => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function saveSearchDeeplinksInfo(
  deeplink_image_url,
  deeplink_ios_url,
  deeplink_android_url
) {
  return async function (dispatch) {
    try {
      await axios.post(
        `${API_URL}/update-search-deeplinks-info.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          deeplink_image_url: deeplink_image_url,
          deeplink_ios_url: deeplink_ios_url,
          deeplink_android_url: deeplink_android_url,
          user_token: SessionHelper.getToken()
        })
      );
    } catch (error) {
      return errorHandler(dispatch, error.response, API_ERROR);
    }
    swal({
      type: "success",
      title: "Deeplinks Saved!",
      timer: 1000
    });
  };
}
