import React, { Component } from "react";
import MainContentContainer from "../MainContentContainer/MainContentContainer";
import SuggestedSearches from "./SuggestedSearches";
import TopSearch from "./TopSearch";
import SearchDeeplinks from "./SearchDeeplinks";

class SearchPage extends Component {
  render() {
    return (
      <div className="utility-page-layout">
        <MainContentContainer subContent={true}>
          <div className="utility-flex-data">
            <TopSearch />
            <SearchDeeplinks />
          </div>
          <div className="utility-flex-row">
            <SuggestedSearches />
          </div>
        </MainContentContainer>
      </div>
    );
  }
}

export default SearchPage;
