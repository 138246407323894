import React from "react";
import Select from "react-select";
import { SortableElement, SortableHandle } from "react-sortable-hoc";
import { MdReorder, MdDelete } from 'react-icons/md';

const DragHandle = SortableHandle(() => <div className="sort-handle" title='Drag to reorder'><MdReorder/></div>);

const SuggestedSearch = SortableElement(
  ({
    data,
    data_options,
    onDelete,
    index,
    el_index,
    handleChange,
    handleDelete,
  }) => {
    const renderObjectOptions = () => {
      let objects = ["AUTHOR", "SESSION", "SERIES", "AUDIOBOOK", "CHALLENGE"];
      return objects.map((el) => {
        return { label: el, value: el, prop: "object_type" };
      });
    };

    const renderSearchOptions = () => {
      let objects = ["REDIRECT", "SEARCH"];
      return objects.map((el) => {
        return { label: el, value: el, prop: "search_behavior" };
      });
    };

    const renderDataOption = () => {
      switch (data.object_type) {
        case "AUTHOR":
          return data_options.authors.map((el) => {
            return {
              label: el.author_name,
              value: el.author_id,
              prop: "object_id",
            };
          });
        case "SESSION":
          return data_options.sessions.map((el) => {
            return {
              label: el.session_name,
              value: el.session_id,
              prop: "object_id",
            };
          });
        case "SERIES":
          return data_options.series.map((el) => {
            return {
              label: el.series_title,
              value: el.series_id,
              prop: "object_id",
            };
          });
        case "AUDIOBOOK":
          return data_options.audiobook.map((el) => {
            return {
              label: el.subcategory_title,
              value: el.subcategory_id,
              prop: "object_id",
            };
          });
        case "CHALLENGE":
          return data_options.challenges.map((el) => {
            return {
              label: el.challenge_title,
              value: el.challenge_id,
              prop: "object_id",
            };
          });
      }
    };

    return (
      <div
        className="utility-flex-row"
        style={{ alignItems: "center", padding: "10px 0px" }}
      >
        <DragHandle />
        <Select
          className="utility-inline-label"
          value={data.object_type}
          style={{ marginLeft: "14px", width: "259px" }}
          clearable={false}
          searchable={true}
          placeholder="Object Type"
          options={renderObjectOptions()}
          onChange={(e) => handleChange(e, el_index)}
        />
        <Select
          className="utility-inline-label"
          value={data.object_id}
          style={{ width: "259px" }}
          clearable={false}
          searchable={true}
          placeholder="Object Name/Title"
          options={renderDataOption()}
          onChange={(e) => handleChange(e, el_index)}
        />
        <Select
          className="utility-inline-label"
          value={data.search_behavior}
          style={{ width: "259px" }}
          clearable={false}
          searchable={true}
          options={renderSearchOptions()}
          placeholder="Search Type"
          onChange={(e) => handleChange(e, el_index)}
        />
        <button
          className="utility-btn-transparent"
          style={{ color: "#d0021b", fontSize: "20px" }}
          onClick={(e) => handleDelete(e, data.id, el_index)}
        >
          <MdDelete />
        </button>
      </div>
    );
  }
);
export default SuggestedSearch;
