import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchRequests } from "../../actions/requestsActions";
import ReactPaginate from 'react-paginate';


class RequestsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requests: {
        data: []
      },
      pageSize: 500,
      currentPage: 0
    };
  }

  componentDidMount() {
    this.props.fetchRequests(0, this.state.pageSize);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.requests != prevState.requests) {
      return { requests: nextProps.requests };
    }
  }


  renderRequests = (requests) => {
    if (this.props.requests.data.length == 0) return <tr><td> <h1>Loading...</h1></td></tr >;

    const currentData = requests.data.map((d, index) => {
      let dt = new Date(d.timestamp * 1000);
      let formatted_timestamp = dt.toLocaleString();
      return (
        <tr key={d.id}>
          <td>{d.reference_number}</td>
          <td className="max-content">{d.request_message}</td>
          <td>{d.user_email}</td>
          <td>{d.user_full_name}</td>
          <td>{formatted_timestamp}</td>
        </tr>
      )
    });
    return currentData;
  };

  handlePageClick = (selectedObject) => {
    // Update Requests page data
    this.props.fetchRequests(selectedObject.selected, this.state.pageSize);
  };

  render() {
    const { requests } = this.state;
    return (
      <>
        <div>
          <table>
            <thead>
              <tr>
                <th>Request Ref. #</th>
                <th className="max-content">Message</th>
                <th>Email</th>
                <th>User Name</th>
                <th>Date & Time</th>
              </tr>
            </thead>
            <tbody>
              {this.renderRequests(requests)}
            </tbody>
          </table>
          <ReactPaginate
            previousLabel={'previous'}
            nextLabel={'next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={requests.total_count / this.state.pageSize}
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={this.handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            pageClassName={'page'}
            previousClassName={'previous'}
            nextClassName={'next'}
            disabledClassName={'disabled'}
          />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    requests: state.requests.requests,
  };
}

const mapDispatchToProps = {
  fetchRequests,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestsTable);
