import axios from "axios";
import { API_URL, errorHandler, queryString } from "./index";
import { API_ERROR } from "../constants/ActionTypes";
import swal from "sweetalert2";

import SessionHelper from "../components/Helper/SessionHelper";

function downloadFile(blob, fileName) {
  var newBlob = new Blob([blob], {
    type: "text/csv",
  });

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }

  const data = window.URL.createObjectURL(newBlob);
  var link = document.createElement("a");
  link.href = data;
  link.download = fileName + ".csv";
  link.click();
  swal({
    title: "Report downloaded!",
    type: "success",
    html: `
        <div style="text-align: center;">
          <p>If file did not automatically download please click the download link below.</p>
          <div style="padding: 10px">
            <a
              href="${data}"
              class="LargeButton"
              style="color: #000; padding: 10px 15px;"
              download="${fileName}.csv"
            >
              Download
            </a>
          </div>
        </div>
      `,
  }).then(() => {
    window.URL.revokeObjectURL(data);
  });
}

export function exportFeedbacksDataCSV(options) {
  var d = new Date();
  var dateString = d.toLocaleString();

  return function (dispatch) {
    swal({
      title: "Loading...",
      animation: false,
      allowOutsideClick: true,
      allowEscapeKey: true,
      showConfirmButton: false,
    });

    axios
      .post(
        `${API_URL}/feedbacks-data-csv.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken(),
        })
      )
      .then((response) => {
        let fileName = "Session-Feedbacks-Report-" + dateString;
        downloadFile(response.data, fileName);
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function exportIntensityDataCSV({ from_date, to_date }) {
  var d = new Date();
  var dateString = d.toLocaleString();

  console.log("user_id: ", SessionHelper.currentUser().admin_id);

  return function (dispatch) {
    swal({
      title: "Loading...",
      animation: false,
      allowOutsideClick: true,
      allowEscapeKey: true,
      showConfirmButton: false,
    });

    axios
      .post(
        `${API_URL}/get-intensity-report.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken(),
          from_date,
          to_date,
        })
      )
      .then((response) => {
        let fileName = "Session-Intensity-Report-" + dateString;
        downloadFile(response.data, fileName);
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function exportRequestsDataCSV() {
  var d = new Date();
  var dateString = d.toLocaleString();

  return function (dispatch) {
    swal({
      title: "Loading...",
      animation: false,
      allowOutsideClick: true,
      allowEscapeKey: true,
      showConfirmButton: false,
    });

    axios
      .post(
        `${API_URL}/requests-data-csv.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken(),
        })
      )
      .then((response) => {
        let fileName = "Requested-Topics-Report-" + dateString;
        downloadFile(response.data, fileName);
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function exportCancellationDataCSV() {
  var d = new Date();
  var dateString = d.toLocaleString();

  return function (dispatch) {
    swal({
      title: "Loading...",
      animation: false,
      allowOutsideClick: true,
      allowEscapeKey: true,
      showConfirmButton: false,
    });

    axios
      .post(
        `${API_URL}/export-cancellation-data-csv.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().user_id,
          user_token: SessionHelper.getToken(),
        })
      )
      .then((response) => {
        let fileName = "Latest-User-Cancellation-Report-" + dateString;
        downloadFile(response.data, fileName);
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}
