import React, { Component } from 'react';
import { connect } from 'react-redux';
// import SubNav from './../../Nav/SubNav/SubNav';
import HeaderBar from '../../HeaderBar/HeaderBar';
import MainContentContainer from '../../MainContentContainer/MainContentContainer';
import { exportFeedbacksDataCSV } from './../../../actions/exportCSVActions';
import FeedbacksTable from '../../FeedbacksTable/FeedbacksTable';

class FeedbacksPage extends Component {

  submitExport = (e) => {
    e.preventDefault();
    this.props.exportFeedbacksDataCSV();
  };
  
  render() {
    return (
      <div className="utility-page-layout">
        <HeaderBar header="Session Feedbacks">
          <button onClick={this.submitExport.bind(this)} className="Utility-btn-primary">Export CSV</button>
        </HeaderBar>
        <MainContentContainer>
          <FeedbacksTable />
        </MainContentContainer>
      </div>
    );
  }

}

const mapDispatchToProps = {
  exportFeedbacksDataCSV
}

export default connect(null, mapDispatchToProps)(FeedbacksPage);
