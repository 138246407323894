import React, { Component } from 'react';
import AddEditSession from './../../AddEditSession/AddEditSession';

class SessionsAddEditPage extends Component {

  render() {
    
    const isEdit = this.props.location.pathname.indexOf('edit') > -1;

    return (
      <div className="utility-page-layout">
        <AddEditSession
          isEdit={isEdit}
          selectedId={this.props.match.params.id}
        />
      </div>
    );
  }

}

export default SessionsAddEditPage;
