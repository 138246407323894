import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchAllSeries, clearAllSeries } from "./../../actions/seriesActions";
import { Link } from "react-router-dom";
import HeaderBar from "./../HeaderBar/HeaderBar";
import MainContentContainer from "./../MainContentContainer/MainContentContainer";

class SeriesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
    };
    this.colWidth1 = "536px";
    this.colWidth2 = "400px";
    this.colWidth3 = "220px";
  }
  componentDidMount() {
    this.props.clearAllSeries();
    this.props.fetchAllSeries();
  }

  handleSearchChange = (e) => {
    const { value } = e.target;
    this.setState({ searchText: value });
  };

  renderSeries = () => {
    if (!this.props.series) return null;

    let data = [...this.props.series];
    if (this.state.searchText !== "") {
      const search = this.state.searchText.toLowerCase();
      data = data.filter(
        (obj) => obj.series_title.toLowerCase().indexOf(search) > -1
      );
    }

    return data.map((obj) => {
      return (
        <div
          className="utility-table-row"
          key={obj.series_id + obj.series_title}
        >
          <p style={{ width: this.colWidth1 }}>{obj.series_title}</p>
          <p style={{ width: this.colWidth2 }}>
            {obj.category_title || "none"}
          </p>

          <div
            style={{ width: this.colWidth3 }}
            className="utility-table-end-col"
          >
            <p>
              {obj.series_is_active === 0 ? (
                "Not Active"
              ) : (
                <span className="utility-active">Active</span>
              )}
            </p>
            <div style={{ margin: "16px 0 16px auto" }}>
              <Link
                to={"/series/edit/" + obj.series_id}
                className="utility-edit-link"
              >
                Edit
              </Link>
            </div>
          </div>
        </div>
      );
    });
  };

  render() {
    let categoryName = "Category";
    return (
      <div>
        <HeaderBar header={categoryName}>
          <Link className="utility-btn-primary" to={"/series/add"}>
            Create New Series
          </Link>
        </HeaderBar>

        <MainContentContainer>
          <div className="SeriesTable">
            <div
              className="utility-flex-row"
              style={{ alignItems: "center", justifyContent: "center" }}
            >
              <p>
                <b>Search Series: </b>
              </p>
              <input
                type="text"
                value={this.state.searchText}
                className="utility-text-input"
                onChange={this.handleSearchChange}
                style={{ marginLeft: "20px" }}
              />
            </div>

            {this.state.searchText !== "" && (
              <h3>Showing series matching search: {this.state.searchText}</h3>
            )}

            <div className="utility-table-row utility-table-row-headers">
              <h2 style={{ width: this.colWidth1 }}>Title</h2>
              <h2 style={{ width: this.colWidth2 }}>In Category</h2>
              <h2 style={{ width: this.colWidth3 }}>Is Active</h2>
            </div>
            {this.renderSeries()}
          </div>
        </MainContentContainer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    series: state.series.all_series,
    all_categories: state.categories.all_categories,
  };
}

const mapDispatchToProps = {
  fetchAllSeries,
  clearAllSeries,
};

export default connect(mapStateToProps, mapDispatchToProps)(SeriesTable);
