import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchAllChallenges,
  fetchChallengeIntro,
  updateChallengeIntro,
  sortChallenges,
} from "../../actions/challengeActions";
import { Link } from "react-router-dom";
import HeaderBar from "../HeaderBar/HeaderBar";
import MainContentContainer from "../MainContentContainer/MainContentContainer";
import { Button } from "react-bootstrap";
import { arrayMoveImmutable } from "array-move";

import SortableChallenges from "./SortableChallenge";

class ChallengeTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      challenge_intro_text: "",
      challenges: [],
    };
    this.colWidth1 = "536px";
    this.colWidth2 = "400px";
    this.colWidth3 = "220px";
  }

  componentDidMount() {
    this.props.fetchChallengeIntro();
    this.props.fetchAllChallenges();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.challenge_intro_text != nextProps.challenge_intro_text) {
      return { challenge_intro_text: nextProps.challenge_intro_text };
    }

    if (nextProps.challenges) {
      if (prevState.challenges.length !== nextProps.challenges.length) {
        const challenges = nextProps.challenges.sort(
          (a, b) =>
            Number(a.challenge_sort_order) - Number(b.challenge_sort_order)
        );

        return { challenges: challenges };
      }
    }
    return null;
  }

  handleSearchChange = (e) => {
    const { value } = e.target;
    this.setState({ searchText: value });
  };

  handleChallengeIntroChange = (e) => {
    const { value } = e.target;
    this.setState({ challenge_intro_text: value });
  };

  saveChallengeIntroText = (e) => {
    this.props.updateChallengeIntro(this.state.challenge_intro_text);
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(
      {
        challenges: arrayMoveImmutable(this.state.challenges, oldIndex, newIndex),
      },
      () => {
        const sort_json = this.state.challenges.map((obj) => obj.challenge_id);
        this.props.sortChallenges(JSON.stringify(sort_json));
      }
    );
  };

  renderChallenges = () => {
    if (!this.state.challenges) return null;

    const dragKey = this.state.challenges.reduce(
      (accum, val) => (accum += val.challenge_id),
      "sort-key"
    );

    return (
      <SortableChallenges
        items={this.state.challenges}
        onSortEnd={this.onSortEnd}
        key={dragKey}
      />
    );
  };

  render() {
    console.log("state", this.state);
    let categoryName = "Challenges";

    return (
      <div>
        <HeaderBar header={categoryName}>
          <Link className="utility-btn-primary" to={"/challenges/add"}>
            Create New Challenge
          </Link>
        </HeaderBar>

        <MainContentContainer>
          <div
            className="utility-flex-row"
            style={{ alignItems: "left", justifyContent: "left" }}
          >
            <input
              type="text"
              value={this.state.challenge_intro_text}
              className="utility-text-input"
              onChange={this.handleChallengeIntroChange}
              style={{ marginLeft: "20px", width: "80%" }}
            />

            <Button
              className="utility-btn-primary"
              onClick={this.saveChallengeIntroText}
            >
              Save Intro Text
            </Button>
          </div>

          <div className="AudioBookTable" style={{ marginTop: "25px" }}>
            {this.state.searchText !== "" && (
              <h3>
                Showing challenge matching search: {this.state.searchText}
              </h3>
            )}

            <div className="utility-table-row utility-table-row-headers">
              <h2 style={{ width: "100px" }}></h2>
              <h2 style={{ width: this.colWidth1 }}>Title</h2>
              <h2 style={{ width: this.colWidth3 }}>Total Joins</h2>
              <h2 style={{ width: this.colWidth3 }}>Total Sessions</h2>
              <h2 style={{ width: this.colWidth3 }}>Is Active</h2>
            </div>
            {this.renderChallenges()}
          </div>
        </MainContentContainer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    challenges: state.challenges.challenges
      ? Object.values(state.challenges.challenges)
      : null,
    challenge_intro_text: state.general_data.general_data
      ? state.general_data.general_data.challenge_intro_text
      : "",
  };
}

const mapDispatchToProps = {
  fetchAllChallenges,
  fetchChallengeIntro,
  updateChallengeIntro,
  sortChallenges,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeTable);
