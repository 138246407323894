import { Paper, AppBar, Box, Button, Container, Toolbar, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import LoadingBackdrop from '../Common/LoadingBackdrop'
import { Cancel, Save } from '@mui/icons-material';
import CardFormFields from './CardFormFields';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { closeEditCard, saveCard, fetchOneCard } from '../../../../actions/cardsActions';
import swal from "sweetalert2";

export default function AddEditCard() {
    const { deckId, id } = useParams();
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.cards.loading);
    const cardStore = useSelector(state => state.cards.card);
    const [isModified, setModified] = useState(false);

    const [card, setCard] = useState({
        back_image_url: '',
        front_image_url: '',
        is_active: 0,
        linked_content_button_icon_url: '',
        linked_content_button_text: '',
        share_deeplink_url: '',
        share_image_url: '',
        share_link_description: '',
        subtitle: '',
        target_android_deeplink_url: '',
        target_deeplink_image_url: '',
        target_ios_deeplink_url: '',
        target_web_deeplink_url: '',
        title: '',
    });

    useEffect(() => {
        if (id) {
            dispatch(fetchOneCard(id));
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (cardStore) {
            setModified(false);
            setCard(cardStore);
        }
    }, [cardStore]);

    const handleChange = e => {
        setModified(true);
        setCard({ ...card, [e.target.name]: e.target.value });
    }

    const handleSave = () => {
        const cardToSave = {
            ...card,
            deck_id: deckId
        }
        setModified(false);
        dispatch(saveCard(cardToSave));
    }

    const handleCancel = () => {
        if (isModified) {
            swal({
                type: "warning",
                text: "You have unsaved changes. Are you sure you want to leave?",
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                confirmButtonText: "Save & Close",
                cancelButtonText: "Close Without Saving",
            }).then((result) => {
                if (result.isConfirmed) {
                    handleSave();
                } else {
                    dispatch(closeEditCard(deckId));
                }
            });
        } else {
            dispatch(closeEditCard(deckId));
        }
    }

    return (
        <Container sx={{ paddingBottom: 4 }}>
            <LoadingBackdrop open={isLoading} />
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        {id && card ? `Edit Card: ${card.title}` : 'Add New Card'}
                    </Typography>
                    <Button color="success" onClick={handleSave} startIcon={<Save />} variant="contained" sx={{ marginRight: 2 }}>
                        Save
                    </Button>
                    <Button color="error" startIcon={<Cancel />} variant="contained" onClick={handleCancel}>
                        Cancel
                    </Button>
                </Toolbar>
            </AppBar>
            <Box gap={4}>
                <Paper sx={{ padding: 2 }}>
                    <CardFormFields card={card} handleChange={handleChange} />
                </Paper>
            </Box>
        </Container>
    )
}
