import axios from "axios";
import SessionHelper from "../components/Helper/SessionHelper";
import {
  API_ERROR,
  DELETE_SUGGESTED_SEARCH,
  FETCH_SUGGESTED_SEARCH,
  FETCH_SUGGESTED_SEARCH_OPTIONS,
} from "../constants/ActionTypes";
import { API_URL, errorHandler, queryString } from "./index";
import swal from "sweetalert2";

//= ===============================
// Client actions
//= ===============================

export function fetchAvailableOption() {
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/fetch-suggested-search-options.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken(),
        })
      )
      .then((response) => {
        dispatch({
          type: FETCH_SUGGESTED_SEARCH_OPTIONS,
          payload: response.data.objects,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function fetchSuggestedSearches() {
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/fetch-suggested-searches.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken(),
        })
      )
      .then((response) => {
        dispatch({
          type: FETCH_SUGGESTED_SEARCH,
          payload: response.data.objects,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function deleteSuggesteedSearch(suggested_search_id) {
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/delete-one-suggested-search.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken(),
          suggested_search_id: suggested_search_id,
        })
      )
      .then((response) => {
        dispatch({
          type: DELETE_SUGGESTED_SEARCH,
          payload: suggested_search_id,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function addOrEditSuggestedSearch(suggested_search_data) {
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/add-n-edit-suggested-searches.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken(),
          suggested_search_data: suggested_search_data,
        })
      )
      .then((response) => {
        swal({
          type: "success",
          title: "Suggested Search Updated!",
          timer: 1000,
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}
