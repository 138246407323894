import axios from "axios";
import { push } from "react-router-redux";
import { API_URL, errorHandler, queryString } from "./index";
import {
  API_ERROR,
  FETCH_ALL_PROMO_DATA,
  FETCH_ALL_GENERAL_DATA
} from "../constants/ActionTypes";
import swal from "sweetalert2";

import SessionHelper from "../components/Helper/SessionHelper";

//= ===============================
// Client actions
//= ===============================

export function fetchPromoData() {
  return function(dispatch) {
    axios
      .post(
        `${API_URL}/fetch-all-promo-data.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken()
        })
      )
      .then(response => {
        console.log(response);
        dispatch({
          type: FETCH_ALL_PROMO_DATA,
          payload: response.data
        });
      })
      .catch(error => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function updatePromoData(promo_data_json) {
  return function(dispatch) {
    axios
      .post(
        `${API_URL}/update-promo-data.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          promo_data_json: promo_data_json,
          user_token: SessionHelper.getToken()
        })
      )
      .then(response => {
        swal({
          type: "success",
          title: "Saved!",
          timer: 1000
        }).then(() => {
          dispatch(push("/promo"));
        });
      })
      .catch(error => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function updateOnboardingPromoData(promo_data_json) {
  return function(dispatch) {
    axios
      .post(
        `${API_URL}/update-promo-data.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          promo_data_json: promo_data_json,
          user_token: SessionHelper.getToken()
        })
      )
      .then(response => {
        swal({
          type: "success",
          title: "Saved!",
          timer: 1000
        }).then(() => {
          dispatch(push("/onboarding"));
        });
      })
      .catch(error => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function fetchGeneralInfo() {
  return function(dispatch) {
    axios
      .post(
        `${API_URL}/fetch-general-info.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken()
        })
      )
      .then(response => {
        console.log("response: ", response);
        dispatch({
          type: FETCH_ALL_GENERAL_DATA,
          payload: response.data
        });
      })
      .catch(error => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}
// TODO: change dispatch to general

export function updateGeneralInfo({
  default_text_image_url,
  default_text_page_url,
  default_text_page_ios_url
}) {
  console.log("default_text_image_url: ", default_text_image_url);
  console.log("default_text_page_url: ", default_text_page_url);
  return async function(dispatch) {
    try {
      // await axios.post(
      //   `${API_URL}/update-general-info.php`,
      //   queryString.stringify({
      //     requesting_user_id: SessionHelper.currentUser().admin_id,
      //     general_info_key: "default_text_image_url",
      //     general_info_value: default_text_image_url,
      //     user_token: SessionHelper.getToken()
      //   })
      // );
      await axios.post(
        `${API_URL}/update-general-info.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          general_info_key: "default_text_page_url",
          general_info_value: default_text_page_url,
          user_token: SessionHelper.getToken()
        })
      );
      await axios.post(
        `${API_URL}/update-general-info.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          general_info_key: "default_text_page_ios_url",
          general_info_value: default_text_page_ios_url,
          user_token: SessionHelper.getToken()
        })
      );
    } catch (error) {
      return errorHandler(dispatch, error.response, API_ERROR);
    }
    swal({
      type: "success",
      title: "Saved!",
      timer: 1000
    }).then(() => {
      dispatch(push("/promo"));
    });
  };
}
