import React, { Component } from 'react';
import SubCategoriesTable from './../../SubCategoriesTable/SubCategoriesTable';

class SubCategoryPage extends Component {

  render() {
    return (
      <div className="utility-page-layout">
        <SubCategoriesTable selectedId={this.props.match.params.id} />
      </div>
    );
  }

}

export default SubCategoryPage;
