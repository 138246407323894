import React, { Component } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { Link } from 'react-router-dom';
import { MdReorder } from "react-icons/md";
import { MdModeEdit } from 'react-icons/md';

const DragHandle = SortableHandle(() => <div className="utility-table-column sort-handle" title='Drag to reorder'><MdReorder/></div>);

const SortableItem = SortableElement(({category, itemDisabled, itemIndex, highlightIndex}) => {
  let className="utility-table-row";
  if(highlightIndex != null && highlightIndex === itemIndex){
    className+=" row-successful-highlight"
  }else{
    className+=" row-normal-fade-out"
  }
  return (
    <div className={className} key={category.category_id}>
        {itemDisabled ? (
          <div className="utility-table-column">{category.category_sort_order}</div>
        ) : (
          <DragHandle />
        )}
      <div className="utility-table-column utility-table-column-expanded">{category.category_title}</div>
      <div
          className={category.category_is_active === 1 ? 'utility-table-column utility-active' : 'utility-table-column'}>{category.category_is_active === 1 ? 'Active' : 'Not Active'}</div>
      <div className="utility-table-column">{category.category_num_subs}</div>
      <div className="utility-table-column">{category.category_num_sessions}</div>
      <div className="utility-table-column">{category.category_is_quick_tap === 1 ? 'Yes' : 'No'}</div>
      <div className="utility-table-column">
          <Link to={'/category/edit/'+category.category_id} className="utility-edit-link"><MdModeEdit/></Link>
      </div>
    </div>
  )
});

const SortableList = SortableContainer(({items, disabled, highlightIndex}) => {
  return (
    <div>
      {items.map((value, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          itemIndex={index}
          category={value}
          highlightIndex={highlightIndex}
          disabled={disabled}
          itemDisabled={disabled}
        />
      ))}
    </div>
  );
});

class SortableCategories extends Component {
  render() {
    return (
      <SortableList
        items={this.props.items}
        highlightIndex={this.props.highlightIndex}
        onSortEnd={this.props.onSortEnd}
        useDragHandle={true}
        helperClass="utility-flex-row-dragging"
        disabled={this.props.isSearching}
      />
    )
  }
}

export default SortableCategories
