import React, { Component } from "react";
import { connect } from "react-redux";
// import SubNav from './../../Nav/SubNav/SubNav';
import HeaderBar from "../../HeaderBar/HeaderBar";
import MainContentContainer from "../../MainContentContainer/MainContentContainer";
import { exportIntensityDataCSV } from "../../../actions/exportCSVActions";
import FeedbacksTable from "../../FeedbacksTable/FeedbacksTable";
// import { DateRange } from "react-date-range";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-dates";
import moment from "moment";

class IntensityReportPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      calendarFocused: null,
      from_date: moment().startOf("month"),
      to_date: moment().endOf("moment")
    };
  }
  submitExport = e => {
    e.preventDefault();
    this.props.exportIntensityDataCSV({
      from_date: this.state.from_date.format("YYYY-MM-DD"),
      to_date: this.state.to_date.format("YYYY-MM-DD")
    });
  };
  onFocusChange = calendarFocused => this.setState({ calendarFocused });
  onDatesChange = ({ startDate, endDate }) =>
    this.setState({ from_date: startDate, to_date: endDate });

  render() {
    return (
      <div className="utility-page-layout">
        <HeaderBar header="Intensity Report">
          <button
            onClick={this.submitExport.bind(this)}
            className="Utility-btn-primary"
          >
            Export CSV
          </button>
        </HeaderBar>
        <MainContentContainer>
          <DateRangePicker
            startDate={this.state.from_date}
            startDateId="startdate"
            endDate={this.state.to_date}
            endDateId="enddate"
            onDatesChange={this.onDatesChange}
            focusedInput={this.state.calendarFocused}
            onFocusChange={this.onFocusChange}
            showClearDates={true}
            numberOfMonths={1}
            isOutsideRange={() => false}
          />
        </MainContentContainer>
      </div>
    );
  }
}

const mapDispatchToProps = {
  exportIntensityDataCSV
};

export default connect(
  null,
  mapDispatchToProps
)(IntensityReportPage);
