import React, { Component } from "react";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { Link } from "react-router-dom";
import { MdReorder } from "react-icons/md";

const DragHandle = SortableHandle(() => <div style={{ display:"block"}} className="sort-handle" title='Drag to reorder'><MdReorder/></div>);

const SortableItem = SortableElement(({ value, widths }) => {
  return (
    <tr
      className="utility-table-row"
      key={"banner-" + value.promo_banner_id}
    >
      <td
        style={{ width: widths.col0, padding: "10px"}}
      >
        <DragHandle />
      </td>
      <td
        style={{ width: widths.col1, padding: "10px" }}
      >
        {value.promo_banner_url && <img src={value.promo_banner_url} alt="" style={{ width: "100%" }} />}
      </td>
      <td
        style={{ width: widths.col2, padding: "10px" }}
      >
        {value.promo_banner_title}
      </td>
      <td
        style={{ width: widths.col3, padding: "10px" }}
      >
        {value.promo_banner_text}
      </td>
      <td
        style={{ width: widths.col4, padding: "10px", background: "#888", textAlign: "center" }}
      >
        {value.promo_banner_icon && <img src={value.promo_banner_icon} alt="" style={{ width: "30%" }} />}
      </td>
      <td
        style={{ width: widths.col5, padding: "10px" }}
        className="utility-table-end-col"
      >
        <p>{value.promo_banner_is_active === 0 ? "Not Active" : "Active"}</p>
        <div style={{ margin: "16px 0 16px auto" }}>
          <Link
            to={"/banners/edit/" + value.promo_banner_id}
            className="utility-edit-link"
          >
            Edit
          </Link>
        </div>
      </td> 
    </tr>
  );
});

const SortableList = SortableContainer(({ items, widths }) => {
  return (
    <tbody>
      {items.map((value, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          value={value}
          widths={widths}
        />
      ))}
    </tbody>
  );
});

class SortablePromoBanners extends Component {
  render() {
    return (
      <SortableList
        items={this.props.items}
        widths={this.props.widths}
        onSortEnd={this.props.onSortEnd}
        distance={2}
        useWindowAsScrollContainer={true}
        helperClass="utility-flex-row-dragging"
      />
    );
  }
}

export default SortablePromoBanners;
