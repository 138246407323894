import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchFeedbacks } from "../../actions/feedbacksActions";
import ReactPaginate from 'react-paginate';
import { MdVideocam, MdPlayArrow } from 'react-icons/md'

class FeedbackTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feedbacks: {
        data: []
      },
      pageSize: 500,
      currentPage: 0
    };
  }

  componentDidMount() {
    this.props.fetchFeedbacks(0, this.state.pageSize);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.feedbacks != prevState.feedbacks) {
      return { feedbacks: nextProps.feedbacks };
    }
  }

  renderFeedbacks = (feedbacks) => {
    if (this.props.feedbacks.data.length == 0) return <tr><td> <h1>Loading...</h1></td></tr >;

    const currentData = feedbacks.data.map((d, index) => {
      let dt = new Date(d.timestamp * 1000);
      let formatted_timestamp = dt.toLocaleString();
      return (
        <tr key={d.id}>
          <td>{d.reference_number}</td>
          <td className="max-content">{d.feedback_message}</td>
          <td>{d.user_email}</td>
          <td>{d.user_full_name}</td>
          <td><span className="sessionIcon">{d.is_quick_tap && (<MdVideocam />) || !d.is_quick_tap && (<MdPlayArrow />)}</span>
            {d.session_name}</td>
          <td>{formatted_timestamp}</td>
        </tr>
      )
    });
    return currentData;
  };

  handlePageClick = (selectedObject) => {
    // Update feedbacks page data
    this.props.fetchFeedbacks(selectedObject.selected, this.state.pageSize);
  };

  render() {
    const { feedbacks } = this.state;

    return (
      <>
        <div>
          <table>
            <thead>
              <tr>
                <th>Feedback Ref. #</th>
                <th className="max-content">Message</th>
                <th>Email</th>
                <th>User Name</th>
                <th>Session Name</th>
                <th>Date & Time</th>
              </tr>
            </thead>
            <tbody>
              {this.renderFeedbacks(feedbacks)}
            </tbody>
          </table>
          <ReactPaginate
            previousLabel={'previous'}
            nextLabel={'next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={feedbacks.total_count / this.state.pageSize}
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={this.handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            pageClassName={'page'}
            previousClassName={'previous'}
            nextClassName={'next'}
            disabledClassName={'disabled'}
          />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    feedbacks: state.feedbacks.feedbacks,
  };
}

const mapDispatchToProps = {
  fetchFeedbacks,
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackTable);
