import React, { Component } from "react";
import { connect } from "react-redux";

import {
  fetchUserAttributions,
  addOrEditUserAttribution,
  deleteUserAttribution,
  fetchAttributionPercentage,
  updateAttributionPercentage
} from "../../actions/userAttributionActions";
import MainContentContainer from "../MainContentContainer/MainContentContainer";

class UserAttributionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        ios: [],
        android: [],
        percentage: "",
      },
      updated: false,
    };
  }

  componentDidMount() {
    this.props.fetchUserAttributions();
    this.props.fetchAttributionPercentage();
  }

  UNSAFE_componentWillReceiveProps({user_attributions}) {
    if (user_attributions && this.state.updated === false) {
      const ios = (user_attributions.ios != null && user_attributions.ios.length > 0)
        ? user_attributions.ios
        : this.state.data.ios;
      const android = (user_attributions.android != null && user_attributions.android.length > 0) 
        ? user_attributions.android 
        : this.state.data.android;
      const percentage = (user_attributions.percentage != null && user_attributions.percentage.length > 0) 
        ? user_attributions.percentage 
        : this.state.data.percentage;

      this.setState({
        data: {
          ios,
          android,
          percentage
        },
        updated: false
      });

    }
  }

  addNewRow = (type) => {
    let os = 2;
    let items = this.state.data.android;

    if (type === "ios") {
      os = 1;
      items = this.state.data.ios;
    }

    const data = {
      value: "",
      os,
    };

    items.push(data);

    let item = this.setState({ data: { ...this.state.data, [type]: items } });
  };

  handleChange = (e, index, type) => {
    const value = e.target.value;

    let items = this.state.data.android;
    if (type === "ios") {
      items = this.state.data.ios;
    }
    let item = items[index];
    item.value = value;
    items[index] = item;

    this.setState({ data: { ...this.state.data, [type]: items } });
  };

  handlePercentageChange = (e) => {
    const value = e.target.value;
    this.setState({data: {...this.state.data, percentage: value}});
  };

  handlePercentageUpdate = (e) => {
    const percentage = this.state.data.percentage;
    this.props.updateAttributionPercentage(percentage)
  };

  handleDelete = (e, id, index, type) => {
    e.preventDefault();
    if (id) {
      this.props.deleteUserAttribution(id);
    }

    this.state.data[type].splice(index, 1);
    this.setState({ data: { ...this.state.data } });
  };

  handleUpdate = (e, index, type) => {
    e.preventDefault();
    let items = this.state.data.android;
    if (type === "ios") {
      items = this.state.data.ios;
    }
    let item = items[index];
    console.log(item);
    this.props.addOrEditUserAttribution(item);
  };

  renderiOS() {
    if (this.state.data.ios.length) {
      return this.state.data.ios.map((el, index) => {
        return (
          <div key={index}>
            <input
              type="text"
              className="utility-inline-label utility-text-input"
              name={el.id}
              value={el.value}
              onChange={(e) => this.handleChange(e, index, "ios")}
              placeholder="iOS Attribution"
              style={{ width: "200px", marginLeft: "5px" }}
              required
            />
            <button
              className="utility-btn-transparent"
              style={{ color: "#0076ff", marginLeft: "10px" }}
              onClick={(e) => this.handleUpdate(e, index, "ios")}
            >
              Update
            </button>

            <button
              className="utility-btn-transparent"
              style={{ color: "#d0021b", marginLeft: "10px" }}
              onClick={(e) => this.handleDelete(e, el.id, index, "ios")}
            >
              Delete
            </button>
          </div>
        );
      });
    }
  }

  renderUserPercentage() {
    return (
      <div className="utility-row-view">
        <input
          type="text"
          className="utility-inline-label utility-text-input"
          value={this.state.data.percentage}
          name="userAttributionPercentage"
          onChange={(e) => this.handlePercentageChange(e)}
          placeholder="100"
          style={{ width: "200px", marginLeft: "5px" }}
        />
        <button
          className="utility-btn-transparent"
          style={{ color: "#0076ff", marginLeft: "10px" }}
          type="button"
          onClick={(e) => this.handlePercentageUpdate()}
        >
          Update Percentage
        </button>
      </div>
    );
  }

  renderAndroid() {
    if (this.state.data.android.length) {
      return this.state.data.android.map((el, index) => {
        return (
          <div key={index}>
            <input
              type="text"
              className="utility-inline-label utility-text-input"
              name={el.id}
              value={el.value}
              onChange={(e) => this.handleChange(e, index, "android")}
              placeholder="Android Attribution"
              style={{ width: "200px", marginLeft: "5px" }}
              required
            />

            <button
              className="utility-btn-transparent"
              style={{ color: "#0076ff", marginLeft: "10px" }}
              onClick={(e) => this.handleUpdate(e, index, "android")}
            >
              Update
            </button>

            <button
              className="utility-btn-transparent"
              style={{ color: "#d0021b", marginLeft: "10px" }}
              onClick={(e) => this.handleDelete(e, el.id, index, "android")}
            >
              Delete
            </button>
          </div>
        );
      });
    }
  }

  render() {
    return (
      <div>
        <MainContentContainer>
          <div className="utility-flex-data">
            <div>
              <h3>Android</h3>
              <div className="utility-flex-end">
                <button
                  className="utility-btn-transparent"
                  onClick={() => this.addNewRow("android")}
                >
                  Add New
                </button>
              </div>

              {this.renderAndroid()}
            </div>
            <div>
              <h3>iOS</h3>
              <div className="utility-flex-end">
                <button
                  className="utility-btn-transparent"
                  onClick={() => this.addNewRow("ios")}
                >
                  Add New
                </button>
              </div>

              {this.renderiOS()}
            </div>
          </div>

          <div className="utility-flex-data">
            <div className="utility-spacing-2x">
              <h3>User Attribution Percentage</h3>
              {this.renderUserPercentage()}
            </div>
          </div>
        </MainContentContainer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user_attributions: state.user_attributions,
    attributionPercentage: state.percentage,
  };
}

const mapDispatchToProps = {
  fetchUserAttributions,
  addOrEditUserAttribution,
  deleteUserAttribution,
  updateAttributionPercentage,
  fetchAttributionPercentage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserAttributionPage);
