import React from 'react';
import './MainContentContainer.css';

export default function(props) {
  return (
    <main className={props.subContent ? 
                    "MainContentContainer MainContentContainer--small" :
                    "MainContentContainer"}
   >
     {props.children}
   </main>
  );
}
