import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchTappingPoints } from "./../../actions/tappingPointsActions";
import { Link } from "react-router-dom";

class TappingPointsTable extends Component {
  constructor(props) {
    super(props);
    this.colWidth1 = "100px";
    this.colWidth2 = "100%";
  }

  componentDidMount() {
    this.props.fetchTappingPoints();
  }

  renderTappingPoints = () => {
    if (!this.props.tapping_points) return <h1>Loading...</h1>;

    return this.props.tapping_points.map((obj) => {
      return (
        <div
          className="utility-table-row"
          key={obj.tapping_point_id + obj.tapping_point_name}
        >
          <p style={{ width: this.colWidth1 }}>
            {obj.tapping_point_image_url && (
              <img
                src={obj.tapping_point_image_url}
                alt="artist"
                style={{ height: "50px" }}
              />
            )}
          </p>
          <div style={{ width: this.colWidth2 }} className="utility-flex-row">
            <p>
              {obj.tapping_point_name === "Default Tapping Point" ? (
                <b>Default Background</b>
              ) : (
                obj.tapping_point_name
              )}
            </p>
            <div style={{ margin: "16px 0 16px auto" }}>
              <Link
                to={"/tapping-points/edit/" + obj.tapping_point_id}
                className="utility-edit-link"
              >
                Edit
              </Link>
            </div>
          </div>
        </div>
      );
    });
  };

  render() {
    return (
      <div className="TappingPointsTable">
        <div className="utility-table-row utility-table-row-headers">
          <h2 style={{ width: this.colWidth1 }}>Image</h2>
          <h2 style={{ width: this.colWidth2 }}>Asset Name</h2>
        </div>
        {this.renderTappingPoints()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    tapping_points: state.tapping_points.tapping_points,
  };
}

const mapDispatchToProps = {
  fetchTappingPoints,
};

export default connect(mapStateToProps, mapDispatchToProps)(TappingPointsTable);
