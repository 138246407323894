import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import SubNav from './../../Nav/SubNav/SubNav';
import HeaderBar from './../../HeaderBar/HeaderBar';
import MainContentContainer from './../../MainContentContainer/MainContentContainer';
import SessionsTable from './../../SessionsTable/SessionsTable';

class SessionsPage extends Component {

  render() {
    return (
      <div className="utility-page-layout">
        {/* <SubNav>
          <Link to="/sessions">Sessions</Link>
        </SubNav> */}
        <HeaderBar header="Sessions">
          <Link to="/sessions/add" className="Utility-btn-primary">Add Session</Link>
        </HeaderBar>
        <MainContentContainer>
          <SessionsTable />
        </MainContentContainer>
      </div>
    );
  }

}

export default SessionsPage;
