import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import HeaderBar from "../../HeaderBar/HeaderBar";
import MainContentContainer from "../../MainContentContainer/MainContentContainer";

import { connect } from "react-redux";

import { fetchUserCancellationReports } from "../../../actions/userCancellationActions";
import { exportCancellationDataCSV } from "../../../actions/exportCSVActions";
fetchUserCancellationReports;
const UserCancellationPage = ({
  fetchUserCancellationReports,
  exportCancellationDataCSV,
  user_cancellation_reports,
}) => {
  const [report, setReport] = useState([]);

  const colWidth1 = "136px";
  const colWidth2 = "480px";
  const colWidth3 = "200px";
  useEffect(() => {
    fetchUserCancellationReports();
  }, []);

  const handleExport = () => {
    exportCancellationDataCSV();
  };
  console.log(user_cancellation_reports);
  const renderCancellationReport = () => {
    return user_cancellation_reports.map((report, i) => {
      let dt = new Date(report.created_at);
      let formatted = dt.toLocaleString();

      return (
        <div className="utility-table-row" key={i}>
          <p style={{ width: colWidth1 }}>{report.user_cancellation_id}</p>
          <p style={{ width: colWidth2 }}>{report.reason}</p>
          <p style={{ width: colWidth3 }}>{report.user_email}</p>
          <p style={{ width: colWidth3 }}>{report.user_full_nmae}</p>
          <p style={{ width: colWidth3 }}>{formatted}</p>
        </div>
      );
    });
  };

  return (
    <div className="utility-page-layout">
      <HeaderBar header="Cancellation Report">
        <button className="Utility-btn-primary" onClick={() => handleExport()}>
          Export CSV
        </button>
      </HeaderBar>
      <MainContentContainer>
        <div>
          <div className="utility-table-row utility-table-row-headers">
            <h2 style={{ width: colWidth1 }}>Ref. #</h2>
            <h2 style={{ width: colWidth2 }}>Reason</h2>
            <h2 style={{ width: colWidth3 }}>Email</h2>
            <h2 style={{ width: colWidth3 }}>User Name</h2>
            <h2 style={{ width: colWidth3 }}>Date & Time</h2>
          </div>
          {renderCancellationReport()}
        </div>
      </MainContentContainer>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user_cancellation_reports:
      state.user_cancellations.user_cancellation_reports || [],
  };
}

const mapDispatchToProps = {
  fetchUserCancellationReports,
  exportCancellationDataCSV,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserCancellationPage);
