import axios from "axios";
import { API_URL, errorHandler, queryString } from "./index";
import { push } from "react-router-redux";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { API_ERROR, FETCH_ALL_ONBOARDING_DATA } from "../constants/ActionTypes";

import SessionHelper from "../components/Helper/SessionHelper";

//= ===============================
// Client actions
//= ===============================

export function fetchOnboardingData() {
  return function(dispatch) {
    axios
      .post(
        `${API_URL}/fetch-all-onboarding-data.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken()
        })
      )
      .then(response => {
        console.log(response);
        dispatch({
          type: FETCH_ALL_ONBOARDING_DATA,
          payload: response.data
        });
      })
      .catch(error => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function updateOnboardingData(onboarding_data_json) {
  return function(dispatch) {
    axios
      .post(
        `${API_URL}/update-all-onboarding-data.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          onboarding_data_json: onboarding_data_json,
          user_token: SessionHelper.getToken()
        })
      )
      .then(response => {
        swal({
          type: "success",
          title: "Saved!",
          timer: 1000
        }).then(() => {
          dispatch(push("/onboarding"));
        });
      })
      .catch(error => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}
