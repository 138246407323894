import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchAllPromoBanners,
  updatePromoBannerOrder,
} from "../../actions/promoBannerActions";
import { Link } from "react-router-dom";
import HeaderBar from "./../HeaderBar/HeaderBar";
import { arrayMoveImmutable } from "array-move";
import MainContentContainer from "./../MainContentContainer/MainContentContainer";
import SortablePromoBanners from "./SortablePromoBanners/SortablePromoBanners";

class PromoBannersTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banners: null,
    };
    this.colWidth0 = "140px";
    this.colWidth1 = "160px";
    this.colWidth2 = "200px";
    this.colWidth3 = "400px";
    this.colWidth4 = "100px";
    this.colWidth5 = "140px";
  }

  componentDidMount() {
    this.props.fetchAllPromoBanners();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.banners && this.state.banners === null) {
      const banners = nextProps.banners.sort(
        (a, b) =>
          Number(a.promo_banner_sort_number) -
          Number(b.promo_banner_sort_number)
      );

      this.setState({ banners: banners });
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(
      {
        banners: arrayMoveImmutable(this.state.banners, oldIndex, newIndex),
      },
      () => {
        const sort_json = this.state.banners.map((obj) => obj.promo_banner_id);
        this.props.updatePromoBannerOrder(JSON.stringify(sort_json));
      }
    );
  };

  render() {
    if (!this.state.banners) {
      return <h3>Loading...</h3>;
    }

    const widths = {
      col0: this.colWidth0,
      col1: this.colWidth1,
      col2: this.colWidth2,
      col3: this.colWidth3,
      col4: this.colWidth4,
      col5: this.colWidth5,
    };

    const dragKey = this.state.banners.reduce(
      (accum, val) => (accum += val.promo_banner_id),
      "sort-key"
    );

    return (
      <div>
        <HeaderBar header={"Promotional Banners"}>
          <Link className="utility-btn-primary" to={"/banners/add"}>
            Add New Promo Banner
          </Link>
        </HeaderBar>

        <MainContentContainer>
          <table className="SubCategoriesTable">
            <thead>
              <tr className="utility-table-row utility-table-row-headers">
                <th style={{ width: this.colWidth0, paddingLeft: "12px" }}>
                  Sort Order
                </th>
                <th style={{ width: this.colWidth1, paddingLeft: "12px" }}>
                  Image
                </th>
                <th style={{ width: this.colWidth2, paddingLeft: "12px" }}>
                  Title
                </th>
                <th style={{ width: this.colWidth3, paddingLeft: "12px" }}>
                  Description
                </th>
                <th style={{ width: this.colWidth4, paddingLeft: "12px" }}>
                  Icon
                </th>
                <th style={{ width: this.colWidth5, paddingLeft: "12px" }}>
                  Is Active
                </th>
              </tr>
            </thead>
            <SortablePromoBanners
              items={this.state.banners}
              onSortEnd={this.onSortEnd}
              widths={widths}
              key={dragKey}
            />
          </table>
        </MainContentContainer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    banners: state.banners.banners,
  };
}

const mapDispatchToProps = {
  fetchAllPromoBanners,
  updatePromoBannerOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(PromoBannersTable);
