import { Delete, Edit } from '@mui/icons-material'
import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Chip, Grid, Link, Tooltip, Typography } from '@mui/material'
import React from 'react'

export default function CardItem({ card, handleEdit, handleRemove, dragHandle }) {
    return (
        <Grid item xs={3}>
            <Card>
                <CardActionArea
                    {...dragHandle}
                >
                    <CardMedia
                        component="img"
                        image={card.front_image_url}
                        alt={card.title}
                        sx={{ filter: card.is_active ? 'none' : 'grayscale(100%)', opacity: card.is_active ? 1 : 0.65 }}
                    />
                    <Chip
                        size='small'
                        label={card.is_active ? 'Active' : 'Inactive'}
                        color={card.is_active ? 'success' : 'warning'}
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            zIndex: 1,
                        }}
                    />
                </CardActionArea>
                <CardContent sx={{ minHeight: 90 }}>
                    <Tooltip title={card.title} placement="bottom">
                        <Typography gutterBottom variant="h1" component="div" sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                            fontSize: '1.2rem'
                        }}>
                            {card.title}
                        </Typography>
                    </Tooltip>
                </CardContent>
                <CardActions>
                    <Button size="small" color="info" startIcon={<Edit />} onClick={handleEdit}>
                        Edit
                    </Button>
                    <Button size="small" color="error" startIcon={<Delete />} onClick={handleRemove}>
                        Remove
                    </Button>
                </CardActions>
            </Card>
        </Grid>
    )
}
