import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import SubNav from './../../Nav/SubNav/SubNav';
import HeaderBar from './../../HeaderBar/HeaderBar';
import MainContentContainer from './../../MainContentContainer/MainContentContainer';
import TappingPointsTable from './../../TappingPointsTable/TappingPointsTable';

class TappingPointsPage extends Component {

  render() {
    return (
      <div className="utility-page-layout">
        {/* <SubNav>
          <Link to="/tapping-points">Tapping Points</Link>
        </SubNav> */}
        <HeaderBar header="Tapping Points">
          <Link to="/tapping-points/add" className="Utility-btn-primary">Add Point</Link>
        </HeaderBar>
        <MainContentContainer>
          <TappingPointsTable />
        </MainContentContainer>
      </div>
    );
  }

}

export default TappingPointsPage;
