import { Component } from "react";
import { connect } from "react-redux";
import cookie from "react-cookies";

class SessionHelper extends Component {
  static KEY_USER = "user";
  static KEY_TOKEN = "token";

  static currentUser() {
    return SessionHelper.getValueForKey(SessionHelper.KEY_USER);
  }

  static isLoggedIn() {
    return !!SessionHelper.currentUser();
  }

  static userDict(admin_data) {
    const dict = {};
    dict.admin_id = admin_data.admin_id;
    dict.admin_email = admin_data.admin_email;
    dict.admin_name = admin_data.admin_name;
    dict.admin_image = admin_data.admin_image;
    dict.admin_role = admin_data.admin_role;

    SessionHelper.setDataForKey(dict, SessionHelper.KEY_USER);
    SessionHelper.setToken(admin_data.admin_token);
  }

  static setToken(token) {
    return SessionHelper.setDataForKey(token, SessionHelper.KEY_TOKEN);
  }

  static getToken() {
    return SessionHelper.getValueForKey(SessionHelper.KEY_TOKEN);
  }

  static setDataForKey(data, key) {
    cookie.save(key, data, { path: "/" });
    sessionStorage.setItem(key, data);
  }

  static getValueForKey(key) {
    const loadedObject = cookie.load(key);
    if (loadedObject) {
      return loadedObject;
    }
    return sessionStorage.getItem(key);
  }

  static hasRole(roles) {
    const user = SessionHelper.currentUser();
    if (user && roles) {
      return roles.includes(user.admin_role);
    }
    return false;
  }

  static clear() {
    cookie.remove(SessionHelper.KEY_TOKEN, { path: "/" });
    cookie.remove(SessionHelper.KEY_USER, { path: "/" });
    sessionStorage.clear();
  }

  static clearValueForKey(key) {
    cookie.remove(key, { path: "/" });
    sessionStorage.removeItem(key);
  }
}

export default connect()(SessionHelper);
