import React, { Component } from 'react';
import AddEditCategory from './../../AddEditCategory/AddEditCategory';

class CategoryAddEditPage extends Component {

  render() {
    const isEdit = this.props.location.pathname.indexOf('edit') > -1;

    return (
      <div className="utility-page-layout">
        <AddEditCategory
          isEdit={isEdit}
          selectedId={this.props.match.params.id} 
        />
      </div>
    );
  }

}

export default CategoryAddEditPage;
