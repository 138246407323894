import axios from 'axios';
import { API_URL, errorHandler, queryString } from './index';
import { push } from 'react-router-redux';
import swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import {
  API_ERROR,
  FETCH_ALL_SUBCATEGORIES,
  FETCH_ALL_SUBCATEGORIES_START,
  FETCH_ONE_SUBCATEGORY,
  // FETCH_ALL_SESSIONS_FOR_PROGRAM,
  // UPLOAD_SESSION_AUDIO_FILE,
  // UPLOAD_SESSION_VIDEO_FILE,
 } from '../constants/ActionTypes';

import SessionHelper from '../components/Helper/SessionHelper';

//= ===============================
// Session actions
//= ===============================

export function fetchAllSubCategories(type = 0, is_quick_tap = null) {
  return function(dispatch) {
    dispatch({
      type: FETCH_ALL_SUBCATEGORIES_START
    });

    axios.post(`${API_URL}/fetch-all-subcategories.php`,
                        queryString.stringify({
                            requesting_user_id: SessionHelper.currentUser().admin_id,
                            user_token: SessionHelper.getToken(),
                            is_audiobook: type,
                            is_quick_tap: is_quick_tap
                        }))
    .then((response) => {
      let obj = [];
      const objects = response.data.objects.reduce((acc,el) => {
         acc[el.subcategory_id] = el
         return acc;
      }, {})

      dispatch({
        type: FETCH_ALL_SUBCATEGORIES,
        payload: objects,
      });
    })
    .catch((error) => {
      errorHandler(dispatch, error.response, API_ERROR)
    });
  }
}

export function fetchOneSubcategory(subcategory_id) {
  return function(dispatch) {
    axios.post(`${API_URL}/fetch-cms-subcategory.php`,
                        queryString.stringify({
                            requesting_user_id: SessionHelper.currentUser().admin_id,
                            user_token: SessionHelper.getToken(),
                            subcategory_id: subcategory_id
                        }))
    .then((response) => {
      dispatch({
        type: FETCH_ONE_SUBCATEGORY,
        payload: {[response.data.subcategory_id]: response.data},
      });
    })
    .catch((error) => {
      errorHandler(dispatch, error.response, API_ERROR)
    });
  }
}

export function clearAllSubCategories() {
  return function(dispatch) {
    dispatch({
      type: FETCH_ALL_SUBCATEGORIES,
      payload: null,
    });
  }
}

export function addEditSubCategory(options) {
  return function(dispatch) {
    axios.post(`${API_URL}/add-n-edit-subcategory.php`,
                        queryString.stringify({
                            requesting_user_id: SessionHelper.currentUser().admin_id,
                            user_token: SessionHelper.getToken(),
                            subcategory_json: options.subcategory_json,
                            category_id: options.category_id,
                            quotes_json: options.quotes_json,
                            session_ids_json: options.session_ids_json,
                        }))
    .then((response) => {
      swal({
        type: 'success',
        title: 'Saved!',
        timer: 1000,
      }).then(()=>{
          dispatch(push('/subcategories'));
      })
    })
    .catch((error) => {
      errorHandler(dispatch, error.response, API_ERROR)
    });
  }
}

export function addEditAudioBook(options) {
  return function(dispatch) {
    axios.post(`${API_URL}/add-n-edit-subcategory.php`,
                        queryString.stringify({
                            requesting_user_id: SessionHelper.currentUser().admin_id,
                            user_token: SessionHelper.getToken(),
                            subcategory_json: options.subcategory_json,
                            category_id: options.category_id,
                            quotes_json: options.quotes_json,
                            session_ids_json: options.session_ids_json,
                        }))
    .then((response) => {
      swal({
        type: 'success',
        title: 'Saved!',
        timer: 1000,
      }).then(()=>{
          dispatch(push('/audiobooks'));
      })
    })
    .catch((error) => {
      errorHandler(dispatch, error.response, API_ERROR)
    });
  }
}



export function deleteSubCategory(subcategory_id) {
  return function(dispatch) {
    axios.post(`${API_URL}/delete-subcategory.php`,
                        queryString.stringify({
                            requesting_user_id: SessionHelper.currentUser().admin_id,
                            subcategory_id: subcategory_id,
                            user_token: SessionHelper.getToken()
                        }))
    .then((response) => {
      swal({
        type: 'success',
        title: 'Subcategory Deleted!',
        timer: 1000,
      }).then(()=>{
          dispatch(push('/subcategories'));
      })
    })
    .catch((error) => {
      errorHandler(dispatch, error.response, API_ERROR)
    });
  }
}

export function nonExistentSubCategory(){
  return function(dispatch) {
    swal({
      type: "error",
      text: "Subcategory does not exist!",
      showConfirmButton: true,
      showCancelButton: false,
      confirmButtonText: "Return"
    }).then(() => {
      dispatch(push("/subcategories"));
    });
  };
}
