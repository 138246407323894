//= ===============================
// Server Instance
//= ===============================
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

const hostname = window && window.location && window.location.hostname;
let backendHost;

const DEV_URL = "http://localhost:4000/scripts";
const STAGING_URL = "https://staging.backend.thetappingsolution.com/scripts";
const PRODUCTION_URL = "https://ap1.thetappingsolution.com/scripts";

switch (hostname) {
  case "localhost":
    backendHost = DEV_URL;
    if (process.env.REACT_APP_DEV_URL_OVERRIDE) {
      backendHost = process.env.REACT_APP_DEV_URL_OVERRIDE;
    }
    break;
  case "dev-tapping-solution.netlify.com":
    backendHost = STAGING_URL;
    break;
  case "staging-tapping-solution.netlify.app":
    backendHost = STAGING_URL;
    break;
  case "cms.thetappingsolution.com":
    backendHost = PRODUCTION_URL;
    break;
  default:
    if (hostname.includes('-prod-tapping-solution.netlify.app')) {
      backendHost = PRODUCTION_URL;
    } else {
      backendHost = STAGING_URL;
    }
}
export const API_URL = backendHost;
export const queryString = require("query-string");

//= ===============================
// Utility actions
//= ===============================

export function createError(message) {
  return {
    data: {
      message,
    },
  };
}

export function errorHandler(dispatch, error, type) {
  if (!error) {
    return false;
  }

  swal({
    type: "warning",
    text: error && error.data ? error.data.message : error.data,
  });
}

export function generalErrorHandle(message) {
  swal({
    type: "error",
    text: message,
  });
}

//= ===============================
// Static Content actions
//= ===============================
