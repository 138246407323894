import React, { Component } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { Link } from 'react-router-dom';
import { MdReorder } from "react-icons/md";
import { MdModeEdit } from 'react-icons/md';

const DragHandle = SortableHandle(() => <div className="utility-table-column sort-handle" title='Drag to reorder'><MdReorder/></div>);

const SortableItem = SortableElement(({item, itemIndex, highlightIndex}) => {
  let className="utility-table-row";
  if(highlightIndex != null && highlightIndex === itemIndex){
    className+=" row-successful-highlight"
  }else{
    className+=" row-normal-fade-out"
  }
  return (
    <div className={className} key={item.category_id}>
      <DragHandle />
      <div className="utility-table-column utility-table-column-expanded">{item.title}</div>
    </div>
  )
});

const SortableList = SortableContainer(({items, highlightIndex}) => {
  return (
    <div>
      {items.map((value, index) => {
        return (
          <SortableItem
            key={`item-${index}`}
            index={index}
            highlightIndex={highlightIndex}
            itemIndex={index}
            item={value} />
        )
      })}
    </div>
  );
});

class QuickTapsFeaturedList extends Component {
  render() {
    return (
      <SortableList
        items={this.props.items}
        highlightIndex={this.props.highlightIndex}
        onSortEnd={this.props.onSortEnd}
        useDragHandle={true}
        helperClass="utility-flex-row-dragging"
      />
    );
  };
}

export default QuickTapsFeaturedList;
