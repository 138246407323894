import React, { Component } from "react";
import AuthNav from "../components/Nav/AuthNav/AuthNav";
import ModalContainer from "../components/Modal/ModalContainer";

export default class AuthLayout extends Component {
  render() {
    const { pathname } = this.props.children.props.location;
    const { email } = this.props;
    return (
      <div>
        <AuthNav path={pathname} />
        {this.props.children}
        <ModalContainer />
      </div>
    );
  }
}
