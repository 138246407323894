import React, { useState, useEffect } from 'react';
import { Grid, TextField, FormControl, Autocomplete, Switch, FormGroup, FormControlLabel } from '@mui/material';
import CardDeckImage from './Common/CardDeckImage';
import { useDispatch, useSelector } from 'react-redux';
import { uploadImage, clearMedia } from '../../../actions/mediaActions';
import FieldSetGroup from '../../Elements/FieldSetGroup';

const DeckForm = ({ deck, handleChange }) => {
  const dispatch = useDispatch();
  const mediaStore = useSelector((state) => {
    return state.media
  });

  const categoryStore = useSelector((state) => {
    return state.categories
  });

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    if (mediaStore.image) {
      deck[mediaStore.target] = mediaStore.image;
      dispatch(clearMedia());
      handleChange({ target: { name: mediaStore.target, value: mediaStore.image } });
    }
  }, [mediaStore]);

  useEffect(() => {
    if (categoryStore.all_categories) {
      const emptyCategory = { category_id: null, category_title: 'Select Category' };
      const newCategories = [emptyCategory, ...categoryStore.all_categories];
      setCategories(newCategories);
      const selectedCategory = newCategories.find(
        (category) => category.category_id === deck.category_id
      ) || emptyCategory;
      setSelectedCategory(selectedCategory);
    }
  }, [categoryStore, deck]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    dispatch(uploadImage(file, e.target.name));
  };

  const removeImage = (e) => {
    handleChange({ target: { name: e.target.name, value: "" } });
  }

  const handleSwitchChange = (e) => {
    handleChange({ target: { name: e.target.name, value: e.target.checked ? 1 : 0 } });
  }

  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: 1 }}>
        <Grid item xs={8}>
          <TextField
            fullWidth
            label="Name"
            variant="outlined"
            name="name"
            value={deck.name ?? ""}
            onChange={handleChange}
            autoFocus
            required
          />
        </Grid>
        <Grid container item xs={4} alignItems="center">
          <Grid item xs={6}>
            <FormGroup>
              <FormControlLabel
                control={<Switch aria-label="is premium" checked={Boolean(deck.is_premium)} onChange={handleSwitchChange} name="is_premium" />}
                label="Is Premium?"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={6}>
            <FormGroup>
              <FormControlLabel
                control={<Switch aria-label="is active" checked={Boolean(deck.is_active)} onChange={handleSwitchChange} name="is_active" />}
                label="Is Active?"
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <FormControl fullWidth>
            <Autocomplete
              id='category-select'
              disablePortal
              options={categories}
              getOptionLabel={(option) => (option.category_title || '') + (option.category_is_quick_tap > 0 ? ' (Quick Tap)' : '')}
              getOptionKey={(option) => option.category_id}
              value={selectedCategory}
              renderInput={(params) => <TextField {...params} label="Category" variant="outlined" />}
              onChange={(_, v) => {
                if (v == null) {
                  v = categories[0];
                }
                setSelectedCategory(v);
                handleChange({ target: { name: 'category_id', value: v.category_id } })
              }}
            />
          </FormControl>
        </Grid>
        <Grid container spacing={2} item xs={4}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Primary color"
              variant="outlined"
              type="color"
              name="primary_color_hex"
              value={deck.primary_color_hex}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Free View Limit"
              variant="outlined"
              name="free_view_limit"
              value={deck.free_view_limit}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} >
          <FieldSetGroup title="Deck Descriptions">
            <Grid container spacing={2} item xs={12} >

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Module Description"
                  variant="outlined"
                  name="module_description"
                  multiline
                  minRows={3}
                  value={deck.module_description ?? ""}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Choose Card Screen Description"
                  variant="outlined"
                  name="description"
                  multiline
                  minRows={3}
                  value={deck.description ?? ""}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="See All - Description"
                  variant="outlined"
                  multiline
                  minRows={3}
                  name="see_all_description"
                  value={deck.see_all_description ?? ""}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </FieldSetGroup>
        </Grid>
        <Grid item xs={12}>
          <FieldSetGroup title="Sharing">
            <Grid container spacing={2} item xs={12}>
              <Grid item xs={4}>
                <CardDeckImage
                  title={"Share Image"}
                  image={deck.share_image_url}
                  name="share_image_url"
                  handleRemove={removeImage}
                  handleImageUpload={handleImageUpload}
                  showCopyUrlButton={true}
                  sx={{ padding: 5, background: '#FFFCF5', height: 250, objectFit: 'contain' }}
                />
              </Grid>
              <Grid item xs={8}>
                <Grid item xs={12} sx={{ marginBottom: 2 }}>
                  <TextField
                    fullWidth
                    label="Share Deeplink URL"
                    variant="outlined"
                    name="share_deeplink_url"
                    value={deck.share_deeplink_url ?? ""}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </FieldSetGroup>
        </Grid>
        <Grid item xs={12}>
          <FieldSetGroup title="Purchase Store">
            <Grid container spacing={2} item xs={12}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Purchase Store URL"
                  variant="outlined"
                  name="purchase_store_url"
                  value={deck.purchase_store_url ?? ""}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Purchase Store Text"
                  variant="outlined"
                  multiline
                  minRows={3}
                  name="purchase_store_text"
                  value={deck.purchase_store_text ?? ""}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </FieldSetGroup>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={3}>
          <CardDeckImage
            title={"Module Image"}
            image={deck.module_image_url}
            name="module_image_url"
            handleRemove={removeImage}
            handleImageUpload={handleImageUpload}
            sx={{ padding: 5, background: '#FFFCF5', height: 250, objectFit: 'contain' }}
          />
        </Grid>
        <Grid item xs={3}>
          <CardDeckImage
            title={"Header Image"}
            image={deck.header_image_url}
            name="header_image_url"
            handleRemove={removeImage}
            handleImageUpload={handleImageUpload}
            sx={{ padding: 5, background: '#FFFCF5', height: 250, objectFit: 'contain' }}
          />
        </Grid>
        <Grid item xs={3}>
          <CardDeckImage
            title={"Wrapper Image"}
            image={deck.wrapper_image_url}
            name="wrapper_image_url"
            handleRemove={removeImage}
            handleImageUpload={handleImageUpload}
            sx={{ padding: 5, background: '#FFFCF5', height: 250, objectFit: 'contain' }}
          />
        </Grid>
        <Grid item xs={3}>
          <CardDeckImage
            title={"Search Result Image"}
            image={deck.search_result_image_url}
            name="search_result_image_url"
            handleRemove={removeImage}
            handleImageUpload={handleImageUpload}
            sx={{ padding: 5, background: '#FFFCF5', height: 250, objectFit: 'contain' }}
          />
        </Grid>
      </Grid >
    </>
  );
};

export default DeckForm;
