import axios from "axios";
import { API_URL, errorHandler, queryString } from "./index";
import { push } from "react-router-redux";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { API_ERROR, FETCH_ALL_SERIES, FETCH_ALL_SERIES_START } from "../constants/ActionTypes";

import SessionHelper from "../components/Helper/SessionHelper";

//= ===============================
// Session actions
//= ===============================

export function fetchAllSeries() {
  return function(dispatch) {
    dispatch({
      type: FETCH_ALL_SERIES_START
    });

    axios
      .post(
        `${API_URL}/fetch-all-series-cms.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken()
        })
      )
      .then(response => {
        dispatch({
          type: FETCH_ALL_SERIES,
          payload: response.data.objects
        });
      })
      .catch(error => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function addEditSeries(options) {
  return function(dispatch) {
    axios
      .post(
        `${API_URL}/add-n-edit-series.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken(),
          series_json: options.series_json,
          category_id: options.category_id,
          session_ids_json: options.session_ids_json
        })
      )
      .then(response => {
        swal({
          type: "success",
          title: "Saved!",
          timer: 1000
        }).then(() => {
          dispatch(push("/series"));
        });
      })
      .catch(error => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function clearAllSeries() {
  return function(dispatch) {
    dispatch({
      type: FETCH_ALL_SERIES,
      payload: null
    });
  };
}

export function deleteSeries(series_id) {
  return function(dispatch) {
    axios
      .post(
        `${API_URL}/delete-series.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          series_id: series_id,
          user_token: SessionHelper.getToken()
        })
      )
      .then(response => {
        swal({
          type: "success",
          title: "Series Deleted!",
          timer: 1000
        }).then(() => {
          dispatch(push("/series"));
        });
      })
      .catch(error => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}
