import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchAllSessions,
  clearSessions,
} from "./../../actions/sessionActions";
import { Link } from "react-router-dom";

class SessionsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
    };
    this.colWidth1 = "136px";
    this.colWidth2 = "480px";
    this.colWidth3 = "200px";
    this.colWidth4 = "240px";
  }

  componentDidMount() {
    this.props.clearSessions();
    this.props.fetchAllSessions();
  }

  handleSearchChange = (e) => {
    const { value } = e.target;
    this.setState({ searchText: value });
  };

  renderSessions = () => {
    let data = [...this.props.all_sessions];

    if (this.state.searchText !== "") {
      const search = this.state.searchText.toLowerCase();
      data = data.filter(
        (obj) => obj.session_name.toLowerCase().indexOf(search) > -1
      );
    }
    return data.map((obj) => {
      let parent = "none";

      if (!!obj.category_id) {
        parent = "Category";
      }
      if (!!obj.subcategory_id) {
        if (obj.subcategory_is_book === 1) {
          parent = "AudioBook";
        } else {
          parent = "Subcategory";
        }
      }

      if (!!obj.series_id) {
        parent = "Series";
      }
      if (!!obj.challenge_id) {
        parent = "Challenge";
      }
      return (
        <div
          className="utility-table-row"
          key={obj.session_id + obj.session_name}
        >
          <p style={{ width: this.colWidth2 }}>{obj.session_name}</p>
          <p style={{ width: this.colWidth3 }}>{parent}</p>
          <p style={{ width: this.colWidth3 }}>
            {obj.session_is_active === 0 ? (
              "Not Active"
            ) : (
              <span className="utility-active">Active</span>
            )}
          </p>
          <p style={{ width: this.colWidth3 }}>
            {obj.session_is_recommended === 0 ? "No" : "Yes"}
          </p>
          <p style={{ width: this.colWidth3 }}>
            {obj.session_is_duplicate === 0 ? "No" : "Yes"}
          </p>
          <div
            style={{ width: this.colWidth4 }}
            className="utility-table-end-col"
          >
            <p>{obj.session_is_locked === 1 ? "Premium" : "Free"}</p>
            <div style={{ margin: "16px 5px 16px auto" }}>
              <Link
                to={"/sessions/edit/" + obj.session_id}
                className="utility-edit-link"
              >
                Edit
              </Link>
            </div>
          </div>
        </div>
      );
    });
  };

  render() {
    if (!this.props.all_sessions) {
      return <h1 style={{ textAlign: "center" }}>Loading...</h1>;
    }

    return (
      <div className="SessionsTable">
        <div
          className="utility-flex-row"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <p>
            <b>Search Sessions: </b>
          </p>
          <input
            type="text"
            value={this.state.searchText}
            className="utility-text-input"
            onChange={this.handleSearchChange}
            style={{ marginLeft: "20px" }}
          />
        </div>

        {this.state.searchText !== "" && (
          <h3>Showing sessions matching search: {this.state.searchText}</h3>
        )}

        <div className="utility-table-row utility-table-row-headers">
          <h2 style={{ width: this.colWidth2 }}>Session Name</h2>
          <h2 style={{ width: this.colWidth3 }}>Parent</h2>
          <h2 style={{ width: this.colWidth3 }}>Is Active</h2>
          <h2 style={{ width: this.colWidth3 }}>Recommended</h2>
          <h2 style={{ width: this.colWidth3 }}>Duplicate </h2>
          <h2 style={{ width: this.colWidth4 }}>Free/Premium</h2>
        </div>
        {this.renderSessions()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    all_sessions: state.sessions.all_sessions,
  };
}

const mapDispatchToProps = {
  clearSessions,
  fetchAllSessions,
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionsTable);
