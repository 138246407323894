import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchAllSubCategories,
  clearAllSubCategories,
} from "../../actions/subcategoryActions";
import { Link } from "react-router-dom";
import HeaderBar from "../HeaderBar/HeaderBar";
import MainContentContainer from "../MainContentContainer/MainContentContainer";

class AudioBookTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
    };
    this.colWidth1 = "536px";
    this.colWidth2 = "400px";
    this.colWidth3 = "220px";
  }

  componentDidMount() {
    this.props.clearAllSubCategories();
    this.props.fetchAllSubCategories(1);
  }

  handleSearchChange = (e) => {
    const { value } = e.target;
    this.setState({ searchText: value });
  };

  renderAudioBooks = () => {
    if (!this.props.subcategories) return null;

    let data = [...this.props.subcategories];
    data = data.filter((el) => el.subcategory_is_book === 1);

    if (this.state.searchText !== "") {
      const search = this.state.searchText.toLowerCase();
      data = data.filter(
        (obj) => obj.subcategory_title.toLowerCase().indexOf(search) > -1
      );
    }

    return data.map((obj) => {
      return (
        <div
          className="utility-table-row"
          key={obj.subcategory_id + obj.subcategory_title}
        >
          <p style={{ width: this.colWidth1 }}>{obj.subcategory_title}</p>
          <p style={{ width: this.colWidth2 }}>
            {obj.category_title || "none"}
          </p>

          <div
            style={{ width: this.colWidth3 }}
            className="utility-table-end-col"
          >
            <p>
              {obj.subcategory_is_active === 0 ? (
                "Not Active"
              ) : (
                <span className="utility-active">Active</span>
              )}
            </p>
            <div style={{ margin: "16px 0 16px auto" }}>
              <Link
                to={"/audiobooks/edit/" + obj.subcategory_id}
                className="utility-edit-link"
              >
                Edit
              </Link>
            </div>
          </div>
        </div>
      );
    });
  };

  render() {
    let categoryName = "Books";

    return (
      <div>
        <HeaderBar header={categoryName}>
          <Link
            style={{ marginRight: 10 }}
            className="utility-btn-primary"
            to={"/category/edit/32"}
          >
            Manage AudioBook
          </Link>

          <Link className="utility-btn-primary" to={"/audiobooks/add"}>
            Create New Book
          </Link>
        </HeaderBar>

        <MainContentContainer>
          <div className="AudioBookTable">
            <div
              className="utility-flex-row"
              style={{ alignItems: "center", justifyContent: "center" }}
            >
              <p>
                <b>Search AudioBook List: </b>
              </p>
              <input
                type="text"
                value={this.state.searchText}
                className="utility-text-input"
                onChange={this.handleSearchChange}
                style={{ marginLeft: "20px" }}
              />
            </div>

            {this.state.searchText !== "" && (
              <h3>
                Showing audiobook matching search: {this.state.searchText}
              </h3>
            )}

            <div className="utility-table-row utility-table-row-headers">
              <h2 style={{ width: this.colWidth1 }}>Title</h2>
              <h2 style={{ width: this.colWidth2 }}>In Category</h2>
              <h2 style={{ width: this.colWidth3 }}>Is Active</h2>
            </div>
            {this.renderAudioBooks()}
          </div>
        </MainContentContainer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    subcategories: state.subcategories.subcategories
      ? Object.values(state.subcategories.subcategories)
      : null,
    all_categories: state.categories.all_categories,
  };
}

const mapDispatchToProps = {
  fetchAllSubCategories,
  clearAllSubCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(AudioBookTable);
