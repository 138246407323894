import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import Login from './../../Login/Login';
import './LoginPage.css';
import SessionHelper from '../../Helper/SessionHelper';
// Import Routes Configuration
import RouteConfig from "../../../constants/RouteConfig";

class LoginPage extends Component {

  render() {
    const navRoutes = RouteConfig
      .filter(route => route.label && SessionHelper.hasRole(route.allowRoles))
      .sort((a, b) => a.order - b.order);
    const route = navRoutes[0];
    if (SessionHelper.isLoggedIn()) {
      return (<Redirect
        to={{
          pathname: route.path,
          state: { from: this.props.location },
        }}
      />);
    } else {
      return (
        <div className="LoginPage">
          <Login></Login>
        </div>
      );
    }

  }
}

export default withRouter(LoginPage);
