import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import MainContentContainer from "./../MainContentContainer/MainContentContainer";
import { fetchUserStreak, restoreUserStreak } from "./../../actions/userStreakActions";

class UserStreakPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_streak_email: '',
      user_streak: [],
      streak_days: props.streak_days || 10,
      openDialog: false,
      missingDates: []
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user_streak !== this.props.user_streak) {
      this.setState({
        user_streak: this.props.user_streak,
        missingDates: this.calculateMissingDates(this.props.user_streak, this.state.streak_days)
      });
    }
  }

  fetchData = () => {
    this.props.fetchUserStreak({
      user_email: this.state.user_streak_email,
      days: this.state.streak_days
    });
  }

  restoreStreak = () => {
    this.props.restoreUserStreak({
      user_email: this.state.user_streak_email,
      days: this.state.streak_days
    });
    this.handleCloseDialog();
  }

  setEmail = (email) => {
    this.setState({ user_streak_email: email });
  }

  setDays = (days) => {
    this.setState({ streak_days: days });
  }

  handleOpenDialog = () => {
    this.setState({ openDialog: true });
  }

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  }

  calculateMissingDates = (user_streak, streak_days) => {
    return Array.from(new Set(user_streak.slice(0, streak_days).filter(item => item.streak == 0).map(item => item.date)));
  }

  render() {
    const { user_streak, missingDates, openDialog } = this.state;
    const streakData = Array.isArray(user_streak) ? user_streak : [];
    let currentStreak = 0;
    let maxStreak = 0;

    console.log("missingDates", missingDates.length);
    return (
      <div className="utility-page-layout">
        <MainContentContainer>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <TextField
                label="User Email"
                variant="outlined"
                value={this.state.user_streak_email}
                onChange={(e) => this.setEmail(e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Number of Days"
                variant="outlined"
                type="number"
                value={this.state.streak_days}
                onChange={(e) => this.setDays(e.target.value)}
                fullWidth
                margin="normal"
              />
              <Button variant="contained" color="primary" onClick={this.fetchData}>
                Fetch Data
              </Button>
              {streakData.length > 0 && (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: "50px" }}
                  onClick={this.handleOpenDialog}
                >
                  Restore Streak
                </Button>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              {missingDates.length > 0 && (
                <Paper style={{ padding: "10px" }}>
                  <h3>Missing Dates ({missingDates.length})</h3>
                  <ul>
                    {missingDates.map(date => (
                      <li key={date}>{date}</li>
                    ))}
                  </ul>
                </Paper>
              )}
            </Grid>
          </Grid>
          <TableContainer style={{ marginTop: "50px" }} component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Streak</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {streakData.map((item, index) => {
                  const isConsecutive = item.streak > 0;
                  if (isConsecutive) {
                    currentStreak++;
                    maxStreak = Math.max(currentStreak, maxStreak);
                  } else {
                    currentStreak = 0;
                  }

                  return (
                    <TableRow key={item.date} style={{ backgroundColor: isConsecutive ? 'lightgreen' : 'lightcoral' }}>
                      <TableCell>{item.date}</TableCell>
                      <TableCell>{item.streak}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Dialog
            open={openDialog}
            onClose={this.handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Confirm Restore"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to restore {this.state.user_streak_email}'s streak?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={this.restoreStreak} color="primary" autoFocus>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </MainContentContainer>
      </div >
    );
  }
}

function mapStateToProps(state) {
  return {
    user_streak: state.user_streak.user_streak,
    restored_dates: state.user_streak.restored_dates
  };
}

const mapDispatchToProps = {
  fetchUserStreak,
  restoreUserStreak
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserStreakPage);
