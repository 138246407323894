import axios from 'axios';
import { push } from 'react-router-redux';
import { API_URL, errorHandler, queryString } from './index';
import {
  API_ERROR,
  FETCH_REQUESTS
} from '../constants/ActionTypes';
import swal from 'sweetalert2';

import SessionHelper from '../components/Helper/SessionHelper';

export function fetchRequests(page, page_size) {
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/fetch-all-requests.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken(),
          page: page,
          page_size: page_size
        })
      )
      .then(response => {
        dispatch({
          type: FETCH_REQUESTS,
          payload: response.data
        });
      })
      .catch(error => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}