import axios from "axios";
import { push } from "react-router-redux";
import { API_URL, errorHandler, queryString } from "./index";
import {
  API_ERROR,
  FETCH_ALL_TOP_SEARCH,
  DELETE_TOP_SEARCH,
} from "../constants/ActionTypes";
import swal from "sweetalert2";

import SessionHelper from "../components/Helper/SessionHelper";

//= ===============================
// Client actions
//= ===============================

export function fetchTopSearches() {
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/fetch-top-searches.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken(),
        })
      )
      .then((response) => {
        dispatch({
          type: FETCH_ALL_TOP_SEARCH,
          payload: response.data.objects,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function deleteTopSearch(top_search_id) {
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/delete-one-top-search.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken(),
          top_search_id: top_search_id,
        })
      )
      .then((response) => {
        dispatch({
          type: DELETE_TOP_SEARCH,
          payload: top_search_id,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function addOrEditTopSearch(top_search_data) {
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/add-n-edit-top-searches.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken(),
          top_search_data: top_search_data,
        })
      )
      .then((response) => {
        swal({
          type: "success",
          title: "Top Search Updated!",
          timer: 1000,
        }).then(() => {
          window.location.reload();
        });
      })

      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}
