import { createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useMemo } from 'react';

// This function will create our theme
const createAppTheme = (mode) => createTheme({
  palette: {
    mode,
    primary: {
      main: '#8BCBF0',
    },
    secondary: {
      main: '#B1D77B',
    },
  },
});

// This is a custom hook that we'll use in our components
export function useAppTheme() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = useMemo(
    // TODO define dark theme colors because now it's not working.
    // change the 'light' : 'light' to 'dark' : 'light' when dark theme is ready
    () => createAppTheme(prefersDarkMode ? 'light' : 'light'),
    [prefersDarkMode]
  );

  return theme;
}

// We'll also export a default theme for cases where we can't use the hook
export default createAppTheme('light');