import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import SubNav from './../../Nav/SubNav/SubNav';
import HeaderBar from './../../HeaderBar/HeaderBar';
import MainContentContainer from './../../MainContentContainer/MainContentContainer';
import AuthorsTable from './../../AuthorsTable/AuthorsTable';

class AuthorsPage extends Component {

  render() {
    return (
      <div className="utility-page-layout">
        {/* <SubNav>
          <Link to="/authors">Authors</Link>
        </SubNav> */}
        <HeaderBar header="Authors">
          <Link to="/authors/add" className="Utility-btn-primary">Add Author</Link>
        </HeaderBar>
        <MainContentContainer>
          <AuthorsTable />
        </MainContentContainer>
      </div>
    );
  }

}

export default AuthorsPage;
