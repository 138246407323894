import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import HeaderBar from "./../HeaderBar/HeaderBar";
import MainContentContainer from "./../MainContentContainer/MainContentContainer";

import {
  fetchTappingPoints,
  clearTappingPoints,
  addEditTappingPoint,
  deleteTappingPoint,
} from "./../../actions/tappingPointsActions";

import { uploadImage, clearMedia } from "./../../actions/mediaActions";

import swal from "sweetalert2";

import "./AddEditTappingPoints.css";

class AddEditTappingPoints extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tapping_point_name: "",
      tapping_point_image_url: "",
      tapping_point_image_preview: null,
      tapping_point_is_default: 0,
      isSetToEdit: false,
    };
  }

  componentDidMount() {
    this.props.clearTappingPoints();
    this.props.clearMedia();
    if (this.props.isEdit) {
      this.props.fetchTappingPoints();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.isEdit &&
      nextProps.tapping_points &&
      !this.state.isSetToEdit
    ) {
      let currentPoint = null;
      for (let x = 0; x < nextProps.tapping_points.length; x++) {
        if (
          nextProps.tapping_points[x].tapping_point_id ===
          Number(this.props.selectedId)
        ) {
          currentPoint = nextProps.tapping_points[x];
          break;
        }
      }
      if (currentPoint === null) {
        swal({
          type: "warning",
          text: "Error finding selected Tapping Point",
        });
      }
      this.setState({ ...currentPoint, isSetToEdit: true });
    }
  }

  componentWillUnmount() {
    if (this.state.tapping_point_image_preview) {
      window.URL.revokeObjectURL(this.state.tapping_point_image_preview);
    }
  }

  handleFieldChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState((prevState) => {
      const state = { ...prevState };
      state[name] = value;
      return state;
    });
  };

  handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (!this.state.tapping_point_image_preview) {
      this.setState({
        tapping_point_image_preview: window.URL.createObjectURL(file),
      });
    } else {
      window.URL.revokeObjectURL(this.state.tapping_point_image_preview);
      this.setState({
        tapping_point_image_preview: window.URL.createObjectURL(file),
      });
    }
    this.props.uploadImage(file);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let data = { ...this.state };
    if (this.props.image) {
      data.tapping_point_image_url = this.props.image;
    }
    data = JSON.stringify(data);
    this.props.addEditTappingPoint(data);
  };

  handleDelete = (e) => {
    e.preventDefault();
    swal({
      type: "warning",
      text: "Are you sure you want to delete this Tapping Point?",
      showConfirmButton: true,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.value) {
        this.props.deleteTappingPoint(this.props.selectedId);
      }
    });
  };

  render() {
    const { isEdit } = this.props;

    if (isEdit && !this.props.tapping_points) {
      return <h1>Loading...</h1>;
    }

    return (
      <div>
        <form
          className="AddEditTappingPoints"
          onSubmit={this.handleSubmit}
          autoComplete="off"
        >
          <HeaderBar
            header={isEdit ? "Edit Tapping Point" : "Add Tapping Point"}
          >
            {isEdit && (
              <Link
                to="/tapping-points"
                className="utility-btn-transparent utility-lr-margin-small"
              >
                Cancel
              </Link>
            )}
            <button className="utility-btn-primary">
              {isEdit ? "Update" : "Create"}
            </button>
          </HeaderBar>
          <MainContentContainer subContent={true}>
            <div>
              <label className="utility-inline-label">
                <p>Tapping Point Name</p>
                {this.state.tapping_point_is_default === 0 ? (
                  <input
                    type="text"
                    className="utility-text-input"
                    name="tapping_point_name"
                    value={this.state.tapping_point_name}
                    onChange={this.handleFieldChange}
                    required
                  />
                ) : (
                  <p style={{ fontWeight: "bold" }}>Default Background</p>
                )}
              </label>
            </div>
            <div className="utility-inline-label AddEditTappingPoints-img-upload">
              <p>Image</p>
              <div className="utility-flex-row">
                {(this.state.tapping_point_image_preview ||
                  this.state.tapping_point_image_url) && (
                  <img
                    src={
                      this.state.tapping_point_image_preview ||
                      this.state.tapping_point_image_url
                    }
                    alt="Profile"
                    style={{ maxHeight: "200px", marginRight: "50px" }}
                  />
                )}
                <label className="AddEditArtist-img-upload">
                  <input
                    type="file"
                    onChange={this.handleImageUpload}
                    accept="image/*"
                  />
                  <p className="utility-btn-primary" style={{ color: "#fff" }}>
                    Upload Picture
                  </p>
                </label>
              </div>
            </div>

            {isEdit && this.state.tapping_point_is_default === 0 && (
              <div style={{ marginTop: "60px" }}>
                <button
                  className="utility-btn-danger"
                  onClick={this.handleDelete}
                >
                  Delete Tapping Point
                </button>
              </div>
            )}
          </MainContentContainer>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    image: state.media.image,
    tapping_points: state.tapping_points.tapping_points,
  };
}

const mapDispatchToProps = {
  fetchTappingPoints,
  clearTappingPoints,
  addEditTappingPoint,
  deleteTappingPoint,
  uploadImage,
  clearMedia,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEditTappingPoints);
