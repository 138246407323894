import React, { Component } from "react";
import { connect } from "react-redux";
import MainContentContainer from "../MainContentContainer/MainContentContainer";
import {
  fetchSearchDeeplinksInfo,
  saveSearchDeeplinksInfo,
} from "../../actions/searchDeeplinksActions";
import {
  uploadImage,
  clearMedia
} from "../../actions/mediaActions";

class SearchDeeplinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search_deeplink_image_url: props.search_deeplink_image_url ?? "",
      search_deeplink_ios_url: props.search_deeplink_ios_url ?? "",
      search_deeplink_android_url: props.search_deeplink_android_url ?? "",
      deeplink_image_preview: "",
      deeplink_image: "",
      image: "",
      deeplink_image_uploading: false,
      edited: false,
    }
  }

  componentDidMount() {
    this.props.fetchSearchDeeplinksInfo();
    this.props.clearMedia();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.search_deeplink_ios_url !== this.props.search_deeplink_ios_url) {
      this.setState({ search_deeplink_ios_url: this.props.search_deeplink_ios_url });
    }
    if (prevProps.search_deeplink_android_url !== this.props.search_deeplink_android_url) {
      this.setState({ search_deeplink_android_url: this.props.search_deeplink_android_url });
    }
    if (prevProps.search_deeplink_image_url !== this.props.search_deeplink_image_url) {
      this.setState({ search_deeplink_image_url: this.props.search_deeplink_image_url });
    }
    if (prevProps.image !== this.props.image) {
      if (this.state.deeplink_image_uploading) {
        this.setState({
          search_deeplink_image_url: this.props.image,
          deeplink_image_uploading: false,
          edited: true
        });
        this.props.clearMedia();
      } else {
        this.setState({
          search_deeplink_image: this.props.image
        });
        this.props.clearMedia();
      }
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.saveSearchDeeplinksInfo(
      this.state.search_deeplink_image_url,
      this.state.search_deeplink_ios_url,
      this.state.search_deeplink_android_url
    );
    this.setState({ edited: false });
  };

  handleDeepLinkImageUpload = (e) => {
    const file = e.target.files[0];
    if (this.state.deeplink_image_preview) {
      window.URL.revokeObjectURL(this.state.deeplink_image_preview);
    }
    this.setState({
      deeplink_image_preview: window.URL.createObjectURL(file),
      deeplink_image_uploading: true,
    });
    this.props.uploadImage(file);
  };

  render() {
    return (
      <div>
        <MainContentContainer>
          <div className="HeaderBar">
            <h2>Search Results Deeplinks</h2>
          </div>
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <div className="utility-inline-label">
                <p>Deep Link Image</p>
                <div className="utility-flex-row">
                  <img
                    src={this.state.deeplink_image_preview || this.state.search_deeplink_image_url}
                    alt="Deeplink Image Preview"
                    style={{
                      height: "150px",
                      width: "150px",
                      marginRight: "20px",
                    }}
                  />
                  <label>
                    <input
                      type="file"
                      onChange={this.handleDeepLinkImageUpload}
                      accept="image/*"
                      style={{ display: "none" }}
                    />
                    <p
                      className="btn btn-primary"
                      style={{ color: "#fff" }}
                      tabIndex="0"
                      onKeyUp={
                        function (e) {
                          if (e.key === "Enter") {
                            e.target.parentElement.click(e);
                          }
                        }
                      }
                    >
                      Upload Image
                    </p>
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group" style={{ paddingTop: "10px", paddingBottom: "10px" }}>
              <label className="utility-inline-label">Deeplink iOS</label>
              <input
                type="text"
                className="utility-text-input"
                value={this.state.search_deeplink_ios_url}
                onChange={(e) => {
                  this.setState({
                    search_deeplink_ios_url: e.target.value,
                    edited: true,
                  });
                }}
              />
            </div>
            <div className="form-group" style={{ paddingTop: "10px", paddingBottom: "10px" }}>
              <label className="utility-inline-label">Deeplink Android</label>
              <input
                type="text"
                className="utility-text-input"
                value={this.state.search_deeplink_android_url}
                onChange={(e) => {
                  this.setState({
                    search_deeplink_android_url: e.target.value,
                    edited: true,
                  });
                }}
              />
            </div>
            <div className="utility-flex-end" style={{ marginTop: "30px" }}>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={!this.state.edited}
              >
                Save
              </button>
            </div>
          </form>
        </MainContentContainer>
      </div >
    );
  }
}

function mapStateToProps(state) {
  var deeplinks = state.search.deeplinks;
  return {
    search_deeplink_image_url: deeplinks ? deeplinks.deeplink_image_url : "",
    search_deeplink_ios_url: deeplinks ? deeplinks.deeplink_ios : "",
    search_deeplink_android_url: deeplinks ? deeplinks.deeplink_android : "",
    image: state.media ? state.media.image : "",
  };
}

const mapDispatchToProps = {
  saveSearchDeeplinksInfo,
  fetchSearchDeeplinksInfo,
  clearMedia,
  uploadImage,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchDeeplinks);
