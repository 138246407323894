import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchAuthors } from "./../../actions/authorsActions";
import { Link } from "react-router-dom";

class AuthorsTable extends Component {
  constructor(props) {
    super(props);
    this.colWidth1 = "100px";
    this.colWidth2 = "100%";
  }

  componentDidMount() {
    this.props.fetchAuthors(3);
  }

  renderAuthors = () => {
    if (!this.props.authors) return <h1>Loading...</h1>;

    const data = this.props.authors.sort((a, b) => a.sortOrder - b.sortOrder);
    return data.map((obj) => {
      return (
        <div
          className="utility-table-row"
          key={obj.author_id + obj.author_name}
        >
          <p style={{ width: this.colWidth1 }}>
            {obj.author_image && (
              <img
                src={obj.author_image}
                alt="artist"
                style={{ height: "50px" }}
              />
            )}
          </p>
          <div style={{ width: this.colWidth2 }} className="utility-flex-row">
            <p>{obj.author_name}</p>
            <div style={{ margin: "16px 0 16px auto" }}>
              <Link
                to={"/authors/edit/" + obj.author_id}
                className="utility-edit-link"
              >
                Edit
              </Link>
            </div>
          </div>
        </div>
      );
    });
  };

  render() {
    return (
      <div>
        <div className="utility-table-row utility-table-row-headers">
          <h2 style={{ width: this.colWidth1 }}>Picture</h2>
          <h2 style={{ width: this.colWidth2 }}>Author Name</h2>
        </div>
        {this.renderAuthors()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authors: state.authors.authors,
  };
}

const mapDispatchToProps = {
  fetchAuthors,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthorsTable);
