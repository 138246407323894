import React, { Component } from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from "react-sortable-hoc";
import { MdReorder } from "react-icons/md";

const colWidth1 = "200px";
const colWidth2 = "130px";
const colWidth3 = "250px";
const colWidth4 = "100px";

const DragHandle = SortableHandle(() => <div style={{width:colWidth1}} className="sort-handle" title='Drag to reorder'><MdReorder/></div>);

const Header = () => {
  return (
    <div className="utility-flex-row" style={{fontSize:'16px', fontWeight: 'bold'}}>
      <p style={{width:colWidth1}}>Session Order</p>
      <p style={{width:colWidth3}}> Session Name</p>
      <p style={{width:colWidth2, marginRight: '10px'}}> Session Label </p>
    </div>
  )
}
const SortableItem = SortableElement(
  ({ value, sessions, handleChallengesDay, state }) => {

    let label = "";
    let item = sessions.find(
      obj => obj.session_id === parseInt(Object.keys(value)[0])
    );
    if (item) {
      label = item.session_name;
    }
    const name = item.session_id

    
    console.log('session value name ',value);
    
    return (
      <div
        className="utility-flex-row"
        key={"sortable-session" + value.session_id}
        style={{ alignItems: "left", paddingBottom: "15px" }}
      >
        <DragHandle />
        <p style={{  fontSize: "16px" ,width : colWidth3}}>{label}</p>
        <input
          type="text"
          className="utility-text-input"
          name={`days-${name}`}
          value={value[name][0]}
          onChange={handleChallengesDay}
          style={{ width: colWidth2, marginRight: '10px' }}
          required
        />
          <input
          type="number"
          hidden="true"
          className="utility-text-input"
          name={`repetition-${name}`}
          value={value[name][1]}
          onChange={handleChallengesDay}
          style={{ width: colWidth2, marginRight: '10px' }}
          required
        />
      </div>
    );
  }
);

const SortableList = SortableContainer(
  ({ items, sessions, handleChallengesDay, state }) => {
    return (
      <div>
        <Header/>
        {items.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            value={value}
            sessions={sessions}
            handleChallengesDay={handleChallengesDay}
            state={state}
          />
        ))}
      </div>
    );
  }
);

class SortableSessions extends Component {
  render() {
    console.log("challenge sortable", this.props);
    return (
      <SortableList
        items={this.props.items}
        onSortEnd={this.props.onSortEnd}
        useDragHandle={true}
        sessions={this.props.sessions}
        handleChallengesDay={this.props.handleChallengesDay}
        state={this.props.state}
        helperClass="utility-flex-row-dragging"
      />
    );
  }
}

export default SortableSessions;
