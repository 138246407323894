import React, { Component } from 'react';
// !!!!!!!!!!!!!!!!!!!!!!
// jQuery and SummerNote are loaded globally on index.html in /public for this to work
// !!!!!!!!!!!!!!!!!!!!!!

class AddEditAuthorTextEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: ''
    };
  }

  componentDidMount() {
  	var that = this;
  	window.$('#author_bio').summernote({
    	callbacks : {
      	onChange: function(contents) {
        	that.props.handleChange(contents)
        }
      },
      height: 200,
      tabsize: 2,
      codemirror: {
        theme: 'monokai'
      },
      colors: [["#545454", "#ffffff", "#00ccff", "#058eb9", "#35b908", "#8d71a7", "#ff9926"]],
      fontSizes: ['14','16','18','22'],
      fontNames: ['Asap-Regular'],
      fontNamesIgnoreCheck: ['Asap-Regular'],
      defaultFontName: 'Asap-Regular',
      toolbar: [
        // ['insert', ['emoji']],
        ['fontname', ['fontname']],
        ['fontsize', ['fontsize']],
        ['style', ['style']],
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['height', ['height']], // line height
        // ['insert', ['picture', 'link']],
        // ['table', ['table']],
        ['codeview', ['codeview']]
      ]
    });

    //Clears rich text formatting on paste
    window.$("#author_bio").on("summernote.paste",function(e,ne) {
      var bufferText = ((ne.originalEvent || ne).clipboardData || window.clipboardData).getData('Text');
      ne.preventDefault();
      document.execCommand('insertText', false, bufferText);
    });

    // Sets initial text
    window.$("#author_bio").summernote("code", this.props.value);

    window.$('#author_bio').next().on('focusout', ".note-codable", function() {
        if (window.$('#author_bio').summernote('codeview.isActivated')) {
            window.$('#author_bio').summernote('codeview.deactivate');
        }
    });
  }

  componentWillUnmount() {
    window.$('.tooltip').remove();
  }

	render() {
  	return (
      <div>
      	<textarea id="author_bio" name="content"  placeholder="Content" className="form-control editor1"></textarea>
      </div>
    );
  }

}

export default AddEditAuthorTextEditor;
