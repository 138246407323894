import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchAllQuotes } from "./../../actions/quoteActions";
import { Link } from "react-router-dom";
import HeaderBar from "./../HeaderBar/HeaderBar";
import MainContentContainer from "./../MainContentContainer/MainContentContainer";
// import SubNav from './../Nav/SubNav/SubNav';

class QuotesTable extends Component {
  constructor(props) {
    super(props);
    this.colWidth1 = "100%";
  }

  componentDidMount() {
    this.props.fetchAllQuotes();
  }

  renderQuotes = () => {
    return this.props.all_quotes.map((obj) => {
      return (
        <div className="utility-table-row" key={obj.quote_id}>
          <p style={{ width: this.colWidth2 }}>{obj.quote_text}</p>
          <div style={{ margin: "16px 0 16px auto", paddingLeft: "16px" }}>
            <Link
              to={"/quotes/edit/" + obj.quote_id}
              className="utility-edit-link"
            >
              Edit
            </Link>
          </div>
        </div>
      );
    });
  };

  render() {
    if (!this.props.all_quotes) {
      return <h3>Loading...</h3>;
    }

    return (
      <div>
        {/* <SubNav>
          <p>Quotes</p>
        </SubNav> */}

        <HeaderBar header={"Dashboard Quotes"}>
          <Link className="utility-btn-primary" to={"/quotes/add"}>
            Add Quote
          </Link>
        </HeaderBar>

        <MainContentContainer>
          <div className="SubCategoriesTable">
            <div className="utility-table-row utility-table-row-headers">
              <h2 style={{ width: this.colWidth1 }}>Quotes</h2>
            </div>
            {this.renderQuotes()}
          </div>
        </MainContentContainer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    all_quotes: state.quotes.all_quotes,
  };
}

const mapDispatchToProps = {
  fetchAllQuotes,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuotesTable);
