import React from 'react';
import { Box, Typography, Divider } from '@mui/material';

export default function FieldSetGroup({ title, children }) {
  return (
    <Box sx={{ border: '1px solid #ccc', borderRadius: 2, padding: 2 }}>
      <Typography variant="subtitle1" component="legend" sx={{ marginBottom: 1 }}>
        {title}
      </Typography>
      <Divider sx={{ marginBottom: 2, background: "#ccc" }} />
      {children}
    </Box>
  );
}
