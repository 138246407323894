import axios from 'axios';
import { push } from 'react-router-redux';
import { API_URL, errorHandler, queryString } from './index';
import {
  API_ERROR,
  FETCH_ALL_TAPPING_POINTS,
  CLEAR_TAPPING_POINTS
} from '../constants/ActionTypes';
import swal from 'sweetalert2';

import SessionHelper from '../components/Helper/SessionHelper';

//= ===============================
// Client actions
//= ===============================

export function fetchTappingPoints() {
  return function(dispatch) {
    axios
      .post(
        `${API_URL}/fetch-all-tapping-points.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken()
        })
      )
      .then(response => {
        dispatch({
          type: FETCH_ALL_TAPPING_POINTS,
          payload: response.data.objects
        });
      })
      .catch(error => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function addEditTappingPoint(tapping_point_json) {
  return function(dispatch) {
    axios
      .post(
        `${API_URL}/add-n-edit-tapping-points.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          tapping_point_json: tapping_point_json,
          user_token: SessionHelper.getToken()
        })
      )
      .then(response => {
        swal({
          type: 'success',
          title: 'Saved!',
          timer: 1000,
        }).then(() => {
          dispatch(push('/tapping-points'));
        });
      })
      .catch(error => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function deleteTappingPoint(tapping_point_id) {
  return function(dispatch) {
    axios.post(`${API_URL}/delete-tapping-point.php`,
                        queryString.stringify({
                            requesting_user_id: SessionHelper.currentUser().admin_id,
                            tapping_point_id: tapping_point_id,
                            user_token: SessionHelper.getToken()
                        }))
    .then((response) => {
      swal({
        type: 'success',
        title: 'Tapping Point Deleted!',
        timer: 1000
      }).then(()=>{
        dispatch(push('/tapping-points'));
      });
    })
    .catch((error) => {
      errorHandler(dispatch, error.response, API_ERROR)
    });
  }
}

export function clearTappingPoints() {
  return function(dispatch) {
    dispatch({
      type: CLEAR_TAPPING_POINTS
    });
  };
}
