import axios from "axios";
import { push } from "react-router-redux";
import { API_URL, errorHandler, queryString } from "./index";
import { AUTH_USER, AUTH_ERROR, UNAUTH_USER } from "../constants/ActionTypes";

import SessionHelper from "../components/Helper/SessionHelper";

//= ===============================
// Authentication actions
//= ===============================

export function emailLogin({ email, password }) {
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/admin-login.php`,
        queryString.stringify({
          email: email,
          password: password,
        })
      )
      .then((response) => {
        SessionHelper.userDict(response.data);
        dispatch({
          type: AUTH_USER,
          payload: response.data,
        });
        dispatch(push("/"));
      })

      .catch((error) => {
        errorHandler(dispatch, error.response, AUTH_ERROR);
      });
  };
}

export function logoutUser(error) {
  return function (dispatch) {
    dispatch({
      type: UNAUTH_USER,
      payload: "You have been logged out",
    });
    dispatch(push("/login"));
  };
}
