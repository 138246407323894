import React, { Component } from "react";
import SeriesTable from "./../../SeriesTable/SeriesTable";

class SeriesPage extends Component {
  render() {
    return (
      <div className="utility-page-layout">
        <SeriesTable selectedId={this.props.match.params.id} />
      </div>
    );
  }
}

export default SeriesPage;
