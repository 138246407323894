import React, { Component } from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { MdReorder, MdDelete } from "react-icons/md";

const DragHandle = SortableHandle(() => <div className="sort-handle" title='Drag to reorder'><MdReorder/></div>);

const SortableItem = SortableElement(
  ({ value, onDelete, index, el_index, handleChange }) => {
    return (
      <div
        className="utility-flex-row"
        style={{ alignItems: "center", padding: "10px 0px" }}
      >
        <DragHandle />
        <input
          type="text"
          className="utility-inline-label utility-text-input"
          name={value.cancellation_id}
          value={value.reason}
          onChange={(e) => handleChange(e, el_index)}
          placeholder="Cancellation Reason"
          style={{ width: "500px", marginLeft: "10px" }}
          required
        />
        <button
          className="utility-btn-transparent"
          style={{ color: "#d0021b", fontSize: "20px" }}
          onClick={(e) => onDelete(e, value.cancellation_id, el_index)}
        >
          <MdDelete/>
        </button>
      </div>
    );
  }
);

const SortableList = SortableContainer(({ items, onDelete, handleChange }) => {
  if (!items) {
    return <p>Loading ...</p>;
  }
  if (!items.length) {
    return <p>Please Add </p>;
  }
  return (
    <div>
      {items.map((value, index) => (
        <SortableItem
          key={`item-${index}`}
          value={value}
          index={index}
          el_index={index}
          handleChange={handleChange}
          onDelete={onDelete}
        />
      ))}
    </div>
  );
});

class SortableData extends Component {
  render() {
    return (
      <SortableList
        items={this.props.items}
        onSortEnd={this.props.onSortEnd}
        onDelete={this.props.onDelete}
        useDragHandle={true}
        handleChange={this.props.handleChange}
      />
    );
  }
}

export default SortableData;
