import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { arrayMoveImmutable } from "array-move";

import {
  fetchAllQuickTapFeaturedSessions,
  clearQuickTapSessions,
  fetchQuickTapsFeaturedIntro,
  updateQuickTapsFeaturedIntro,
  updateQuickTapFeaturedOrder,
} from "./../../../actions/quickTapsActions";

import QuickTapsFeaturedList from "./QuickTapsFeaturedList/QuickTapsFeaturedList";

class QuickTapsFeaturedTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quick_taps_featured_intro: props.quick_taps_featured_intro,
      featured_sessions: props.featured_sessions,
      highlight_index: null,
    };
  }

  componentDidMount() {
    this.props.clearQuickTapSessions();
    this.props.fetchAllQuickTapFeaturedSessions();
    this.props.fetchQuickTapsFeaturedIntro();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.quick_taps_featured_intro !== this.props.quick_taps_featured_intro) {
        this.setState({ quick_taps_featured_intro: this.props.quick_taps_featured_intro });
    }
    if (prevProps.featured_sessions != this.props.featured_sessions){
      this.setState({ featured_sessions: this.props.featured_sessions });
    }
    if(prevProps.highlight_index !== this.props.highlight_index){
      this.setState({ highlight_index: this.props.highlight_index})
    }
  }

  handleIntroChange = (e) => {
    const { value } = e.target;
    this.setState({ quick_taps_featured_intro: value });
  };

  handleIntroSave = (e) => {
    this.props.updateQuickTapsFeaturedIntro(
      this.state.quick_taps_featured_intro
    );
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(
      {
        featured_sessions: arrayMoveImmutable(
          this.state.featured_sessions,
          oldIndex,
          newIndex
        ),
        highlight_index: newIndex
      },
      () => {
        const sort_json = this.state.featured_sessions.map(
          (obj) => obj.id
        );
        this.props.updateQuickTapFeaturedOrder(JSON.stringify(sort_json));
      });
  }

  render(){
    if (!this.state.featured_sessions) {
      return <h1 style={{ textAlign: "center" }}>Loading</h1>;
    }

    const dragKey = this.state.featured_sessions.reduce(
      (accum, val) => (accum += val.id),
      "sort-key"
    );

    return(
      <div className="SessionsTable">
        <div
          className="utility-flex-row"
          style={{ alignItems: "left", justifyContent: "left", margin: "20px 20px" }}
        >
          <input
            type="text"
            value={this.state.quick_taps_featured_intro}
            className="utility-text-input"
            name="quick_taps_featured_intro"
            onChange={this.handleIntroChange}
            style={{ marginRight: "20px", flex: "1" }}
          />
          <Button className="utility-btn-primary" onClick={this.handleIntroSave}>
            Save Intro
          </Button>
        </div>
        {/* Header Row */}
        <div className="utility-table-row utility-table-row-headers">
            <div className="utility-table-column">Sort Order</div>
            <div className="utility-table-column utility-table-column-expanded">Title</div>
        </div>
        <QuickTapsFeaturedList
            items={this.state.featured_sessions}
            highlightIndex={this.state.highlight_index}
            onSortEnd={this.onSortEnd}
            key={dragKey}
          />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    featured_sessions: state.quick_taps.all_quick_tap_sessions,
    quick_taps_featured_intro: state.general_data.general_data
      ? state.general_data.general_data.quick_taps_featured_intro
      : "",
    highlight_index: state.highlight_index
  };
}

const mapDispatchToProps = {
  clearQuickTapSessions,
  fetchAllQuickTapFeaturedSessions,
  updateQuickTapsFeaturedIntro,
  fetchQuickTapsFeaturedIntro,
  updateQuickTapFeaturedOrder
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickTapsFeaturedTable);
