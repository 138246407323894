import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import HeaderBar from "../HeaderBar/HeaderBar";
import MainContentContainer from "../MainContentContainer/MainContentContainer";
import {
  fetchPromoData,
  fetchGeneralInfo,
  updatePromoData,
  updateOnboardingPromoData,
  updateGeneralInfo,
} from "../../actions/promoDataActions";
import {
  fetchOnboardingData,
  updateOnboardingData,
} from "../../actions/onboardingAction";
import { uploadVideo, clearMedia } from "../../actions/mediaActions";
import swal from "sweetalert2";
import { fetchAllSeries } from "./../../actions/seriesActions";
import Select from "react-select";

class OnboardingData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confetti_screen: "",
      onboarding_video_one_url: "",
      onboarding_video_one_preview: "",
      onboarding_video_one_uploading: false,
      onboarding_video_one_title: "",
      video_one_onboarding_length: "",
      video_one_onboarding_length_in_sec: 0,
      onboarding_video_two_url: "",
      onboarding_video_two_preview: "",
      onboarding_video_two_uploading: false,
      onboarding_video_two_title: "",
      video_two_onboarding_length: "",
      video_two_onboarding_length_in_sec: 0,
      onboarding_video_three_url: "",
      onboarding_video_three_preview: "",
      onboarding_video_three_uploading: false,
      onboarding_video_three_title: "",
      video_three_onboarding_length: "",
      video_three_onboarding_length_in_sec: 0,
      onboarding_video_one_length: "",
      onboarding_video_two_length: "",
      onboarding_video_three_length: "",
      initialFetch: false,
      series_is_promoted: 0,
    };
  }

  componentDidMount() {
    this.props.fetchPromoData();
    this.props.fetchGeneralInfo();
    this.props.fetchOnboardingData();
    this.props.fetchAllSeries();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log("nextProps: ", nextProps);
    if (nextProps.onboarding_data.onboarding_data && !this.state.initialFetch) {
      this.setState({
        confetti_screen:
          nextProps.onboarding_data.onboarding_data.confetti_screen,
        onboarding_video_one_title:
          nextProps.onboarding_data.onboarding_data.onboarding_video_one_title,
        onboarding_video_one_url:
          nextProps.onboarding_data.onboarding_data.onboarding_video_one_url,
        onboarding_video_two_url:
          nextProps.onboarding_data.onboarding_data.onboarding_video_two_url,
        onboarding_video_two_title:
          nextProps.onboarding_data.onboarding_data.onboarding_video_two_title,
        onboarding_video_three_url:
          nextProps.onboarding_data.onboarding_data.onboarding_video_three_url,
        onboarding_video_three_title:
          nextProps.onboarding_data.onboarding_data
            .onboarding_video_three_title,
        series_is_promoted:
          nextProps.onboarding_data.onboarding_data.series_is_promoted,
        initialFetch: true,
      });
    }
    if (nextProps.video && !this.props.video) {
      if (this.state.onboarding_video_one_uploading) {
        const video = nextProps.video;
        this.setState({
          onboarding_video_one_uploading: false,
          onboarding_video_one_url: video,
        });
        this.props.clearMedia();
      }

      if (this.state.onboarding_video_two_uploading) {
        const video = nextProps.video;
        this.setState({
          onboarding_video_two_uploading: false,
          onboarding_video_two_url: video,
        });
        this.props.clearMedia();
      }
      if (this.state.onboarding_video_three_uploading) {
        const video = nextProps.video;
        this.setState({
          onboarding_video_three_uploading: false,
          onboarding_video_three_url: video,
        });
        this.props.clearMedia();
      }
    }
  }

  componentWillUnmount = () => {
    this.props.clearMedia();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let { ...data } = this.state;
    data = JSON.stringify(data);
    console.log("DATA", data);
    this.props.updateOnboardingData(data);
  };

  handleFieldChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => {
      const state = { ...prevState };
      state[name] = value;
      return state;
    });
  };

  clickParent = (e) => {
    e.target.parentNode.click();
  };

  renderSeriesOptions = () => {
    const { series } = this.props;
    if (!series) return [];

    const options = series.map((obj) => ({
      value: obj.series_id,
      label: obj.series_title,
    }));

    return [{ label: "None", value: 0 }, ...options];
  };

  handleVideoUpload = (e) => {
    const number = e.target.id;
    const file = e.target.files[0];
    console.log("number----", e.target.id);
    if (!e) {
      return false;
    }
    if (!file) {
      return false;
    }

    switch (number) {
      case "one":
        if (this.state.onboarding_video_one_preview) {
          window.URL.revokeObjectURL(this.state.onboarding_video_one_preview);
        }
        this.setState({
          onboarding_video_one_preview: window.URL.createObjectURL(file),
          onboarding_video_one_uploading: true,
        });

        const video = document.createElement("video");
        video.preload = "metadata";

        video.onloadedmetadata = () => {
          window.URL.revokeObjectURL(video.src);
          if (video.duration < 1) {
            console.log("Invalid Video! video is less than 1 second");
            return;
          }

          const minutes = Math.floor(video.duration / 60);
          const seconds = Math.round(video.duration - minutes * 60);
          this.setState({
            video_one_onboarding_length: `${minutes}:${
              seconds < 10 ? "0" + seconds : seconds
            }`,
            video_one_onboarding_length_in_sec: Math.round(video.duration),
          });
        };

        video.src = URL.createObjectURL(file);
        this.props.uploadVideo(file);
        break;
      case "two":
        if (this.state.onboarding_video_two_preview) {
          window.URL.revokeObjectURL(this.state.onboarding_video_two_preview);
        }
        this.setState({
          onboarding_video_two_preview: window.URL.createObjectURL(file),
          onboarding_video_two_uploading: true,
        });

        const videoTwo = document.createElement("video");
        videoTwo.preload = "metadata";

        videoTwo.onloadedmetadata = () => {
          window.URL.revokeObjectURL(videoTwo.src);

          if (videoTwo.duration < 1) {
            console.log("Invalid Video! video is less than 1 second");
            return;
          }

          const minutes = Math.floor(videoTwo.duration / 60);
          const seconds = Math.round(videoTwo.duration - minutes * 60);
          this.setState({
            video_two_onboarding_length: `${minutes}:${
              seconds < 10 ? "0" + seconds : seconds
            }`,
            video_two_onboarding_length_in_sec: Math.round(videoTwo.duration),
          });
        };

        videoTwo.src = URL.createObjectURL(file);
        this.props.uploadVideo(file);
        break;
      case "three":
        if (this.state.onboarding_video_three_preview) {
          window.URL.revokeObjectURL(this.state.onboarding_video_three_preview);
        }
        this.setState({
          onboarding_video_three_preview: window.URL.createObjectURL(file),
          onboarding_video_three_uploading: true,
        });

        const videoThree = document.createElement("video");
        videoThree.preload = "metadata";

        videoThree.onloadedmetadata = () => {
          window.URL.revokeObjectURL(videoThree.src);

          if (videoThree.duration < 1) {
            console.log("Invalid Video! video is less than 1 second");
            return;
          }

          const minutes = Math.floor(videoThree.duration / 60);
          const seconds = Math.round(videoThree.duration - minutes * 60);
          this.setState({
            video_three_onboarding_length: `${minutes}:${
              seconds < 10 ? "0" + seconds : seconds
            }`,
            video_three_onboarding_length_in_sec: Math.round(
              videoThree.duration
            ),
          });
        };

        videoThree.src = URL.createObjectURL(file);
        this.props.uploadVideo(file);
        break;
      default:
        return;
    }
  };

  render() {
    console.log("state: ", this.state);
    console.log("props: ", this.props);
    if (!this.props.promo_data) {
      return <h1>Loading...</h1>;
    }

    return (
      <div>
        <form
          className="AddEditQuote"
          onSubmit={this.handleSubmit}
          autoComplete="off"
        >
          <HeaderBar header="Update Onboarding Data">
            <button className="utility-btn-primary">Update</button>
          </HeaderBar>
          <MainContentContainer subContent={true}>
            <div className="promo-flex-row">
              <div className="promo-column">
                <div className="utility-flex-row">
                  <label className="utility-inline-label">
                    <p>Confetti Screen Link</p>
                    <input
                      className="utility-text-input"
                      name="confetti_screen"
                      value={this.state.confetti_screen}
                      style={{ width: "300px" }}
                      onChange={this.handleFieldChange}
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="utility-flex-row">
              <div className="utility-inline-label">
                <p>Select Recommended Series</p>
                <Select
                  value={this.state.series_is_promoted}
                  onChange={(e) =>
                    this.setState({ series_is_promoted: e.value })
                  }
                  style={{ width: "450px", marginBottom: "40px" }}
                  clearable={false}
                  searchable={false}
                  options={this.renderSeriesOptions()}
                />
              </div>
            </div>
            <div className="utility-flex-row">
              <div>
                <label style={{ marginRight: "20px", width: "300px" }}>
                  <p style={{ fontSize: "14px", margin: "13px 0 -11px" }}>
                    Onboarding One Video File
                  </p>
                  <input
                    type="file"
                    onChange={this.handleVideoUpload}
                    style={{ display: "none" }}
                    accept={
                      this.state.session_media_type === 1 ? ".mp3" : ".mp4"
                    }
                    id="one"
                  />
                  <p
                    className="utility-btn-primary"
                    style={{ color: "#fff" }}
                    tabIndex="0"
                    onKeyPress={this.clickParent}
                  >
                    Upload Video
                  </p>
                </label>
                <label style={{ marginRight: "20px", width: "300px" }}>
                  <p style={{ fontSize: "14px", margin: "13px 0 20px" }}>
                    Onboarding One Video Title
                  </p>
                  <input
                    type="text"
                    className="utility-text-input"
                    name="onboarding_video_one_title"
                    value={this.state.onboarding_video_one_title}
                    onChange={this.handleFieldChange}
                    style={{ width: "300px" }}
                    id="one"
                  />
                </label>
                <video
                  id="onboarding_video_one"
                  src={
                    this.state.onboarding_video_one_preview ||
                    this.state.onboarding_video_one_url
                  }
                  controls
                  style={{ width: "300px" }}
                />
              </div>
              <div>
                <label style={{ marginRight: "20px", width: "300px" }}>
                  <p style={{ fontSize: "14px", margin: "13px 0 -11px" }}>
                    Onboarding Two Video File
                  </p>
                  <input
                    type="file"
                    onChange={this.handleVideoUpload}
                    style={{ display: "none" }}
                    accept={
                      this.state.session_media_type === 1 ? ".mp3" : ".mp4"
                    }
                    id="two"
                  />
                  <p
                    className="utility-btn-primary"
                    style={{ color: "#fff" }}
                    tabIndex="0"
                    onKeyPress={this.clickParent}
                  >
                    Upload Video
                  </p>
                </label>
                <label style={{ marginRight: "20px", width: "300px" }}>
                  <p style={{ fontSize: "14px", margin: "13px 0 20px" }}>
                    Onboarding Two Video Title
                  </p>
                  <input
                    type="text"
                    className="utility-text-input"
                    name="onboarding_video_two_title"
                    value={this.state.onboarding_video_two_title}
                    onChange={this.handleFieldChange}
                    style={{ width: "300px" }}
                    id="one"
                  />
                </label>
                <video
                  id="onboarding_video_two"
                  src={
                    this.state.onboarding_video_two_preview ||
                    this.state.onboarding_video_two_url
                  }
                  controls
                  style={{ width: "300px" }}
                />
              </div>
              <div>
                <label style={{ marginRight: "20px", width: "300px" }}>
                  <p style={{ fontSize: "14px", margin: "13px 0 -11px" }}>
                    Onboarding Three Video File
                  </p>
                  <input
                    type="file"
                    onChange={this.handleVideoUpload}
                    style={{ display: "none" }}
                    accept={
                      this.state.session_media_type === 1 ? ".mp3" : ".mp4"
                    }
                    id="three"
                  />
                  <p
                    className="utility-btn-primary"
                    style={{ color: "#fff" }}
                    tabIndex="0"
                    onKeyPress={this.clickParent}
                  >
                    Upload Video
                  </p>
                </label>
                <label style={{ marginRight: "20px", width: "300px" }}>
                  <p style={{ fontSize: "14px", margin: "13px 0 20px" }}>
                    Onboarding Three Video Title
                  </p>
                  <input
                    type="text"
                    className="utility-text-input"
                    name="onboarding_video_three_title"
                    value={this.state.onboarding_video_three_title}
                    onChange={this.handleFieldChange}
                    style={{ width: "300px" }}
                    id="one"
                  />
                </label>
                <video
                  id="onboarding_video_three"
                  src={
                    this.state.onboarding_video_three_preview ||
                    this.state.onboarding_video_three_url
                  }
                  controls
                  style={{ width: "300px" }}
                />
              </div>
            </div>
          </MainContentContainer>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  console.log("onboarding state: ", state);
  return {
    promo_data: state.promo_data.promo_data,
    confetti_screen:
      state.general_data.general_data &&
      state.general_data.general_data.confetti_screen,
    video: state.media.video,
    onboarding_data: state.onboarding_data,
    series: state.series.all_series,
  };
}

const mapDispatchToProps = {
  fetchPromoData,
  updatePromoData,
  updateOnboardingPromoData,
  fetchGeneralInfo,
  updateGeneralInfo,
  uploadVideo,
  clearMedia,
  fetchAllSeries,
  fetchOnboardingData,
  updateOnboardingData,
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingData);
