import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SubNav from './../../Nav/SubNav/SubNav';
import AddEditPromoBanner from './../../AddEditPromoBanner/AddEditPromoBanner';

class PromoBannerAddEditPage extends Component {

  render() {
    const isEdit = this.props.location.pathname.indexOf('edit') > -1;

    return (
      <div className="utility-page-layout">
        <SubNav>
          <Link to="/banners">Promo Banners</Link>
          <span>/</span>
          {isEdit ? <Link to="#">Edit</Link> : <Link to="#">Add</Link>}
        </SubNav>
        <AddEditPromoBanner isEdit={isEdit} selectedId={this.props.match.params.id} />
      </div>
    );
  }

}

export default PromoBannerAddEditPage;
