import React, { Component } from "react";
import { connect } from "react-redux";
import { arrayMoveImmutable } from "array-move";
import { generalErrorHandle } from "../../actions";
import {
  fetchTopSearches,
  deleteTopSearch,
  addOrEditTopSearch,
} from "../../actions/topSearchActions";
import MainContentContainer from "../MainContentContainer/MainContentContainer";
import SortableData from "./Sortable";

// import SubNav from './../Nav/SubNav/SubNav';

class TopSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      top_search_data: [],
    };
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      top_search_data: arrayMoveImmutable(
        this.state.top_search_data,
        oldIndex,
        newIndex
      ),
    });
  };

  componentDidMount() {
    this.props.fetchTopSearches();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.top_search !== this.props.top_search) {
      this.setState({ top_search_data: this.props.top_search });
    }
  }

  renderTopSearch() {
    if (this.state.top_search_data) {
      return this.state.top_search_data.map((el, i) => {
        return <p key={i}>{el.value}</p>;
      });
    }
  }

  handleChange = (e, index) => {
    const value = e.target.value;
    let items = [...this.state.top_search_data];
    let item = { ...this.state.top_search_data[index] };
    item.value = value;
    items[index] = item;
    this.setState({ top_search_data: items });
  };

  handleDelete = (e, top_search_id, index) => {
    e.preventDefault();
    if (top_search_id) {
      this.props.deleteTopSearch(top_search_id);
    }
    this.state.top_search_data.splice(index, 1);
    this.setState({ top_search_data: this.state.top_search_data });
  };

  addNewTopSearch = () => {
    const data = {
      value: "",
      sort_order: this.state.top_search_data.length + 1,
    };
    this.setState({ top_search_data: [...this.state.top_search_data, data] });
  };

  validateData = () => {
    for (let i = 0; i < this.state.top_search_data.length; i++) {
      const data = this.state.top_search_data[i];
      if (!data.value) {
        throw generalErrorHandle("Please Fill All Missing Data For Top Search");
      }
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.validateData();
    const data = JSON.stringify(this.state.top_search_data);
    this.props.addOrEditTopSearch(data);
  };

  render() {
    if (!this.props.top_search) {
      return <h3>Loading...</h3>;
    }

    return (
      <div>
        <MainContentContainer>
          <div>
            <div className="HeaderBar">
              <h2>Top Search</h2>
            </div>

            <div className="utility-flex-end">
              <button
                className="utility-btn-transparent"
                onClick={() => this.addNewTopSearch()}
              >
                Add New
              </button>
            </div>

            <div>
              {this.state.top_search_data.length && (
                <SortableData
                  items={this.state.top_search_data}
                  onSortEnd={this.onSortEnd}
                  onDelete={this.handleDelete}
                  handleChange={this.handleChange}
                />
              )}
            </div>
          </div>
          <div className="utility-flex-end" style={{ marginTop: "30px" }}>
            <button
              className="utility-btn-primary"
              style={{ width: "35%" }}
              onClick={this.handleSubmit}
            >
              Save
            </button>
          </div>
        </MainContentContainer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    top_search: state.search.top_search_data || [],
  };
}

const mapDispatchToProps = {
  fetchTopSearches,
  deleteTopSearch,
  addOrEditTopSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopSearch);
