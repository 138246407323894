import React, { Component } from 'react';
import { connect } from 'react-redux';
import {emailLogin} from './../../actions/authActions';

import './Login.css';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
    };
  }

  handleTextChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => {
      const state = {...prevState};
      state[name] = value;
      return state;
    });
  }

  handleLogin = (e) => {
    e.preventDefault();
    this.props.emailLogin(this.state);
  }

  render() {
    return (
      <form className="Login" onSubmit={this.handleLogin}>
        <h1>Tapping Solution</h1>
        <label>
          <p>Email</p>
          <input
            type="email"
            required
            className="utility-text-input"
            name="email"
            onChange={this.handleTextChange}
            value={this.state.email}
          />
        </label>
        <label>
          <p>Password</p>
          <input
            type="password"
            className="utility-text-input"
            required
            name="password"
            onChange={this.handleTextChange}
            value={this.state.password}
          />
        </label>
        <p className="text-danger">{this.state.error}</p>
        <button className="utility-btn-primary" type="submit">
          Log In
        </button>
      </form>
    );
  }

}

function mapStateToProps(state) {
  return {

  };
}

const mapDispatchToProps = {
  emailLogin
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
