import React, { Component } from 'react';
import AddEditQuickTapSession from "./../../AddEditQuickTapSession/AddEditQuickTapSession";

class QuickTapsDuplicatePage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
  const idDuplicate = this.props.match.params.id > 0;
  const duplicateSessionId = this.props.match.params.id
    return (
      <div className="utility-page-layout">
        <AddEditQuickTapSession
          isEdit={false}
          isDuplicate={idDuplicate}
          duplicateSessionId={duplicateSessionId}
        />
      </div>
    );
  }
}

export default QuickTapsDuplicatePage;
