import React, { Component } from 'react';
import SessionHelper from '../../components/Helper/SessionHelper';
import { Redirect } from "react-router-dom";


class Logout extends Component {
  componentDidMount() {
    SessionHelper.clear(); // Your logout logic
  }

  render() {
    return (
      <Redirect
        to={{
          pathname: "/",
          state: { from: this.props.location },
        }}
      />
    );
  }
}

export default Logout;
