import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './EntryNav.css';

class EntryNav extends Component {
  render() {
    return (
      <nav className="EntryNav">
        <img src="/img/top-ts-logo.jpg" alt="" />
      </nav>
    );
  }
}

export default EntryNav;
