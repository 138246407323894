import React, { Component } from "react";
import { Link } from "react-router-dom";
// import SubNav from './../../Nav/SubNav/SubNav';
import HeaderBar from "../../HeaderBar/HeaderBar";
import MainContentContainer from "../../MainContentContainer/MainContentContainer";
import {
  addEditBGM,
  deleteBGM,
  fetchAllBGM,
} from "../../../actions/backgroundMusicActions";
import BackgroundMusicTable from "./BackgroundMusicTable";

class BackgroundMusicPage extends Component {
  render() {
    return (
      <div className="utility-page-layout">
        <HeaderBar header="Background Musics">
          <Link to="/background-musics/add" className="Utility-btn-primary">
            Add Background Music
          </Link>
        </HeaderBar>
        <MainContentContainer>
          <BackgroundMusicTable />
        </MainContentContainer>
      </div>
    );
  }
}

export default BackgroundMusicPage;
