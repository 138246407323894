import axios from 'axios';
import { push } from 'react-router-redux';
import { API_URL, errorHandler, queryString } from './index';
import {
  API_ERROR,
  FETCH_AUTHORS,
  CLEAR_AUTHORS
} from '../constants/ActionTypes';
import swal from 'sweetalert2';

import SessionHelper from '../components/Helper/SessionHelper';

//= ===============================
// Client actions
//= ===============================

export function fetchAuthors() {
  return function(dispatch) {
    axios
      .post(
        `${API_URL}/fetch-all-authors.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken()
        })
      )
      .then(response => {
        dispatch({
          type: FETCH_AUTHORS,
          payload: response.data.objects
        });
      })
      .catch(error => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function addEditAuthor(author_json) {
  return function(dispatch) {
    axios
      .post(
        `${API_URL}/add-n-edit-author.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          author_json: author_json,
          user_token: SessionHelper.getToken()
        })
      )
      .then(response => {
        swal({
          type: 'success',
          title: 'Saved!',
          timer: 1000
        }).then(() => {
          dispatch(push('/authors'));
        });
      })
      .catch(error => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function deleteAuthor(author_id) {
  return function(dispatch) {
    axios
      .post(
        `${API_URL}/delete-author.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          author_id: author_id,
          user_token: SessionHelper.getToken()
        })
      )
      .then(response => {
        swal({
          type: 'success',
          title: 'Author Deleted!',
          timer: 1000
        }).then(() => {
          dispatch(push('/authors'));
        });
      })
      .catch(error => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function clearAuthors(page) {
  return function(dispatch) {
    dispatch({
      type: CLEAR_AUTHORS
    });
  };
}
