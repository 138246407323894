import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";

// Import Routes Configuration
import RouteConfig from "../../../constants/RouteConfig";
import SessionHelper from '../../Helper/SessionHelper';

import "./AuthNav.css";

class AuthNav extends Component {
  isActive = (path) => {
    const { location } = this.props;
    return location.pathname.includes(path);
  }



  render() {
    const navRoutes = RouteConfig
      .filter(route => route.label && SessionHelper.hasRole(route.allowRoles))
      .sort((a, b) => a.order - b.order);
    return (
      <nav className="AuthNav">
        <img src="/img/top-ts-logo.jpg" alt="" />
        <ul>
          {navRoutes.map((route, index) => (
            <li key={index}>
              <NavLink
                to={route.path}
                isActive={() => this.isActive(route.path)}
                activeClassName="active"
              >{route.label}</NavLink>
            </li>
          ))}
        </ul>
      </nav>
    );
  }
}

export default withRouter(AuthNav);
