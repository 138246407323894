import React, { Component } from 'react';
import { connect } from 'react-redux';
// import SubNav from './../../Nav/SubNav/SubNav';
import HeaderBar from '../../HeaderBar/HeaderBar';
import MainContentContainer from '../../MainContentContainer/MainContentContainer';
import { exportRequestsDataCSV } from './../../../actions/exportCSVActions';
import RequestsTable from '../../RequestsTable/RequestsTable';

class RequestsPage extends Component {

  submitExport = (e) => {
    e.preventDefault();
    this.props.exportRequestsDataCSV();
  };
  

  render() {
    return (
      <div className="utility-page-layout">
        <HeaderBar header="Requests">
        <button onClick={this.submitExport.bind(this)} className="Utility-btn-primary">Export CSV</button>
        </HeaderBar>
        <MainContentContainer>
          <RequestsTable />
        </MainContentContainer>
      </div>
    );
  }

}

const mapDispatchToProps = {
  exportRequestsDataCSV
}

export default connect(null, mapDispatchToProps)(RequestsPage);

