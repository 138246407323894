import axios from "axios";
import { push } from "react-router-redux";
import { API_URL, errorHandler, queryString } from "./index";
import {
  API_ERROR,
  FETCH_ALL_USER_ATTRIBUTION,
  DELETE_ONE_USER_ATTRIBUTION,
  FETCH_ATTRIBUTION_PERCENTAGE,
  UPDATE_ATTRIBUTION_PERCENTAGE,
} from "../constants/ActionTypes";
import swal from "sweetalert2";

import SessionHelper from "../components/Helper/SessionHelper";

//= ===============================
// Client actions
//= ===============================

export function fetchUserAttributions() {
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/fetch-all-attributions.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken(),
        })
      )
      .then((response) => {
        console.log("response", response);
        dispatch({
          type: FETCH_ALL_USER_ATTRIBUTION,
          payload: response.data,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function fetchAttributionPercentage() {
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/fetch-attribution-percentage.php`,
        queryString.stringify({
          user_token: SessionHelper.getToken(),
        })
      )
      .then((response) => {
        console.log("response", response);
        dispatch({
          type: FETCH_ATTRIBUTION_PERCENTAGE,
          payload: response.data,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function deleteUserAttribution(id) {
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/delete-one-attribution.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken(),
          id: id,
        })
      )
      .then((response) => {
        swal({
          type: "success",
          title: "User Attribution Deleted!",
          timer: 1000,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function updateAttributionPercentage(value) {
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/update-attribution-percentage.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken(),
          attribution_value: value,
        })
      )
      .then((response) => {
        swal({
          type: "success",
          title: "Attribution Percentage Updated!",
          timer: 1000,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function addOrEditUserAttribution(data) {
  return function (dispatch) {
    axios
      .post(
        `${API_URL}/add-n-edit-attribution.php`,
        queryString.stringify({
          requesting_user_id: SessionHelper.currentUser().admin_id,
          user_token: SessionHelper.getToken(),
          data: JSON.stringify(data),
        })
      )
      .then((response) => {
        swal({
          type: "success",
          title: "User Attribution Updated!",
          timer: 1000,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}
