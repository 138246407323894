import axios from "axios";
import { API_URL, errorHandler } from "./index";
import {
  API_ERROR,
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_TARGET,
  UPLOAD_AUDIO,
  UPLOAD_VIDEO,
  UPLOAD_MUX_VIDEO,
  CLEAR_MEDIA,
} from "../constants/ActionTypes";
import swal from "sweetalert2";
import * as UpChunk from "@mux/upchunk";

import SessionHelper from "../components/Helper/SessionHelper";

//= ===============================
// Media actions
//= ===============================

export function uploadImage(file, target = "") {
  return function (dispatch) {
    swal({
      title: "Loading...",
      animation: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
    });

    var form = new FormData();
    form.append("requesting_user_id", SessionHelper.currentUser().admin_id);
    form.append("file", file);

    axios
      .post(`${API_URL}/upload-image.php`, form)
      .then((response) => {
        swal({
          title: "Image Uploaded",
          type: "success",
          timer: 1000,
        });
        if (target !== "") {
          dispatch({
            type: UPLOAD_IMAGE_TARGET,
            payload: {
              image_url: response.data.image_url,
              target: target
            }
          });
        } else {
          dispatch({
            type: UPLOAD_IMAGE,
            payload: response.data.image_url,
          });
        }
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function uploadCategoryImage(category_id, file) {
  return function (dispatch) {
    swal({
      title: "Loading...",
      animation: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
    });
    var form = new FormData();
    form.append("requesting_user_id", SessionHelper.currentUser().admin_id);
    form.append("category_id", category_id);
    form.append("file", file);

    axios
      .post(`${API_URL}/upload-image.php`, form)
      .then((response) => {
        swal({
          title: "Image Uploaded",
          type: "success",
          timer: 1000,
        });
        dispatch({
          type: UPLOAD_IMAGE,
          payload: response.data.image_url,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}
const config = {
  onUploadProgress: (progressEvent) =>
    console.log("PROGESS EVENY", progressEvent),
};
export function uploadAudio(file) {
  return function (dispatch) {
    swal({
      title: "Loading...",
      animation: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
    });

    var form = new FormData();
    form.append("requesting_user_id", SessionHelper.currentUser().admin_id);
    form.append("user_token", SessionHelper.getToken());
    form.append("file", file);

    axios
      .post(
        `${API_URL}/upload-audio.php`,
        form
        // onUploadProgress: progressEvent => {
        //   console.log("progressEvent: ", progressEvent);
        // }
      )
      // axios({
      //   method: "post",
      //   url: `${API_URL}/upload-audio.php`,
      //   timeout: 1800000,
      //   data: form,
      //   config
      //   // headers: {
      //   //   "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
      //   // }
      // })
      .then((response) => {
        console.log("audio upload response: ", response);
        swal({
          title: "Audio Uploaded",
          type: "success",
          timer: 1000,
        });
        dispatch({
          type: UPLOAD_AUDIO,
          payload: response.data.audio_file_url,
        });
      })
      .catch((error) => {
        console.log("audio upload error: ", error);
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function uploadVideo(file) {
  return function (dispatch) {
    swal({
      title: "Loading...",
      animation: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
    });

    var form = new FormData();
    form.append("requesting_user_id", SessionHelper.currentUser().admin_id);
    form.append("user_token", SessionHelper.getToken());
    form.append("file", file);

    axios
      .post(
        `${API_URL}/upload-video.php`,
        form
        // onUploadProgress: progressEvent => {
        //   console.log("progressEvent: ", progressEvent);
        // }
      )
      // axios({
      //   method: "post",
      //   url: `${API_URL}/upload-audio.php`,
      //   timeout: 1800000,
      //   data: form,
      //   config
      //   // headers: {
      //   //   "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
      //   // }
      // })
      .then((response) => {
        console.log("video upload response: ", response);
        swal({
          title: "Video Uploaded",
          type: "success",
          timer: 1000,
        });
        dispatch({
          type: UPLOAD_VIDEO,
          payload: response.data.video_url,
        });
      })
      .catch((error) => {
        console.log("audio upload error: ", error);
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function uploadQuickTapVideo(file) {
  return async function (dispatch) {
    swal({
      title: "Loading...",
      animation: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
    });

    const form = new FormData();
    form.append("requesting_user_id", SessionHelper.currentUser().admin_id);
    form.append("user_token", SessionHelper.getToken());

    try {
      const response = await axios.post(
        `${API_URL}/get-quick-tap-upload-url.php`,
        form
      );
      console.log("get-quick-tap-upload-url response", response);
      const { url, id: upload_id } = response.data;

      const upload = UpChunk.createUpload({
        endpoint: url,
        file,
        chunkSize: 5120, // Uploads the file in ~5mb chunks
      });

      // Subscribe to events
      upload.on("error", (error) => {
        console.error("uploading video error", error.detail);
      });

      upload.on("progress", (progress) => {
        console.log("uploading video progress", progress.detail);
      });

      upload.on("success", async () => {
        form.append("upload_id", upload_id);
        const response = await axios.post(
          `${API_URL}/get-quick-tap-asset-data.php`,
          form
        );
        console.log("get-quick-tap-asset-data response", response);
        const { playback_id, duration } = response.data;
        swal({
          title: "Video Uploaded",
          type: "success",
          timer: 1000,
        });
        dispatch({
          type: UPLOAD_MUX_VIDEO,
          payload: {
            playback_id,
            duration,
          },
        });
      });
    } catch (error) {
      console.error("quick tap video upload error", error);
      errorHandler(dispatch, error.response, API_ERROR);
    }
  };
}

export function clearMedia() {
  return function (dispatch) {
    dispatch({
      type: CLEAR_MEDIA,
    });
  };
}
